import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appFormChangeDetector]',
  standalone: true,
})
export class FormChangeDetectorDirective implements OnDestroy {
  @Input() formGroup: FormGroup;
  private isFormDirty: boolean = false;
  private cancelingNavigation: boolean = false;
  private routerSubscription: Subscription;

  constructor(
    private el: ElementRef,
    private router: Router,
    private translateService: TranslateService
  ) {
    // Listen to navigation events
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!this.insideClosedModal() && this.isFormDirty && !this.cancelingNavigation) {
          const confirmNavigation = this.confirmNavigation();
          if (!confirmNavigation) {
            this.cancelingNavigation = true;
            this.router.navigateByUrl(this.router.url);
          } else {
            this.cancelingNavigation = false;
          }
        }
      }
    });

    window.addEventListener('beforeunload', this.onBeforeUnload);
  }

  private isAncestorOfType(
    elementRef: ElementRef,
    tagName: string
  ): HTMLElement | false {
    let element = elementRef.nativeElement;

    while (element) {
      if (element.tagName.toLowerCase() === tagName.toLowerCase()) {
        return element;
      }
      element = element.parentElement; // Move up to the parent
    }

    return false;
  }

  private insideClosedModal() {
    const element = this.isAncestorOfType(this.el, 'app-modal');
    if (element !== false) {
      const firstChild = element.firstChild as HTMLElement;
      if (firstChild && false === firstChild.classList.contains('modal-open')) {
        return true;
      }
    }
    return false;
  }

  @HostListener('ngSubmit')
  onForNgSubmit(): void {
    this.isFormDirty = false;
    this.cancelingNavigation = false;
  }

  @HostListener('input')
  onFormChange(): void {
    this.isFormDirty = true;
    this.cancelingNavigation = false;
  }

  onBeforeUnload = (event: BeforeUnloadEvent): void => {
    if (!this.insideClosedModal() && this.isFormDirty) {
      event.preventDefault();
      event.returnValue = this.translateService.instant('page.leave'); // Required for Chrome to show the alert
    }
  };

  confirmNavigation(): boolean {
    return confirm(this.translateService.instant('page.leave'));
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.onBeforeUnload);
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
