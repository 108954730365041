import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './pages/default/default.component';
import { PersonalComponent } from './pages/default/profile/personal.component';
import { CompanyComponent } from './pages/default/profile/company.component';
import { PasswordComponent } from './pages/default/profile/password.component';
import { AllComponent } from './pages/default/tickets/all.component';
import { ListComponent } from './pages/default/projects/list.component';
import { DetailComponent } from './pages/default/projects/detail.component';
import { DetailGeneralComponent } from './pages/default/projects/detail-general.component';
import { DetailPhasesListComponent } from './pages/default/projects/detail-phases/list.component';
import { DetailPhasesEditComponent } from './pages/default/projects/detail-phases/edit.component';
import { DetailSatisfactionMonitorComponent } from './pages/default/projects/detail-satisfaction-monitor.component';
import { DetailLivestreamsComponent } from './pages/default/projects/detail-livestreams.component';
import { DetailWidgetComponent } from './pages/default/projects/detail-widget.component';
import { DetailStatisticsComponent } from './pages/default/projects/detail-statistics.component';
import { DetailTargetsComponent } from './pages/default/projects/detail-targets.component';
import { PageNotFoundComponent } from './pages/default/page-not-found.component';
import { ExportComponent } from './pages/default/profile/export.component';
import { SmartReportingDefaultComponent } from './pages/default/modules/smart-reporting/default/default.component';
import { SmartReportingThemesComponent } from './pages/default/modules/smart-reporting/themes/themes.component';
import { SmartReportingSettingsComponent } from './pages/default/modules/smart-reporting/settings/settings.component';
import { SmartReportingNoticesComponent } from './pages/default/modules/smart-reporting/notices/notices.component';
import { SmartReportingNoticeDetailComponent } from './pages/default/modules/smart-reporting/notice-detail/notice-detail.component';
import { SmartReportingNoticeCreateComponent } from './pages/default/modules/smart-reporting/notice-create/notice-create.component';
import { SmartReportingNoticesTableComponent } from './pages/default/modules/smart-reporting/notices/table/table.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginRedirectComponent } from './pages/auth/login-redirect/login-redirect.component';
import { AuthCallbackComponent } from './pages/auth/auth-callback/auth-callback.component';
import { ClearComponent } from './pages/auth/clear/clear.component';
import { DetailFeedbackEditComponent } from './pages/default/projects/detail-feedback/edit.component';
import { DetailFeedbackListComponent } from './pages/default/projects/detail-feedback/list.component';
import { NewsComponent } from './pages/default/news/news.component';
import { ArticleComponent } from './pages/default/news/article.component';
import { TwoFactorComponent } from './pages/auth/two-factor/two-factor.component';
import { UserAdminComponent } from './pages/default/profile/user-admin.component';
import { ConfirmLeaveGuard } from './guards/confirm.guard';
import { UpdatesListComponent } from './pages/default/updates/list.component';
import { UpdatesEditComponent } from './pages/default/updates/edit.component';
import { DetailContactComponent } from './pages/default/projects/detail-contact.component';
import { DetailDashboardComponent } from './pages/default/projects/detail-dashboard.component';
import { ResidentLettersComponent } from './pages/default/news/resident-letters.component';
import { UpdatesViewComponent } from './pages/default/updates/view.component';
import { DetailEventsEditComponent } from './pages/default/projects/detail-events/edit.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginRedirectComponent,
  },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
  },
  {
    path: 'two-factor',
    canActivate: [AuthGuard],
    component: TwoFactorComponent,
  },
  {
    path: 'clear',
    component: ClearComponent,
  },
  {
    path: '',
    component: DefaultComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'smart-reporting',
        component: SmartReportingDefaultComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'notices',
          },
          {
            path: 'notices',
            component: SmartReportingNoticesComponent,
            children: [
              {
                path: 'project/:projectSlug',
                component: SmartReportingNoticesTableComponent,
              },
            ],
          },
          {
            path: 'notices/create/:projectSlug',
            component: SmartReportingNoticeCreateComponent,
          },
          {
            path: 'notices/:id',
            component: SmartReportingNoticeDetailComponent,
          },
          {
            path: 'themes/:id',
            component: SmartReportingThemesComponent,
          },
          {
            path: 'themes',
            component: SmartReportingThemesComponent,
          },
          {
            path: 'settings',
            component: SmartReportingSettingsComponent,
          },
        ],
      },
      {
        path: 'organization/profile',
        component: CompanyComponent,
      },
      {
        path: 'organization/admin',
        component: UserAdminComponent,
      },
      {
        path: 'profile/personal',
        component: PersonalComponent,
      },
      {
        path: 'profile/company/:id',
        component: CompanyComponent,
      },
      {
        path: 'profile/company',
        component: CompanyComponent,
      },
      {
        path: 'profile/company/admin/:id',
        component: UserAdminComponent,
      },
      {
        path: 'profile/password',
        component: PasswordComponent,
      },
      {
        path: 'profile/export',
        component: ExportComponent,
      },
      {
        path: 'tickets',
        component: AllComponent,
      },
      {
        path: 'tickets/:id',
        component: AllComponent,
      },
      {
        path: '',
        component: ListComponent,
      },
      {
        path: 'knowledge',
        component: NewsComponent,
        data: {
          type: 'knowledge',
        },
      },
      {
        path: 'articles/:slug',
        component: ArticleComponent,
      },
      {
        path: 'resident-letters',
        component: ResidentLettersComponent,
      },
      {
        path: 'updates',
        component: UpdatesListComponent,
      },
      {
        path: 'customers/:slug',
        children: [
          {
            path: 'updates/new',
            component: UpdatesEditComponent,
            canDeactivate: [ConfirmLeaveGuard],
          },
          {
            path: 'updates/:id',
            component: UpdatesViewComponent,
          },
          {
            path: 'updates/:id/edit',
            component: UpdatesEditComponent,
            canDeactivate: [ConfirmLeaveGuard],
          },
        ],
      },
      {
        path: 'projects/:slug',
        component: DetailComponent,
        children: [
          {
            path: '',
            component: DetailDashboardComponent,
          },
          {
            path: 'phases',
            component: DetailPhasesListComponent,
          },
          {
            path: 'phases/new',
            component: DetailPhasesEditComponent,
          },
          {
            path: 'phases/:id/edit',
            component: DetailPhasesEditComponent,
          },
          {
            path: 'events/new',
            component: DetailEventsEditComponent,
          },
          {
            path: 'events/:id/edit',
            component: DetailEventsEditComponent,
          },
          {
            path: 'updates',
            component: UpdatesListComponent,
          },
          {
            path: 'updates/new',
            component: UpdatesEditComponent,
            canDeactivate: [ConfirmLeaveGuard],
          },
          {
            path: 'updates/:id',
            component: UpdatesViewComponent,
          },
          {
            path: 'updates/:id/edit',
            component: UpdatesEditComponent,
            canDeactivate: [ConfirmLeaveGuard],
          },
          {
            path: 'tickets',
            component: AllComponent,
          },
          {
            path: 'contact',
            component: DetailContactComponent,
          },
          {
            path: 'feedback',
            component: DetailFeedbackListComponent,
          },
          {
            path: 'feedback/new',
            component: DetailFeedbackEditComponent,
          },
          {
            path: 'feedback/:id/edit',
            component: DetailFeedbackEditComponent,
          },
          {
            path: 'satisfaction-monitor',
            component: DetailSatisfactionMonitorComponent,
          },
          {
            path: 'livestreams',
            component: DetailLivestreamsComponent,
          },
          {
            path: 'statistics',
            component: DetailStatisticsComponent,
          },
          {
            path: 'general',
            component: DetailGeneralComponent,
          },
          {
            path: 'targets',
            component: DetailTargetsComponent,
          },
          {
            path: 'users',
            component: UserAdminComponent,
          },
          {
            path: 'widget',
            component: DetailWidgetComponent,
          },
          {
            path: 'smart-reporting',
            component: SmartReportingNoticesComponent,
            children: [
              {
                path: '',
                component: SmartReportingNoticesTableComponent,
              },
            ],
          },
          {
            path: 'smart-reporting/create',
            component: SmartReportingNoticeCreateComponent,
            canDeactivate: [ConfirmLeaveGuard],
          },
          {
            path: 'smart-reporting/:id',
            component: SmartReportingNoticeDetailComponent,
          },
        ],
      },
      {
        path: 'projects', // create
        component: DetailComponent,
        children: [
          {
            path: '',
            component: DetailGeneralComponent,
          },
        ],
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
