import { Component, Input } from '@angular/core';
import { Poll } from '../interfaces/poll';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';
import { VersionDirective } from '../directives/version.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  standalone: true,
  imports: [NgFor, NgIf, VersionDirective, InlineSVGModule, TranslateModule],
})
export class PollComponent {
  public poll: Poll;
  public totalVotes = 0;

  @Input('poll') public set pollSetter(item: Poll) {
    this.poll = item;

    this.loadResults();
  }

  constructor(private sanitizer: DomSanitizer) {}

  private loadResults() {
    let totalVotes = 0;
    let maxVotes = 0;

    for (const choice of this.poll.choices) {
      totalVotes += choice.votes;

      if (choice.votes > maxVotes) {
        maxVotes = choice.votes;
      }
    }

    let rest = 100;
    let index = 1;

    for (const choice of this.poll.choices) {
      if (totalVotes === 0) {
        choice.percentage = 0;
        choice.percentageSize = 5;
      } else {
        let percentage;

        if (index === this.poll.choices.length) {
          percentage = rest;
        } else {
          percentage = Math.round((choice.votes / totalVotes) * 100);

          rest -= percentage;
        }

        choice.percentage = percentage;
        choice.percentageSize = choice.percentage;
      }

      if (choice.filePath != null) {
        choice.image = this.sanitizer.bypassSecurityTrustStyle(
          `url(${choice.filePathThumbnails?.medium})`
        );
      }

      if (choice.votes === maxVotes && choice.votes > 0) {
        choice.active = true;
      }

      index++;
    }

    this.totalVotes = totalVotes;
  }
}
