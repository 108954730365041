import { Injectable } from '@angular/core';
import { ProjectDocument } from '../interfaces/project-document';
import { CollectionResponse } from '../interfaces/collection-response';
import { DocumentCategoryService } from './document-category.service';
import { DocumentCategory } from '../interfaces/document-category';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class ProjectDocumentService {
  static base = '/project-documents';
  static storeKey = 'project-documents';

  public updated$ = new BehaviorSubject<void>(null);

  constructor(
    private http: HttpClient,
    private storageService: LocalStorageService
  ) {}

  public fetch(id: number): Promise<ProjectDocument> {
    return this.http
      .get<ProjectDocument>(
        environment.apiUrl + ProjectDocumentService.base + '/' + id
      )
      .toPromise();
  }

  public fetchListForCategory(
    category: DocumentCategory
  ): Observable<ProjectDocument[]> {
    return Observable.create((observer: Observer<ProjectDocument[]>) => {
      const stored = this.storageService.retrieve(
        this.createStorageKey(category)
      );
      if (stored != null) {
        observer.next(stored);
      }
      this.fetchForCategory(category)
        .then((data: ProjectDocument[]) => {
          observer.next(data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  public delete(projectDocument: ProjectDocument): Promise<ProjectDocument> {
    return this.http
      .delete(
        environment.apiUrl +
          `${ProjectDocumentService.base}/${projectDocument.id}`
      )
      .toPromise()
      .then((response) => {
        this.updated$.next(null);
        return response as Promise<ProjectDocument>;
      });
  }

  public update(slug: number, data: ProjectDocument): Promise<ProjectDocument> {
    return this.http
      .put(environment.apiUrl + ProjectDocumentService.base + '/' + slug, data)
      .toPromise()
      .then((response) => {
        this.updated$.next(null);
        return response as Promise<ProjectDocument>;
      });
  }

  public create(projectDocument: ProjectDocument): Promise<ProjectDocument> {
    return this.http
      .post(environment.apiUrl + ProjectDocumentService.base, {
        ...projectDocument,
        category:
          '/api' +
          DocumentCategoryService.base +
          '/' +
          projectDocument.category.id,
      })
      .toPromise()
      .then((response) => {
        this.updated$.next(null);
        return response as Promise<ProjectDocument>;
      });
  }

  private async fetchForCategory(category: DocumentCategory) {
    const response: CollectionResponse<ProjectDocument> = await this.http
      .get<CollectionResponse<ProjectDocument>>(
        environment.apiUrl + `/document-categories/${category.id}/documents`
      )
      .toPromise();

    const data = response['hydra:member'];
    this.storageService.store(this.createStorageKey(category), data);

    return data;
  }

  private createStorageKey(category: DocumentCategory): string {
    return `${ProjectDocumentService.storeKey}-${category.id}`;
  }
}
