<div class="input-file-preview">
  <div *ngIf="path != ''" class="input-file-preview-holder">
    <img class="input-file-preview-image" *ngIf="!isVideo()" [src]="getUrl()" />
    <video class="input-file-preview-video" *ngIf="isVideo()" controls>
      <source [src]="getUrl()" />
    </video>
    <div class="input-file-preview-name" *ngIf="showFileNameThumbnail">
      {{ 'input_file_preview.pdf_file' | translate }}
    </div>
    <div class="input-file-preview-remove" (click)="clear()" *ngIf="!isEmpty()">
      <div
        [inlineSVG]="'/assets/img/icons/delete.svg'"
        class="icon inline-svg__item"
      ></div>
      {{ deleteText }}
    </div>
    <div class="input-file-no-image" *ngIf="isEmpty()">
      {{ 'input_file_preview.no_file' | translate }}
    </div>
  </div>
</div>
