import { Component, Input } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeStyle,
} from '@angular/platform-browser';
import { ConfirmDeleteComponent } from 'src/app/components/confirm-delete.component';
import { Update } from 'src/app/interfaces/update';
import { UpdateAttachment } from 'src/app/interfaces/update-attachment';
import { AttachmentHelper } from 'src/app/shared/attachment-helper';
import { TranslateModule } from '@ngx-translate/core';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { PollComponent } from 'src/app/components/poll.component';
import { ClipboardModule } from 'ngx-clipboard';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { RouterLink } from '@angular/router';
import { AccessDirective } from 'src/app/directives/access.directive';
import { NgIf, NgFor, DatePipe, NgTemplateOutlet } from '@angular/common';
import { VersionDirective } from 'src/app/directives/version.directive';
import { FileUrlPipe } from '../../../pipes/file-url.pipe';

@Component({
  selector: 'app-default-updates-tile',
  templateUrl: 'tile.component.html',
  standalone: true,
  imports: [
    NgIf,
    VersionDirective,
    AccessDirective,
    RouterLink,
    InlineSVGModule,
    ClipboardModule,
    NgFor,
    PollComponent,
    DatePipe,
    TruncateModule,
    TranslateModule,
    NgTemplateOutlet,
    FileUrlPipe,
  ],
})
export class UpdatesTileComponent {
  @Input() update: Update;
  @Input() confirmDelete: ConfirmDeleteComponent;
  @Input() isCustomer: boolean = false;
  @Input() standalone: boolean = false;

  moreAttachments: boolean = false;
  public prefix: string;

  previewUrl: string;
  copied: boolean = false;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.update?.customer) {
      this.prefix = 'customers/' + this.update.customer.slug;
    } else {
      this.prefix = 'projects/' + this.update.project.slug;
    }
  }

  /**
   * @returns {void}
   */
  viewMoreAttachments() {
    this.moreAttachments = true;
  }

  /**
   * @returns {boolean}
   */
  hasBreak(): boolean {
    return (
      this.update.content != null &&
      this.update.content.split(' ').length >
        (this.update.attachments.length === 0 ? 50 : 30)
    );
  }

  /**
   * Way to test whether a string is a PDF file
   * @param {UpdateAttachment} attachment
   * @returns {boolean}
   */
  isPdf(attachment: UpdateAttachment) {
    return /\.pdf$/.test(attachment.filePath);
  }

  isYoutubeVideo(attachment: UpdateAttachment) {
    return attachment.videoId != null;
  }

  isVideo(attachment: UpdateAttachment) {
    return (
      /\.mp4$/i.test(attachment.filePath) ||
      /\blob:$/i.test(attachment.filePath) ||
      /\.mov$/i.test(attachment.filePath)
    );
  }

  /**
   * @param attachment
   * @returns {SafeStyle|string}
   */
  getAttachmentImage(attachment: UpdateAttachment): SafeStyle | string {
    const url =
      attachment.videoId != null
        ? AttachmentHelper.getVideoImageForId(attachment.videoId)
        : attachment.thumbnailPathThumbnails?.medium ??
          attachment.filePathThumbnails?.medium;

    return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
  }

  getAttachmentVideo(attachment: UpdateAttachment): SafeResourceUrl | string {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      attachment.filePathThumbnails.medium
    );
  }

  getText(text: string) {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  isFuture(update: Update) {
    return new Date(update.publicationDate) > new Date();
  }

  public getPreviewUrl() {
    if (!this.update.project) {
      return;
    }

    return (this.previewUrl =
      'https://' +
      this.update.project.pwaDomain +
      '/projects/' +
      this.update.project.slug +
      '/updates?preview_token=' +
      this.update.project.previewToken);
  }

  urlCopied() {
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 2000);
  }
}
