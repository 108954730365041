<div class="bouwapp-header">
    <div class="bouwapp-header__text">
        <h1>{{ 'projects.detail.feedback.list.title' | translate }}</h1>
        <p *ngIf="surveys?.length">{{ 'projects.detail.feedback.list.description' | translate }}</p>
        <p *ngIf="!surveys?.length">{{ 'projects.detail.feedback.list.empty' | translate }}</p>
    </div>
    <div class="bouwapp-header__buttons">
        <a
            routerLink="new"
            class="primary"
            appAccess
            role="manager"
            [project]="project"
        >
            {{ 'projects.detail.feedback.list.create' | translate }}
        </a>
    </div>
</div>

<form [formGroup]="filterForm" class="bouwapp-toolbar hasSortingAndFilter">
    <div *ngIf="surveys?.length">
        <label for="search">{{
                'projects.detail.feedback.list.search.label' | translate
            }}</label>
        <input
            formControlName="search"
            [attr.placeholder]="
              'projects.detail.feedback.list.search.placeholder' | translate
            "
            type="search"
            class="search"
            id="search"
        />
    </div>
    <div>
        <label for="filter">{{
                'projects.detail.feedback.list.filter.label' | translate
            }}</label>
        <select
            formControlName="filter"
            [attr.title]="
              'projects.detail.feedback.list.filter.placeholder' | translate
            "
            id="filter"
            class="select select--m"
        >
            <option [value]="[SurveyStatus.ACTIVE, SurveyStatus.INACTIVE]">
                {{ 'projects.detail.feedback.list.filter.all' | translate }}
            </option>
            <option [value]="SurveyStatus.ACTIVE">
                {{ 'projects.detail.feedback.list.filter.active' | translate }}
            </option>
            <option [value]="SurveyStatus.INACTIVE">
                {{ 'projects.detail.feedback.list.filter.inactive' | translate }}
            </option>
        </select>
    </div>
    <div>
        <label for="sort">{{
                'projects.detail.feedback.list.sort.label' | translate
            }}</label>
        <select
            formControlName="order"
            [attr.title]="
              'projects.detail.feedback.list.sort.placeholder' | translate
            "
            id="sort"
            class="select select--m"
        >
            <option value="title">
                {{ 'projects.detail.feedback.list.sort.name.asc' | translate }}
            </option>
            <option value="-title">
                {{ 'projects.detail.feedback.list.sort.name.desc' | translate }}
            </option>
            <option value="createdAt">
                {{ 'projects.detail.feedback.list.sort.date.asc' | translate }}
            </option>
            <option value="-createdAt">
                {{ 'projects.detail.feedback.list.sort.date.desc' | translate }}
            </option>
        </select>
    </div>
</form>

<section>
    <div class="feedback" *ngIf="surveys">
        <div class="feedback__list" *ngIf="surveys.length > 0">
            <table>
                <thead>
                <tr>
                    <th
                        [attr.data-th]="'project.detail.feedback.list.name' | translate"
                    >
                        {{ 'project.detail.feedback.list.name' | translate }}
                    </th>
                    <th
                        [attr.data-th]="'project.detail.feedback.list.status' | translate"
                    >
                        {{ 'project.detail.feedback.list.status' | translate }}
                    </th>
                    <th
                        [attr.data-th]="'project.detail.feedback.list.push' | translate"
                    >
                        {{ 'project.detail.feedback.list.push' | translate }}
                    </th>
                    <th
                        [attr.data-th]="
                'project.detail.feedback.list.created_by' | translate
              "
                    >
                        {{ 'project.detail.feedback.list.created_by' | translate }}
                    </th>
                    <th
                        [attr.data-th]="
                'project.detail.feedback.list.created_on' | translate
              "
                    >
                        {{ 'project.detail.feedback.list.created_on' | translate }}
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    *ngFor="
              let survey of surveys
                | orderBy : filterForm.get('order').value
                | filterBy : ['status'] : filterForm.get('filter').value : true
                | filterBy : ['title'] : filterForm.get('search').value
            "
                >
                    <td
                        [attr.data-th]="'project.detail.feedback.list.name' | translate"
                    >
              <span
                  routerLink="/projects/{{ project.slug }}/feedback/{{
                  survey.id
                }}/edit"
              >{{ survey.title }}</span
              >
                        <span *ngIf="survey.submittedCount === 1">{{
                                'project.detail.feedback.list.submissions.singular'
                                    | translate : { submissions: survey.submittedCount }
                            }}</span>
                        <span *ngIf="survey.submittedCount !== 1">{{
                                'project.detail.feedback.list.submissions.plural'
                                    | translate : { submissions: survey.submittedCount }
                            }}</span>
                    </td>
                    <td
                        [ngClass]="[getStatusClass(survey)]"
                        [attr.data-th]="'project.detail.feedback.list.status' | translate"
                    >
                        {{ survey.status | translate }}
                    </td>
                    <td
                        [attr.data-th]="'project.detail.feedback.list.push' | translate"
                    >
                        <span *ngIf="!survey.pushedAt">-</span>
                        <span *ngIf="survey.pushedAt">{{
                                survey.pushedAt | date : 'd MMM y H:s'
                            }}</span>
                    </td>
                    <td
                        [attr.data-th]="
                'project.detail.feedback.list.created_by' | translate
              "
                    >
                        {{ project.customer.name }}
                    </td>
                    <td
                        [attr.data-th]="
                'project.detail.feedback.list.created_on' | translate
              "
                    >
                        {{ survey.createdAt | date : 'd MMM y' }}
                    </td>
                    <td
                        [attr.data-th]="
                'project.detail.feedback.list.actions' | translate
              "
                    >
                        <div class="tile-actions">
                            <div
                                routerLink="/projects/{{ project.slug }}/feedback/{{
                    survey.id
                  }}/edit"
                                [queryParams]="{ tab: 'submissions' }"
                                [inlineSVG]="'/assets/img/icons/stats.svg'"
                                class="tile-action tile-submissions inline-svg__item"
                            ></div>
                            <div
                                routerLink="/projects/{{ project.slug }}/feedback/{{
                    survey.id
                  }}/edit"
                                [inlineSVG]="'/assets/img/icons/edit-small.svg'"
                                class="tile-action tile-edit inline-svg__item"
                                appAccess
                                role="manager"
                                [project]="project"
                            ></div>
                            <div
                                [inlineSVG]="'/assets/img/icons/delete.svg'"
                                class="tile-action tile-delete inline-svg__item"
                                (click)="
                    confirmDeleteSurvey.confirmDelete(survey);
                    $event.stopPropagation();
                    (false)
                  "
                                appAccess
                                role="manager"
                                [project]="project"
                            ></div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>

<app-confirm-delete
    #confirmDeleteSurvey
    (confirmed)="deleteSurvey($event)"
></app-confirm-delete>
