import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { NgIf } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { VersionDirective } from '../directives/version.directive';

@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  standalone: true,
  imports: [InlineSVGModule, NgIf, VersionDirective],
})
export class ModalComponent {
  @ViewChild('modalContainer', { static: false }) modalContainer!: ElementRef; // Reference to the modal's container

  /**
   * @type {boolean}
   */
  @Input() isOpen = false;

  /**
   * @type {boolean}
   */
  @Input() image = false;

  /**
   * @type {string}
   */
  @Input() title;

  @Input() svg;

  @Input() subtitle: string;

  /**
   * @type {string}
   */
  @Input() scroll = true;

  /**
   * @type {string}
   */
  public imageUrl: string | SafeResourceUrl;

  @Output() public onClose = new EventEmitter<boolean>();

  /**
   * @returns {void}
   */
  close(inModal: boolean = false): void {
    this.isOpen = false;

    this.onClose.emit(inModal);
  }

  /**
   * @param image
   * @returns {void}
   */
  open(image?: string | SafeResourceUrl): void {
    this.imageUrl = image;
    this.isOpen = true;
  }

  public wasClickedInside(event: MouseEvent): boolean {
    if (this.modalContainer && this.modalContainer.nativeElement) {
      return this.modalContainer.nativeElement.contains(event.target);
    }
    return false;
  }
}
