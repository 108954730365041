import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';
import { UserService } from './user.service';
import { UserDataService } from './user-data.service';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { LocaleService } from './locale.service';
import { AccessService } from './access.service';
import { environment } from '../../environments/environment';
import { Mutex } from 'async-mutex';
import { platform, PlatformService } from './platform.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private oauthService: OAuthService,
    private userService: UserService,
    private userDataService: UserDataService,
    private localeService: LocaleService,
    private accessService: AccessService,
    private platformService: PlatformService
  ) {}

  public setup() {
    this.oauthService.configure(this.generateConfig());
  }

  public login() {
    this.oauthService.initLoginFlow();
  }

  public tradeToken() {
    return this.oauthService.tryLogin();
  }

  public logout() {
    return this.oauthService.logOut();
  }

  public async waitForToken() {
    return await this.oauthService.events
      .pipe(
        filter((e) => e.type === 'token_received'),
        switchMap(() => {
          this.accessService.tokenReceived.next(true);
          return from(this.updateUserData());
        }),
        catchError(() => {
          this.logout();
          return of(null);
        }),
        first()
      )
      .toPromise();
  }

  public isAuthenticated() {
    return (
      this.oauthService.hasValidAccessToken() ||
      !!this.oauthService.getRefreshToken()
    );
  }

  public getAccessToken(): string | null {
    return this.oauthService.getAccessToken();
  }

  public refreshToken(): Observable<boolean> {
    return from(this.oauthService.refreshToken()).pipe(
      switchMap(() => {
        return from(this.updateUserData()).pipe(
          map(() => true),
          catchError((error) => {
            console.error('Error updating user data:', error);
            return of(false);
          })
        );
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  public generateConfig(): AuthConfig {
    return {
      issuer: `${platform.baseUrl}auth`,
      redirectUri: window.location.origin + '/auth-callback',
      postLogoutRedirectUri: window.location.origin + `/login`,
      loginUrl: `${platform.baseUrl}auth/authorize`,
      tokenEndpoint: `${platform.baseUrl}auth/token`,
      logoutUrl: `${platform.baseUrl}auth/logout?client_id=${platform.clientId}`,
      clientId: platform.clientId,
      requireHttps: platform.baseUrl.startsWith('https'),
      responseType: 'code',
      oidc: false,
      scope: [
        'updates:read',
        'updates:write',
        'customers:read',
        'customers:write',
        'projects:read',
        'projects:write',
        'projects:usermanagement',
        'planning:read',
        'planning:write',
        'smartreporting:read',
        'smartreporting:write',
        'tickets:read',
        'tickets:write',
        'profile:manage',
        'surveys:manage',
      ].join(' '),
    };
  }

  private async updateUserData() {
    // fetch additional details
    const user = await this.userService.fetchMe();
    await this.userDataService.storeUser(user);
    await this.accessService.updateAccessControlList();

    return user;
  }
}
