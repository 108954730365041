import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retryWhen, delay, scan } from 'rxjs/operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),

      retryWhen((errors) =>
        errors.pipe(
          scan((retryCount, error) => {
            if (
              error instanceof HttpErrorResponse &&
              error.status === 0 &&
              error.message.endsWith('0 Unknown Error')
            ) {
              if (retryCount >= 2) {
                throw error;
              }
              return retryCount + 1;
            } else {
              throw error;
            }
          }, 0),
          delay(2000)
        )
      )
    );
  }
}
