import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Injectable, signal } from '@angular/core';
import Quill from 'quill';

@Injectable({
  providedIn: 'root',
})
export class QuillService {
  private uniqueIdentifier: number = 0;
  private loadingState$ = signal<boolean>(true);

  isQuillLoading = this.loadingState$.asReadonly();

  public constructor(private http: HttpClient) {}

  public getIdentifier() {
    this.uniqueIdentifier++;

    return this.uniqueIdentifier;
  }

  public loadIcon(iconName: string, iconUrl: string): void {
    this.loadingState$.set(false);
    this.http.get(iconUrl, { responseType: 'text' }).subscribe((svgContent) => {
      const icons = Quill.import('ui/icons');
      if (iconName.startsWith('list-')) {
        const listType = iconName.split('-')[1];
        icons['list'][listType] = svgContent;
      } else {
        icons[iconName] = svgContent;
      }
      if (iconName === 'list-bullet') {
        this.loadingState$.set(true);
      }
    });
  }
}
