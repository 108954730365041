<app-success-message [hidden]="!inviteSaved" [onScreen]="true">
  {{ 'project.detail.settings.users.invited' | translate }}
</app-success-message>

<app-success-message [hidden]="!editSaved" [onScreen]="true">
  {{ 'project.detail.settings.users.updated' | translate }}
</app-success-message>

<app-success-message [hidden]="!deleted" [onScreen]="true">
  {{ 'project.detail.settings.users.delete' | translate }}
</app-success-message>

<app-error-message [hidden]="!cannotSave" [onScreen]="true">
  {{ 'project.detail.settings.users.exists' | translate }}
</app-error-message>

<section
  class="projects detail projects-settings"
  [ngClass]="{ container: !project }"
>
  <app-manage-users
    [loading]="loading"
    [projectUsers]="projectUsers"
    (deleteUser)="delete($event)"
    (editUser)="update($event)"
    (createUser)="create($event)"
    [customerInput]="customer"
    [project]="project"
    (selectedCustomer)="changeCustomer($event)"
  >
  </app-manage-users>
  <ng-container *appVersion="1">
    <app-list-users
      [loading]="loading"
      [projectUsers]="projectUsers"
      [customer]="customer"
    ></app-list-users>
  </ng-container>
  <ng-container *appVersion="2">
    <form [formGroup]="filterForm">
      <div class="bouwapp-toolbar hasSorting">
        <div>
          <input
            type="search"
            class="input-lg search"
            [attr.placeholder]="
              'project.detail.settings.users.search' | translate
            "
            formControlName="search"
          />
        </div>
        <div>
          <select
            [attr.title]="'project.list.sort' | translate"
            id="sort"
            class="select select--m"
            formControlName="sort"
          >
            <option *ngFor="let sort of sortItems" [ngValue]="sort">
              {{ sort.label | translate }}
            </option>
          </select>
        </div>
      </div>
      <div class="projects-settings__users">
        <div class="filter">
          <div class="filter__title">
            <h3>{{ 'project.list.filter.title' | translate }}</h3>
          </div>

          <div class="filter__group" formArrayName="roles">
            <label class="filter__label">
              {{ 'project.list.filter.role.title' | translate }}
            </label>
            <div class="checkboxes">
              <div
                *ngFor="let role of allRoles; let index = index"
                class="checkbox"
              >
                <input
                  type="checkbox"
                  id="role{{ role }}"
                  [formControlName]="index"
                />
                <label for="role{{ role }}">
                  {{ 'project.list.filter.role.' + role | translate }}
                  <span *ngIf="roleCounts">({{ roleCounts[role] }})</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="projects-settings__users__wrapper">
          <p [hidden]="loading || !projectUsers?.length">
            {{
              'project.detail.settings.users.table.result.title'
                | translate : { total: projectUsers.length }
            }}
          </p>
          <div class="users__list">
            <app-list-users
              [loading]="loading"
              [projectUsers]="projectUsers"
              [customer]="customer ?? project?.customer"
              [project]="project"
              [editAdmins]="project ? false : true"
            ></app-list-users>
          </div>
          <app-loader *ngIf="loading"></app-loader>
        </div>
      </div>
    </form>
  </ng-container>
</section>
