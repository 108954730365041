<div class="input-file--overlay" *ngIf="!uploadJson && uploading && standalone">
  <i class="spinner"></i>
</div>
<div class="input-file" [class.selected]="selected && !uploadJson">
  <span
    class="input-file__add"
    [inlineSVG]="
      selected ? '/assets/img/icons/edit.svg' : '/assets/img/icons/add.svg'
    "
  ></span>
  <ng-content></ng-content>
  <div
    *ngIf="standalone && selected"
    inlineSVG="/assets/img/icons/trash.svg"
    (click)="removeFile()"
    class="input-file__clear"
  ></div>
  <input
    type="file"
    [id]="'media' + type"
    [accept]="getMimeTypes()"
    (change)="upload($event.target.files)"
    [attr.multiple]="standalone ? null : ''"
    title=""
    #inputFile
  />
</div>
