import { Component, forwardRef, Input, OnInit, Optional } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import { DatePipe, NgIf, NgFor, NgClass } from '@angular/common';
import { DatePickerItem } from '../interfaces/date-picker-item';
import { TranslateModule } from '@ngx-translate/core';
import { SwitchOptionComponent } from './switch-option.component';
import { SwitchComponent } from './switch.component';
import { VersionDirective } from '../directives/version.directive';

@Component({
  selector: 'app-date-picker-v2',
  templateUrl: 'date-picker-v2.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerV2Component),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    VersionDirective,
    ReactiveFormsModule,
    NgFor,
    NgClass,
    SwitchComponent,
    SwitchOptionComponent,
    DatePipe,
    TranslateModule,
  ],
})
export class DatePickerV2Component implements ControlValueAccessor, OnInit {
  static COMPARISON_FORMAT = 'YYYY-MM-DD';

  currentDate: moment.Moment;
  @Input() typeControl: FormControl;
  @Input() inline = false;
  @Input() dateOnly = false;
  public showPeriod: boolean;
  public type: string;
  public actualDate = new FormControl(null);
  public dateForm: FormGroup;
  public years: number[];
  public days: number[];

  items: DatePickerItem[];

  constructor(private formBuilder: FormBuilder) {
    this.createDateForm();
  }

  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  /**
   * @param value
   */
  writeValue(value: string): void {
    this.updateDate(value);
  }

  getMonthNames() {
    const monthNames = [];
    for (let i = 0; i < 12; i++) {
      monthNames.push({
        value: i + 1,
        label: new Date(0, i).toLocaleString('en-US', { month: 'long' }), // English month name
      });
    }
    return monthNames;
  }

  /**
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /**
   * @returns {void}
   */
  ngOnInit(): void {
    this.updateYears(moment());
    this.currentDate = moment();
    this.actualDate.patchValue(
      this.currentDate.format(DatePickerV2Component.COMPARISON_FORMAT)
    );

    this.dateForm.valueChanges.subscribe((value) => {
      let date = moment()
        .year(value.year)
        .month(value.month - 1);

      const daysInMonth = date.daysInMonth();
      if (daysInMonth < value.day) {
        // check if a date is still valid (31st of December -> 31st of November is not valid)
        this.dateForm.get('day').patchValue(daysInMonth);
        return;
      } else {
        date = date.date(value.day);
      }

      if (value.type === 'QUARTER') {
        date.quarter(value.quarter);
      }

      this.currentDate = date;
      this.actualDate.patchValue(
        this.currentDate.format(DatePickerV2Component.COMPARISON_FORMAT)
      );

      this.updateDays(this.currentDate);
      this.propagateChange(moment(this.actualDate.value).startOf('day').format('YYYY-MM-DDTHH:mm'));
      this.propagateTouch(moment(this.actualDate.value).startOf('day').format('YYYY-MM-DDTHH:mm'));
      this.updateType(value);
    });

    if (this.typeControl) {
      this.typeControl.valueChanges.subscribe((value) => {
        this.handleTypeControlChange(value);
      });

      this.handleTypeControlChange(this.typeControl.value);
    }
  }

  patchDateForm(event: any) {
    this.updateDate(event.target.value === '' ? null : event.target.value);
  }

  handleTypeControlChange(value: string) {
    const mainTypeValue =
      this.typeControl.value === 'EXACT_DATE' || this.typeControl.value === null
        ? 'EXACT_DATE'
        : 'PERIOD';
    let typeValue = this.typeControl.value;

    if (['Q1', 'Q2', 'Q3', 'Q4'].includes(this.typeControl.value)) {
      typeValue = 'QUARTER';
      const quarterValue = parseInt(this.typeControl.value[1], 10);
      this.dateForm
        .get('quarter')
        ?.patchValue(quarterValue, { emitEvent: false });
    }

    this.dateForm.get('mainType')?.valueChanges.subscribe((mainType) => {
      if (mainType === 'PERIOD') {
        this.updateDate(this.currentDate);
      } else if (mainType === 'EXACT_DATE') {
        this.updateDate(moment().format());
      }
    });

    this.dateForm
      .get('mainType')
      ?.patchValue(mainTypeValue, { emitEvent: false });
    this.dateForm.get('type')?.patchValue(typeValue, { emitEvent: false });
  }

  select(dateItem: DatePickerItem): void {
    if (!dateItem.previous) {
      const date = dateItem.date;

      this.updateDate(date);
    }
  }

  validate(control: FormControl): any {
    return this.dateForm.valid ? null : { invalidDate: true };
  }

  private createDateForm(): void {
    this.dateForm = this.formBuilder.group({
      day: ['', Validators.required],
      month: ['', Validators.required],
      year: ['', Validators.required],
      mainType: ['EXACT_DATE'],
      type: ['MONTH'],
      quarter: [1],
    });
  }

  /**
   * @param {string} value
   * @param {boolean} dateOnly
   */
  private updateDate(value: string | moment.Moment) {
    if (value) {
      const date = moment(value);

      this.currentDate = date;
      this.actualDate.patchValue(
        this.currentDate.format(DatePickerV2Component.COMPARISON_FORMAT)
      );

      const day = +date.format('D');
      const month = +date.format('M');

      const year = +date.format('YYYY');

      const quarter = moment(date).quarter();

      const hours = date.format('HH');
      const minutes = date.format('mm');

      const time = hours + ':' + minutes;

      this.dateForm.patchValue({
        day: day,
        month: month,
        year: year,
        time: time,
        quarter: quarter,
      });
    } else {
      this.propagateChange(value);
      this.propagateTouch(value);
    }
  }

  private updateYears(date: moment.Moment) {
    const year = +date.format('Y');
    this.years = this.createRange(year - 40, year + 40);
  }

  private updateDays(date: moment.Moment) {
    if (date.isValid() === false) {
      return;
    }
    const start = moment(date).startOf('month');
    const end = moment(date).endOf('month');

    this.days = this.createRange(+start.format('D'), +end.format('D'));

    const startOfWeek = moment(start).startOf('isoWeek');
    const endOfWeek = moment(end).endOf('isoWeek');

    const rangeMoment = extendMoment(moment);
    const month = date.format('M');

    this.items = [];

    Array.from(rangeMoment().range(startOfWeek, endOfWeek).by('day')).map(
      (item: moment.Moment) => {
        this.items.push({
          date: item.format(DatePickerV2Component.COMPARISON_FORMAT),
          previous: item.format('M') !== month,
        });
      }
    );
  }

  private createRange(start: number, end: number): number[] {
    return Array.apply(null, Array(end - start + 1)).map(function (discard, n) {
      return n + start;
    });
  }

  /**
   * @param value
   * @returns {void}
   */
  private updateType(value): void {
    if (!this.typeControl) {
      return;
    }

    this.showPeriod = value.mainType === 'PERIOD';

    // user switched from exact to period, so reset type to month
    if (value.type === 'EXACT_DATE' && this.showPeriod === true) {
      this.dateForm.get('type').patchValue('MONTH');
      return;
    }

    this.type = value.type;

    let emitType = 'EXACT_DATE';

    if (this.showPeriod) {
      if (value.type === 'QUARTER') {
        emitType = 'Q' + value.quarter;
      } else {
        emitType = value.type;
      }
    }

    if (emitType != null) {
      this.typeControl.patchValue(emitType, { emitEvent: false });
    }
  }
}
