<div class="bouwapp-box__self__children">
  <app-loader *ngIf="loading"></app-loader>

  <div class="bouwapp-box__self__children__head">
    <p>{{ 'projects.detail.projects.title' | translate }}</p>
    <a
      (click)="openChildProjectAdd()"
      appAccess
      role="manager"
      [project]="project"
      >{{ 'projects.detail.projects.new' | translate }}</a
    >
  </div>

  <p
    class="no-results"
    *ngIf="
      (!project.childrenProjects || !project.childrenProjects.length) &&
      !loading
    "
  >
    {{ 'projects.detail.projects.empty' | translate }}
  </p>

  <ul *ngIf="project.childrenProjects && !loading">
    <li class="project-child" *ngFor="let child of project.childrenProjects">
      <div
        class="project-child__icon"
        inlineSVG="/assets/img/icons/location--dark.svg"
      ></div>
      <div class="project-child__content">
        <div>
          <p (click)="openProject(child)">{{ child.name }}</p>
          <div>
            <span>{{ child.participants }}</span>
            <span *ngIf="child.startDate">
              {{ child.startDate | dateType : child.startType }}
            </span>
          </div>
        </div>
        <div>
          <span
            (click)="confirmDeleteChildProject.confirmDelete(child)"
            appAccess
            role="manager"
            [project]="project"
            >{{ 'projects.detail.projects.delete' | translate }}</span
          >
        </div>
      </div>
    </li>
  </ul>
</div>

<section class="projects child create">
  <app-modal
    [title]="'projects.detail.projects.new' | translate"
    #addChildProject
    [scroll]="false"
    (onClose)="resetChildProject()"
  >
    <form [formGroup]="childProjectsForm" (submit)="saveChildProject()">
      <app-form-group errorPath="projectId" [form]="childProjectsForm">
        <label>{{ 'projects.detail.projects.modal.label' | translate }}</label>
        <select id="project" formControlName="projectId">
          <option [ngValue]="null" selected disabled>
            {{ 'projects.detail.projects.modal.placeholder' | translate }}
          </option>
          <option
            *ngFor="let project of availableProjects"
            [ngValue]="project.id"
          >
            {{ project.name }}
          </option>
        </select>
      </app-form-group>

      <div class="form-group">
        <button type="submit" class="btn btn-primary" [appLoading]="loading">
          {{ 'projects.detail.projects.modal.save' | translate }}
        </button>
      </div>
    </form>
  </app-modal>
</section>

<app-confirm-delete
  #confirmDeleteChildProject
  (confirmed)="deleteChildProject($event)"
></app-confirm-delete>
