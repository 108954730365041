import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-error-message',
    templateUrl: 'error-message.component.html',
    standalone: true,
    imports: [NgClass]
})
export class ErrorMessageComponent {
  isHidden = true;
  @Input() timeout = 5000;
  @Input() onScreen = false;

  @Input()
  set hidden(hidden: boolean) {
    this.isHidden = hidden;

    if (!this.isHidden) {
      setTimeout(() => {
        this.isHidden = true;
      }, this.timeout);
    }
  }
}
