import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { User } from '../interfaces/user';
import { ProjectUser } from '../interfaces/project-user';
import { Customer } from '../interfaces/customer';
import { Thumbnail } from '../interfaces/thumbnail';
import { ReplaySubject } from 'rxjs';


@Injectable()
export class UserDataService {
  /**
   * @type {string}
   */
  prefix = 'user';

  public userLoaded = new ReplaySubject();
  public projectUserLoaded = new ReplaySubject();

  /**
   * @param localStorage
   */
  constructor(private localStorage: LocalStorageService) { }

  /**
   * @param {User} user
   * @returns {void}
   */
  public async storeUser(user: User): Promise<void> {
    await this.storeId(user.id);
    await this.storeEmail(user.email);
    await this.storeProjectUser(user.projectUser);
    await this.storeLastLogin(user.lastLogin);
    await this.storeActivatedAt(user.activatedAt);
    await this.storeSsoLogin(user.ssoLogin);
    await this.storeLanguage(user.language);
    this.userLoaded.next(true);
  }

  /**
   * @returns {Promise<User>}
   */
  public async retrieveUser(): Promise<User> {
    return {
      id: await this.retrieveId(),
      email: await this.retrieveEmail(),
      projectUser: await this.retrieveProjectUser(),
      lastLogin: await this.retrieveLastLogin(),
      language: await this.retrieveLanguage(),
      activatedAt: await this.retrieveActivatedAt(),
      ssoLogin: await this.retrieveSsoLogin(),
    };
  }

  /**
   * @param {number} userId
   */
  public async storeId(userId: number): Promise<void> {
    this.store('id', userId);
  }

  /**
   * @return {Promise<string>}
   */
  public async retrieveId(): Promise<number> {
    return this.retrieve<number>('id');
  }

  public async storeActivatedAt(activatedAt: string): Promise<void> {
    this.store('activated-at', activatedAt);
  }

  public async retrieveActivatedAt(): Promise<string> {
    return this.retrieve<string>('activated-at');
  }

  public async storeSsoLogin(ssoLogin: boolean): Promise<void> {
    this.store('sso-login', ssoLogin);
  }

  public async retrieveSsoLogin(): Promise<boolean> {
    return this.retrieve<boolean>('sso-login');
  }

  /**
   * @param {string} email
   */
  public async storeEmail(email: string): Promise<void> {
    this.store('email', email);
  }

  /**
   * @return {Promise<string>}
   */
  public async retrieveEmail(): Promise<string> {
    return this.retrieve<string>('email');
  }

  public async storeLanguage(language: string): Promise<void> {
    this.store('language', language);
  }

  public async retrieveLanguage(): Promise<string> {
    return this.retrieve<string>('language');
  }

  /**
   * @param {string} lastLogin
   */
  public async storeLastLogin(lastLogin: string): Promise<void> {
    this.store('last-login', lastLogin);
  }

  /**
   * @return {Promise<string>}
   */
  public async retrieveLastLogin(): Promise<string> {
    return this.retrieve<string>('last-login');
  }

  /**
   * Store basic details of a project user
   *
   * @param {ProjectUser} projectUser
   */
  public async storeProjectUser(projectUser: ProjectUser): Promise<void> {
    if (projectUser != null) {
      this.store('project-user-id', projectUser.id);
      this.store('project-user-first-name', projectUser.firstName);
      this.store('project-user-last-name', projectUser.lastName);
      this.store('project-user-force-two-factor', projectUser.forceTwoFactor);
      this.store('project-user-avatarThumbnails', projectUser.avatarThumbnails === undefined ? '' : projectUser.avatarThumbnails);
      this.store(
        'project-user-two-factor-enabled',
        projectUser.twoFactorEnabled
      );
      this.store('project-user-two-factor-code', projectUser.hasTwoFactorCode);
      this.store('project-user-beta', projectUser.beta);

      await this.storeCustomers(projectUser.customers);
      this.projectUserLoaded.next(true);
    }
  }

  /**
   * @return {Promise<ProjectUser>}
   */
  public async retrieveProjectUser(): Promise<ProjectUser> {
    return {
      id: await this.retrieve<number>('project-user-id'),
      firstName: await this.retrieve<string>('project-user-first-name'),
      lastName: await this.retrieve<string>('project-user-last-name'),
      avatarThumbnails: await this.retrieve<Thumbnail>('project-user-avatarThumbnails'),
      forceTwoFactor: await this.retrieve<boolean>('project-user-force-two-factor'),
      twoFactorEnabled: await this.retrieve<boolean>(
        'project-user-two-factor-enabled'
      ),
      hasTwoFactorCode: await this.retrieve<boolean>(
        'project-user-two-factor-code'
      ),
      beta: await this.retrieve<boolean>(
        'project-user-beta'
      ),
      customers: await this.retrieveCustomers(),
    };
  }

  /**
   * Store customers
   *
   * @param {Customer[]} customers
   */
  public async storeCustomers(customers: Customer[]): Promise<void> {
    if (customers != null) {
      this.store('customers', JSON.stringify(customers));
    }
  }

  public async storeCustomer(customer: Customer): Promise<void> {
    if (customer != null) {
      const customers = await this.retrieveCustomers();
      const foundCustomerIndex = customers.findIndex(
        (item) => +customer.id === +item.id
      );

      if (foundCustomerIndex > -1) {
        customers[foundCustomerIndex] = customer;
      } else {
        customers.push(customer);
      }

      await this.storeCustomers(customers);
    }
  }

  /**
   * @return {Promise<Customer[]>}
   */
  public async retrieveCustomers(): Promise<Customer[]> {
    return JSON.parse(await this.retrieve<string>('customers'));
  }

  /**
   * @param {string} token
   */
  public async storeToken(token: string): Promise<void> {
    this.store('token', token);
  }

  /**
   * @return {Promise<string>}
   */
  public async retrieveToken(): Promise<string> {
    return this.retrieve<string>('token');
  }

  /**
   * @param {string} token
   */
  public async storeRefreshToken(token: string): Promise<void> {
    this.store('refresh-token', token);
  }

  /**
   * @return {Promise<string>}
   */
  public async retrieveRefreshToken(): Promise<string> {
    return this.retrieve<string>('refresh-token');
  }

  /**
   * @param {string} key
   * @param {*} value
   * @return void
   */
  public store(key: string, value): void {
    this.localStorage.store(this.prefix + '|' + key, value);
  }

  /**
   * @return Promise<void[]>
   */
  public async clear(): Promise<void> {
    await this.localStorage.clear();
  }

  /**
   * @param {string} key
   * @return {Promise<*>}
   */
  public retrieve<T>(key: string): T {
    return this.localStorage.retrieve(this.prefix + '|' + key);
  }
}
