<ng-container *appVersion="2">
  <ng-container *ngIf="withSwitcher()">
    <div *ngIf="customerList?.length > 1" class="customer-picker">
      <div class="customer-picker__button" (click)="pick()">
        <span inlineSVG="/assets/v2/img/icons/chevron-right-left.svg"></span>
        <span>
          {{ currentCustomer?.name }}
        </span>
      </div>
    </div>
  </ng-container>
</ng-container>

<app-modal
  [title]="'customer_picker.title' | translate"
  (onClose)="modalClosed()"
>
  <p class="modal-description">
    {{ 'customer_picker.instruction' | translate }}
  </p>

  <form [formGroup]="form" (submit)="selectCompany()">
    <app-form-group [form]="form" errorPath="customer">
      <label for="customer">{{ 'customer_picker.company' | translate }}</label>
      <div class="input-button-group">
        <select id="customer" formControlName="customer">
          <option value="">
            {{ 'customer_picker.select_company' | translate }}
          </option>
          <option *ngFor="let customer of filteredList" [value]="customer.id">
            {{ customer.name }}
          </option>
        </select>
        <button class="button button--m button--flat bg--orange">
          {{ 'customer_picker.confirm' | translate }}
        </button>
      </div>
    </app-form-group>
  </form>
</app-modal>