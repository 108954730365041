import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { PlatformService } from 'src/app/services/platform.service';
import { FileUrlPipe } from '../../../pipes/file-url.pipe';

@Component({
  selector: 'app-resident-letters',
  templateUrl: './resident-letters.component.html',
  standalone: true,
  imports: [NgIf, TranslateModule, InlineSVGModule, FileUrlPipe],
})
export class ResidentLettersComponent implements OnInit {
  lang: string = 'en';

  constructor(
    private translateService: TranslateService,
    private router: Router
  ) {
    if (PlatformService.platform.id !== 'ba') {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit(): void {
    this.lang = this.translateService.currentLang;
  }
}
