<div
  class="app__toggle"
  (click)="toggleNav()"
  [ngClass]="{ slideIn: navState }"
>
  <div class="app__toggle__handle">
    <span inlineSVG="/assets/img/icons/dots.svg"></span>
    <span inlineSVG="/assets/img/icons/arrow-left.svg"></span>
  </div>
</div>

<div class="app__menu__overlay" [ngClass]="{ slideIn: navState }"></div>
<div class="app__menu" [ngClass]="{ slideIn: navState }">
  <div class="app__logo">
    <a routerLink="/">
      <img [src]="logo" />
    </a>
  </div>
  <ng-container *appVersion="2">
    <div
      class="app__menu__project-switch"
      *ngIf="isActive('projects') && currentProject"
      (click)="pickProject()"
    >
      <span inlineSVG="/assets/v2/img/icons/chevron-right-left.svg"></span>
      <span>
        {{ currentProject.name }}
      </span>
    </div>
  </ng-container>

  <div class="app__twofactor" *ngIf="showTwoFactorNotice">
    <a routerLink="/profile/personal">
      {{ 'header.menu.two_factor' | translate }}
    </a>
  </div>

  <ul class="app__navigation" *ngIf="authenticated">
    <!-- MAIN MENU -->
    <ng-container *ngIf="!isActive('projects') || !currentProject">
      <li>
        <a routerLink="/" [ngClass]="{ active: isActive('home') }">
          <span inlineSVG="/assets/img/icons/home.svg"></span>
          <span>{{ 'header.menu.projects' | translate }}</span>
        </a>
      </li>
      <li>
        <a routerLink="/updates" [ngClass]="{ active: isActive('updates') }">
          <span
            *appVersion="1"
            inlineSVG="/assets/img/icons/updates.svg"
          ></span>
          <span
            *appVersion="2"
            inlineSVG="/assets/img/icons/notifications.svg"
          ></span>
          <span>{{ 'projects.detail.menu.updates' | translate }}</span>
        </a>
      </li>
      <li>
        <a
          routerLink="/tickets"
          [queryParams]="{ tab: 'open' }"
          [ngClass]="{
            hasTicket: openTickets > 0,
            active: isActive('tickets') && version === 2
          }"
        >
          <span inlineSVG="/assets/img/icons/tickets.svg"></span>
          <span>{{ 'header.menu.conversations' | translate }}</span>
          <span class="count">
            {{ openTickets }}
          </span>
        </a>
        <ul
          class="sub-menu"
          *appVersion="1"
          [ngClass]="{ open: isActive('tickets') }"
        >
          <li>
            <a
              [ngClass]="{ active: isActive('open', 'tickets') }"
              routerLink="/tickets"
              [queryParams]="{ tab: 'open' }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'tickets.all.status.open' | translate }}</span>
            </a>
          </li>
          <li>
            <a
              [ngClass]="{ active: isActive('pending', 'tickets') }"
              routerLink="/tickets"
              [queryParams]="{ tab: 'pending' }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'tickets.all.status.pending' | translate }}</span>
            </a>
          </li>
          <li>
            <a
              [ngClass]="{ active: isActive('closed', 'tickets') }"
              routerLink="/tickets"
              [queryParams]="{ tab: 'closed' }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'tickets.all.status.closed' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="hasSmartReportingModule">
        <a
          routerLink="/smart-reporting/notices"
          [ngClass]="{
            hasTicket: openTickets > 0,
            active: isActive('notices', 'smart-reporting')
          }"
        >
          <span inlineSVG="/assets/img/icons/smart-reporting.svg"></span>
          <span>{{ 'header.menu.smart_reporting' | translate }}</span>
          <span class="count" [hidden]="openNotices == 0">
            {{ openNotices }}
          </span>
        </a>
        <ul
          class="sub-menu"
          [ngClass]="{
            open: isActive('smart-reporting')
          }"
        >
          <li *ngIf="hasThemeOption">
            <a
              *appVersion="1"
              (click)="
                pickCustomer('/smart-reporting/themes', Module.SMART_REPORTING)
              "
              [ngClass]="{ active: isActive('themes', 'smart-reporting') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'smart_reporting.menu.themes' | translate }}</span>
            </a>
            <a
              *appVersion="2"
              routerLink="/smart-reporting/themes"
              [ngClass]="{ active: isActive('themes', 'smart-reporting') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'smart_reporting.menu.themes' | translate }}</span>
            </a>
          </li>
          <li *ngIf="hasSettingsOption">
            <a
              routerLink="/smart-reporting/settings/"
              [ngClass]="{ active: isActive('settings', 'smart-reporting') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'smart_reporting.menu.settings' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="isBA()">
        <a
          routerLink="/resident-letters"
          [ngClass]="{ active: isActive('resident-letters') }"
        >
          <span inlineSVG="/assets/v2/img/icons/news.svg"></span>
          <span>{{ 'header.menu.resident-letters' | translate }}</span>
          <span class="new">
            {{ 'header.menu.new' | translate }}
          </span>
        </a>
      </li>
      <ng-container *appVersion="1">
        <li>
          <a
            routerLink="/knowledge"
            [ngClass]="{ active: isActive('knowledge') }"
          >
            <span inlineSVG="/assets/img/icons/knowledge.svg"></span>
            <span>{{ 'header.menu.blog.knowledge' | translate }}</span>
          </a>
        </li>
      </ng-container>
      <ng-container *appVersion="2">
        <li>
          <a
            routerLink="/knowledge"
            [ngClass]="{ active: isActive('knowledge') }"
          >
            <span inlineSVG="/assets/img/icons/knowledge.svg"></span>
            <span>{{ 'header.menu.blog.knowledge' | translate }}</span>
          </a>
        </li>
      </ng-container>
      <li>
        <a
          *ngIf="version === 1 && isAccountAdmin"
          (click)="pickCustomer('profile/company')"
        >
          <span inlineSVG="/assets/img/icons/settings.svg"></span>
          <span>{{ 'header.menu.settings' | translate }}</span>
        </a>
        <a
          *ngIf="version === 1 && !isAccountAdmin"
          routerLink="/profile/export"
        >
          <span inlineSVG="/assets/img/icons/settings.svg"></span>
          <span>{{ 'header.menu.settings' | translate }}</span>
        </a>
        <a
          *ngIf="version === 2 && isAccountAdmin"
          routerLink="/organization/profile"
        >
          <span inlineSVG="/assets/v2/img/icons/organisation.svg"></span>
          <span>{{ 'header.menu.organisation' | translate }}</span>
        </a>
        <ul
          class="sub-menu"
          [ngClass]="{
            open: isActive(version === 1 ? 'profile' : 'organization')
          }"
        >
          <li appAccess role="accountAdmin">
            <a
              *appVersion="1"
              (click)="pickCustomer('profile/company')"
              [ngClass]="{ active: isActive('company', 'profile') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'header.menu.company' | translate }}</span>
            </a>
            <a
              *appVersion="2"
              routerLink="/organization/profile"
              [ngClass]="{ active: isActive('profile', 'organization') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'header.menu.company.profile' | translate }}</span>
            </a>
          </li>
          <li appAccess role="accountAdmin">
            <a
              *appVersion="1"
              (click)="pickCustomer('profile/company/admin')"
              [ngClass]="{ active: isActive('admin', 'profile') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'header.menu.admin' | translate }}</span>
            </a>
            <a
              *appVersion="2"
              routerLink="/organization/admin"
              [ngClass]="{ active: isActive('admin', 'organization') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'header.menu.organisation.users' | translate }}</span>
            </a>
          </li>
          <li appAccess role="manager" [project]="null" *appVersion="1">
            <a
              routerLink="/profile/export"
              [ngClass]="{ active: isActive('export', 'profile') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'header.menu.export' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ng-container>

    <!-- PROJECT MENU -->
    <ng-container *ngIf="isActive('projects') && currentProject">
      <li>
        <a routerLink="/">
          <span inlineSVG="/assets/img/icons/back.svg"></span>
          <span>{{ 'projects.detail.menu.back' | translate }}</span>
        </a>
      </li>
      <ng-container>
        <li *appVersion="2">
          <a
            [routerLink]="['projects', currentProject.slug]"
            [ngClass]="{ active: isActiveOnProjects('dashboard') }"
          >
            <span inlineSVG="/assets/v2/img/icons/grid.svg"></span>
            <span>{{ 'projects.detail.menu.dashboard' | translate }}</span>
          </a>
        </li>
      </ng-container>
      <li *appVersion="1">
        <a
          [routerLink]="['projects', currentProject.slug, 'general']"
          [ngClass]="{ active: isActiveOnProjects('general') }"
        >
          <span inlineSVG="/assets/img/icons/general.svg"></span>
          <span>{{ 'projects.detail.menu.general' | translate }}</span>
        </a>
      </li>
      <li *appVersion="1">
        <a
          [routerLink]="['projects', currentProject.slug, 'targets']"
          [ngClass]="{ active: isActiveOnProjects('targets') }"
        >
          <span inlineSVG="/assets/img/icons/targets.svg"></span>
          <span>{{ 'projects.detail.menu.targets' | translate }}</span>
        </a>
      </li>
      <li>
        <a
          [routerLink]="
            currentProject.slug !== ''
              ? ['projects', currentProject.slug, 'updates']
              : null
          "
          [ngClass]="{
            active: isActiveOnProjects('updates'),
            disabled: currentProject.slug === ''
          }"
        >
          <span
            *appVersion="1"
            inlineSVG="/assets/img/icons/updates.svg"
          ></span>
          <span
            *appVersion="2"
            inlineSVG="/assets/img/icons/notifications.svg"
          ></span>
          <span>{{ 'projects.detail.menu.updates' | translate }}</span>
        </a>
      </li>
      <li>
        <a
          [routerLink]="
            currentProject.slug !== ''
              ? ['projects', currentProject.slug, 'phases']
              : null
          "
          [ngClass]="{
            active:
              isActiveOnProjects('phases') || isActiveOnProjects('events'),
            disabled: currentProject.slug === ''
          }"
        >
          <span inlineSVG="/assets/img/icons/planning.svg"></span>
          <span>{{ 'projects.detail.menu.phases' | translate }}</span>
        </a>
      </li>
      <ng-container *appVersion="1">
        <li *ngIf="currentProject.enabledModules?.includes(Module.SURVEY)">
          <a
            [routerLink]="['projects', currentProject.slug, 'feedback']"
            [ngClass]="{ active: isActiveOnProjects('feedback') }"
          >
            <span inlineSVG="/assets/img/icons/survey.svg"></span>
            <span>{{ 'projects.detail.menu.feedback' | translate }}</span>
          </a>
        </li>
      </ng-container>
      <li *appVersion="1">
        <a
          [routerLink]="[
            'projects',
            currentProject.slug,
            'satisfaction-monitor'
          ]"
          [ngClass]="{ active: isActiveOnProjects('satisfaction-monitor') }"
        >
          <span inlineSVG="/assets/img/icons/rating.svg"></span>
          <span>{{
            'projects.detail.menu.satisfaction_monitor' | translate
          }}</span>
        </a>
      </li>
      <li *appVersion="2">
        <a
          [routerLink]="
            currentProject.slug !== ''
              ? ['projects', currentProject.slug, 'satisfaction-monitor']
              : null
          "
        >
          <span inlineSVG="/assets/v2/img/icons/notes.svg"></span>
          <span>{{ 'projects.detail.menu.feedback_item' | translate }}</span>
        </a>
        <ul
          class="sub-menu"
          [ngClass]="{
            open:
              isActiveOnProjects('feedback') ||
              isActiveOnProjects('satisfaction-monitor')
          }"
        >
          <li>
            <a
              [routerLink]="[
                'projects',
                currentProject.slug,
                'satisfaction-monitor'
              ]"
              [ngClass]="{ active: isActiveOnProjects('satisfaction-monitor') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{
                'projects.detail.menu.satisfaction_monitor' | translate
              }}</span>
            </a>
          </li>
          <li *ngIf="currentProject.enabledModules?.includes(Module.SURVEY)">
            <a
              [routerLink]="['projects', currentProject.slug, 'feedback']"
              [ngClass]="{ active: isActiveOnProjects('feedback') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'projects.detail.menu.feedback' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a
          [routerLink]="
            currentProject.slug !== ''
              ? ['projects', currentProject.slug, 'tickets']
              : null
          "
          [queryParams]="{ tab: 'overview' }"
          [ngClass]="{
            active: isActiveOnProjects('tickets-overview') && version === 2,
            disabled: currentProject.slug === ''
          }"
        >
          <span inlineSVG="/assets/img/icons/tickets.svg"></span>
          <span>{{ 'projects.detail.menu.tickets' | translate }}</span>
          <span class="count" [hidden]="currentProject.openTicketCount == 0">
            {{
              currentProject.openTicketCount > 99
                ? 99
                : currentProject.openTicketCount
            }}
          </span>
        </a>
        <ul
          *appVersion="1"
          class="sub-menu"
          [ngClass]="{
            open:
              isActiveOnProjects('tickets-overview') ||
              isActiveOnProjects('tickets-settings')
          }"
        >
          <li>
            <a
              [routerLink]="['projects', currentProject.slug, 'tickets']"
              [queryParams]="{ tab: 'overview' }"
              [ngClass]="{ active: isActiveOnProjects('tickets-overview') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{
                'project.detail.tickets.tab.overview' | translate
              }}</span>
            </a>
          </li>
          <li>
            <a
              [routerLink]="['projects', currentProject.slug, 'tickets']"
              [queryParams]="{ tab: 'settings' }"
              [ngClass]="{ active: isActiveOnProjects('tickets-settings') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{
                'project.detail.tickets.tab.settings' | translate
              }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li
        *ngIf="
          version === 2 &&
          currentProject.enabledModules?.includes(Module.SMART_REPORTING)
        "
      >
        <a
          [routerLink]="['projects', currentProject.slug, 'smart-reporting']"
          [ngClass]="{
            hasTicket: currentProject.openNoticeCount > 0,
            active: isActiveOnProjects('smart-reporting')
          }"
        >
          <span inlineSVG="/assets/img/icons/smart-reporting.svg"></span>
          <span>{{ 'header.menu.smart_reporting' | translate }}</span>
          <span class="count" [hidden]="currentProject.openNoticeCount == 0">
            {{
              currentProject.openNoticeCount > 99
                ? 99
                : currentProject.openNoticeCount
            }}
          </span>
        </a>
      </li>
      <li>
        <a
          [routerLink]="
            currentProject.slug !== ''
              ? ['projects', currentProject.slug, 'statistics']
              : null
          "
          [ngClass]="{
            active: isActiveOnProjects('statistics'),
            disabled: currentProject.slug === ''
          }"
        >
          <span inlineSVG="/assets/img/icons/statistics.svg"></span>
          <span *appVersion="1">{{
            'projects.detail.menu.statistics' | translate
          }}</span>
          <span *appVersion="2">{{
            'projects.detail.menu.analytics' | translate
          }}</span>
        </a>
      </li>
      <li *appVersion="1">
        <a
          [routerLink]="['projects', currentProject.slug, 'livestreams']"
          [ngClass]="{ active: isActiveOnProjects('livestreams') }"
        >
          <span inlineSVG="/assets/img/icons/livestreams.svg"></span>
          <span>{{ 'projects.detail.menu.livestreams' | translate }}</span>
        </a>
      </li>
      <li *appVersion="1">
        <a
          [routerLink]="['projects', currentProject.slug, 'widget']"
          [ngClass]="{ active: isActiveOnProjects('widget') }"
        >
          <span inlineSVG="/assets/img/icons/widget.svg"></span>
          <span>{{ 'projects.detail.menu.widget' | translate }}</span>
        </a>
      </li>
      <li
        appAccess
        role="projectAdmin"
        [project]="currentProject"
        [customer]="currentProject.customer"
        class="tab-settings"
        *ngIf="currentProject.slug !== ''"
      >
        <a
          [routerLink]="['projects', currentProject.slug, 'users']"
          [ngClass]="{ active: isActiveOnProjects('users') }"
        >
          <span
            *appVersion="1"
            inlineSVG="/assets/img/icons/settings.svg"
          ></span>
          <span
            *appVersion="2"
            inlineSVG="/assets/v2/img/icons/organisation.svg"
          ></span>
          <span>{{ 'projects.detail.menu.settings' | translate }}</span>
        </a>
      </li>
      <li *appVersion="2">
        <a
          [routerLink]="
            currentProject.slug !== ''
              ? ['projects', currentProject.slug, 'general']
              : null
          "
        >
          <span inlineSVG="/assets/img/icons/settings.svg"></span>
          <span>{{ 'projects.detail.menu.project_settings' | translate }}</span>
        </a>
        <ul
          class="sub-menu"
          [ngClass]="{
            open:
              isActiveOnProjects('general') ||
              isActiveOnProjects('livestreams') ||
              isActiveOnProjects('widget') ||
              isActiveOnProjects('targets') ||
              isActiveOnProjects('contact')
          }"
        >
          <li>
            <a
              [routerLink]="['projects', currentProject.slug, 'general']"
              [ngClass]="{ active: isActiveOnProjects('general') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'projects.detail.menu.general' | translate }}</span>
            </a>
          </li>
          <li>
            <a
              [routerLink]="['projects', currentProject.slug, 'targets']"
              [ngClass]="{ active: isActiveOnProjects('targets') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{
                'projects.detail.menu.target_groups' | translate
              }}</span>
            </a>
          </li>
          <li>
            <a
              [routerLink]="['projects', currentProject.slug, 'contact']"
              [ngClass]="{ active: isActiveOnProjects('contact') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'projects.detail.menu.contact' | translate }}</span>
            </a>
          </li>
          <li>
            <a
              [routerLink]="['projects', currentProject.slug, 'livestreams']"
              [ngClass]="{ active: isActiveOnProjects('livestreams') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'projects.detail.menu.livestreams' | translate }}</span>
            </a>
          </li>
          <li>
            <a
              [routerLink]="['projects', currentProject.slug, 'widget']"
              [ngClass]="{ active: isActiveOnProjects('widget') }"
            >
              <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
              <span>{{ 'projects.detail.menu.widget' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>

  <div class="app__user" *ngIf="!!user" (mouseleave)="hideUserMenu()">
    <div class="app__user__self">
      <div class="app__user__image" (mouseover)="showUserMenu()">
        <ng-container *ngIf="avatar; else noAvatar">
          <img [src]="avatar" width="40px" height="40px" />
        </ng-container>
        <ng-template #noAvatar>
          {{ user.projectUser.firstName[0] }}
        </ng-template>
      </div>
      <div class="app__user__text" (mouseover)="showUserMenu()">
        <span *appVersion="1">
          <span>{{ user.projectUser.firstName }} </span>
        </span>
        <span *appVersion="2">
          <span
            >{{ user.projectUser.firstName }}
            {{ user.projectUser.lastName }}
          </span>
          <span class="app__user__text__small">{{ user.email }}</span>
        </span>
      </div>
      <div
        class="app__user__toggle"
        #productFruitLauncher
        (mouseout)="hideUserMenu()"
      >
        <span inlineSVG="/assets/img/icons/bell.svg"></span>
        <span class="count" *ngIf="newsCount > 0">
          {{ newsCount > 99 ? 99 : newsCount }}</span
        >
      </div>
    </div>

    <div class="app__user__menu" [ngClass]="{ active: userMenuVisible }">
      <div *appVersion="1">
        <span
          >{{ user.projectUser.firstName }}
          {{ user.projectUser.lastName }}</span
        >
        <span>{{ user.email }}</span>
        <span>{{ getCustomers() }}</span>
      </div>
      <div *appVersion="2">
        <span>{{ 'header.menu.organisations' | translate }}</span>
        <span (click)="viewAllOrganizations()">
          <ng-container *ngFor="let item of getCalculatedCustomers()">
            {{ item.name }}
          </ng-container>
          <ng-container *ngIf="!viewAllOrganisations && customers.length > 4">
            +{{ getRemainingItemCount() }}
          </ng-container>
        </span>
      </div>
      <ul>
        <li>
          <a routerLink="/profile/personal">
            <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
            <span>{{ 'header.menu.profile' | translate }}</span>
          </a>
        </li>
        <li>
          <a routerLink="/profile/password">
            <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
            <span>{{ 'header.menu.change_password' | translate }}</span>
          </a>
        </li>
        <li>
          <a routerLink="/two-factor" [queryParams]="{ method: true }">
            <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
            <span>{{ 'header.menu.two_factor' | translate }}</span>
          </a>
        </li>
        <li>
          <a (click)="logout()">
            <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
            <span>{{ 'header.menu.logout' | translate }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<app-customer-picker *appVersion="1" #customerPicker></app-customer-picker>
<app-project-picker
  [project]="currentProject"
  #projectPicker
></app-project-picker>
