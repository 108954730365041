import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CsvService {
  public static SEPARATOR = ';';

  public constructor(protected translateService: TranslateService) {}

  /**
   * Parses new CustomData into CSV format with nested structure handling
   * @param data CustomData structure (nested JSON)
   * @param title Optional title for the section
   * @param withSeparator Whether to include separator row
   * @param parseDots Whether to parse dots into commas
   */
  private parseCustomData(data: {
    [date: string]: {
      y: string;
      responseCount: number;
      responses: {
        score: number;
        content: string;
        theme: string;
      }[];
      x: string;
    };
  }) {
    let empty = true;

    let result = this.addLine(
      '',
      this.translateService.instant('smart_reporting.notices.table.date') +
        CsvService.SEPARATOR +
        this.translateService.instant('ticket_list.table.rating') +
        CsvService.SEPARATOR +
        this.translateService.instant(
          'project.detail.feedback.editor.stats.title'
        ) +
        CsvService.SEPARATOR +
        this.translateService.instant('ticket_list.table.subject')
    );

    for (const [date, entry] of Object.entries(data)) {
      entry.responses.forEach((response) => {
        const row = [date, response.score, response.content, response.theme];
        if (row) {
          empty = false;
        }
        result = this.addLine(
          result,
          row
            .map((value) => {
              let val = value + '';
              val = val.replace(/\./g, ',').replace(/(\r\n|\n|\r)/gm, '');

              if (val.includes(CsvService.SEPARATOR)) {
                return `"${val.replace(/"/g, '""')}"`;
              }

              return val;
            })
            .join(CsvService.SEPARATOR)
        );
      });
    }

    return empty ? '' : result;
  }

  public create(
    data:
      | any[]
      | {
          [date: string]: {
            y: string;
            responseCount: number;
            responses: {
              score: number;
              content: string;
              theme: string;
            }[];
            x: string;
          };
        },
    title?: string,
    parseDots: boolean = true
  ) {
    let result = '';

    // Handle the existing flat data (array)
    if (Array.isArray(data)) {
      result = this.parseData(data, title, true, parseDots);
    }
    // Handle the new CustomData structure (object)
    else {
      if (result) result += '\n\n';
      result = this.parseCustomData(data);
    }

    return {
      _service: this,
      data: result,
      download: function (fileName: string) {
        const mimeType = 'text/csv';

        const blob = new Blob([this.data], {
          type: mimeType + ';charset=utf8;',
        });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('visibility', 'hidden');
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      merge: function (data, title?: string) {
        let result = this._service.addLine(this.data, ' '); // Add a separator
        if (Array.isArray(data)) {
          result = result.concat(this._service.parseData(data, title, false));
        } else {
          if (result) result += '\n\n';
          result = result.concat(
            this._service.parseCustomData(data, title, false)
          );
        }

        this.data = result;
      },
    };
  }

  /**
   * Parses flat data (existing) into CSV format
   * @param data Array of objects (flat structure)
   * @param title Optional title for the data
   * @param withSeparator Whether to include separator row
   * @param parseDots Whether to parse dots into commas
   */
  private parseData(
    data: any[],
    title?: string,
    withSeparator: boolean = true,
    parseDots: boolean = true
  ) {
    let result = this.addLine(
      '',
      withSeparator ? 'sep=' + CsvService.SEPARATOR : ''
    );

    if (title != null) {
      result = this.addLine(result, title);
    }

    if (data.length > 0) {
      result = this.addLine(
        result,
        Object.keys(data[0]).join(CsvService.SEPARATOR)
      );
      for (const dataItem of data) {
        result = this.addLine(
          result,
          Object.keys(dataItem)
            .map((key) => {
              let value = dataItem[key] + '';
              if (parseDots === true) {
                value = value.replace(/\./g, ',').replace(/(\r\n|\n|\r)/gm, '');
              }

              if (value.includes(CsvService.SEPARATOR)) {
                return `"${value.replace(/"/g, '""')}"`;
              }

              return value;
            })
            .join(CsvService.SEPARATOR)
        );
      }
    }

    return result;
  }

  /**
   * @param result
   * @param data
   * @returns string
   */
  private addLine(result, data: string) {
    return result + data + '\n';
  }
}
