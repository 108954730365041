import {
  Component,
  HostBinding,
  Input,
  OnInit,
  forwardRef,
  OnChanges,
  SimpleChanges,
  HostListener,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { environment } from '../../environments/environment';
import { AttachmentHelper } from '../shared/attachment-helper';
import { Thumbnail } from '../interfaces/thumbnail';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-input-file-preview',
    templateUrl: 'input-file-preview.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputFilePreviewComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputFilePreviewComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [NgIf, InlineSVGModule, TranslateModule]
})
export class InputFilePreviewComponent implements ControlValueAccessor {
  public path: string = null;
  protected control: FormControl = null;

  protected concept = true;
  public showImageThumbnail = false; // we can't show thumbnails for e.g. PDFs
  public showFileNameThumbnail = false; // we can't show thumbnails for e.g. PDFs
  protected thumbnail: string; // we can't show thumbnails for e.g. PDFs

  @Input() private thumbnails: Thumbnail;
  @Input() public videoPreview: string;
  @Input() private type: string;
  @Input() public deleteText: string;
  @Input() public videoId: string;
  @Output() public cleared: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Way to test whether a string is a data url
   * @param {string} string
   * @returns {boolean}
   */
  static isDataUrl(string: string) {
    const url =
      /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
    return url.test(string);
  }

  /**
   * Way to test whether a string is a PDF file
   * @param {string} string
   * @returns {boolean}
   */
  static isPdf(string: string) {
    return /\.pdf$/.test(string);
  }

  propagateChange = (_: any) => {};

  /**
   * @param obj
   */
  writeValue(obj: string): void {
    // write existing value
    this.concept = false; // from now on, we can only show data URL's
    this.path = obj;
    this.showFileNameThumbnail = false; //reset it first
    if (InputFilePreviewComponent.isPdf(obj)) {
      this.showFileNameThumbnail = true;
      this.thumbnail = obj;
    }
  }

  /**
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn; // needed to delete a file
  }

  /**
   * @param fn
   */
  registerOnTouched(fn: any): void {}

  /**
   * @returns {Promise<void>}
   */
  async clear(): Promise<void> {
    if (this.control === null) {
      return;
    }

    this.control.setValue(null);
    this.path = null;
    this.thumbnail = null;
    this.showFileNameThumbnail = false;

    this.cleared.emit();
    this.propagateChange(null);
  }

  isYoutubeVideo() {
    return this.path == null && this.videoId != null;
  }

  isVideo() {
    return (
      /\.mp4$/i.test(this.path) ||
      /\blob:$/i.test(this.path) ||
      /\.mov$/i.test(this.path)
    );
  }

  /**
   * @returns {string}
   */
  getUrl(): string {
    if (this.isYoutubeVideo()) {
      return AttachmentHelper.getVideoImageForId(this.videoId);
    }
    if (this.isVideo() && this.videoPreview) {
      return this.videoPreview;
    }
    return this.isEmpty() || this.showFileNameThumbnail
      ? ''
      : this.showImageThumbnail
      ? this.thumbnail
      : this.thumbnails.medium;
  }

  /**
   * @returns {string}
   */
  isEmpty(): boolean {
    return this.path === null && this.videoId == null;
  }

  update(previewData: string) {
    this.showImageThumbnail = true;
    this.showFileNameThumbnail = false;
    this.thumbnail = previewData;
  }

  setVideoPreview(video) {
    this.videoPreview = video;
  }

  /**
   * Way to obtain the form control, as it will be called on upload
   * @param control
   * @returns {null}
   */
  validate(control: FormControl) {
    if (this.control === null) {
      this.control = control;
    }

    if (this.control.value !== this.path) {
      this.concept = true;

      const isPdf: boolean = InputFilePreviewComponent.isPdf(
        this.control.value
      );

      if (isPdf) {
        // first check if it's a pdf, so we have to show a file name thumbnail
        this.showFileNameThumbnail = true;
        this.showImageThumbnail = false;
        this.thumbnail = this.control.value;
      } else {
        const isDataUrl: boolean = InputFilePreviewComponent.isDataUrl(
          this.control.value
        ); // check if it's an image

        if (isDataUrl) {
          this.showImageThumbnail = true;
          this.showFileNameThumbnail = false;
          this.thumbnail = this.control.value;
        }
      }
    }

    this.path = this.control.value;

    return null;
  }
}
