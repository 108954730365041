import { AbstractControl, ValidatorFn } from '@angular/forms';

export function dateRangeValidator(): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: any } | null => {
    const startDate = formGroup.get('startDate');
    const endDate = formGroup.get('endDate');

    startDate.setErrors(null);
    endDate.setErrors(null);

    if (startDate.value && endDate.value && startDate.value > endDate.value) {
      const error = { invalidDateEnd: true };
      startDate.setErrors(error);
      endDate.setErrors(error);
      return error;
    }

    return null;
  };
}
