<footer class="footer">
  <div class="container flex flex-row" *appVersion="1">
    <div class="footer__left">
      <p>
        <span>{{ 'footer.company.name' | translate }}</span
        ><br />
        <span>{{ 'footer.company.street' | translate }}</span
        ><br />
        <span>
          {{ 'footer.company.zip_code' | translate }}
          {{ 'footer.company.city' | translate }} </span
        ><br />
        <span
          ><a href="{{ 'footer.privacy_statement.url' | translate }}">{{
            'footer.privacy_statement.desc' | translate
          }}</a></span
        >
      </p>
    </div>
    <div class="footer__right">
      <p>
        <span>{{ 'footer.company.chamber_of_commerce' | translate }}</span
        ><br />
        <span>{{ 'footer.company.vat_number' | translate }}</span
        ><br />
        <span>{{ 'footer.company.bank_account' | translate }}</span>
      </p>
    </div>
  </div>
  <div *appVersion="2">
    <p>
      <span>{{ 'footer.company.name' | translate }}</span> •
      <span>{{ 'footer.company.street' | translate }}</span> •
      <span>{{ 'footer.company.zip_code' | translate }}</span> •
      <span>{{ 'footer.company.city' | translate }}</span>
    </p>
    <p>
      <span>{{ 'footer.company.chamber_of_commerce' | translate }}</span> •
      <span>{{ 'footer.company.vat_number' | translate }}</span> •
      <span>{{ 'footer.company.bank_account' | translate }}</span> •
      <span>
        <a
          target="_blank"
          href="{{ 'footer.privacy_statement.url' | translate }}"
          >{{ 'footer.privacy_statement.desc' | translate }}</a
        ></span
      >
    </p>
  </div>
</footer>
