import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ProjectDataBusService } from '../../../services/project-data-bus.service';
import { Project } from '../../../interfaces/project';
import { RatingThemeService } from '../../../services/rating-theme.service';
import * as moment from 'moment';
import { StatisticsService } from '../../../services/statistics.service';
import { Subscription } from 'rxjs';
import { Target } from '../../../interfaces/target';
import { TranslateModule } from '@ngx-translate/core';
import { DataFilterComponent } from '../../../components/data-filter.component';
import { ChartComponent } from '../../../components/chart.component';
import { NgIf, NgFor } from '@angular/common';
import { LoaderComponent } from '../../../components/loader.component';
import { TargetPickerComponent } from '../../../components/target-picker.component';
import { PeriodComponent } from '../../../components/period.component';

@Component({
  selector: 'app-default-projects-detail-statistics',
  templateUrl: 'detail-statistics.component.html',
  standalone: true,
  imports: [
    PeriodComponent,
    ReactiveFormsModule,
    TargetPickerComponent,
    LoaderComponent,
    NgIf,
    NgFor,
    ChartComponent,
    DataFilterComponent,
    TranslateModule,
  ],
})
export class DetailStatisticsComponent implements OnInit {
  project: Project;
  results;
  conversationResults;
  periodControl: FormControl;
  error = false;
  loading = false;
  loadingResults = false;

  public demography;
  public currentFilter;
  public targetControl: FormControl;
  public targetSubscription: Subscription;
  public currentTarget: Target | null;

  constructor(
    protected projectDataBusService: ProjectDataBusService,
    protected formBuilder: FormBuilder,
    protected ratingThemeService: RatingThemeService,
    protected statisticsService: StatisticsService
  ) {
    this.projectDataBusService.projectObservable.subscribe((project) => {
      this.project = project;
    });

    this.createControls();
  }

  determineChartTooltipTitle(data, full) {
    return (
      (data.realValue != null
        ? data.realValue
        : data.y == null
        ? data
        : data.y) +
      ' ' +
      full.tooltip
    );
  }

  async ngOnInit(): Promise<void> {
    this.loadDemography();
  }

  public handleFilter(filter) {
    this.currentFilter = { ...this.currentFilter, ...filter };
    this.loadConversationResults();
  }

  public async loadDemography() {
    const period = this.periodControl.value;
    this.demography = await this.statisticsService.demography(
      this.project,
      period
    );
  }

  public handleChoiceChange(choices) {
    this.currentFilter = { ...this.currentFilter, ...choices };

    this.loadConversationResults();
  }

  private createControls() {
    this.periodControl = this.formBuilder.control({
      type: 'week',
      start: moment().subtract(7, 'days').format(),
      end: moment().format(),
    });
    this.targetControl = this.formBuilder.control(null);

    this.periodControl.valueChanges.subscribe((value) => {
      if (this.periodControl.valid) {
        this.loadDemography();
        this.loadConversationResults();
        this.loadResults();
      }
    });

    this.targetSubscription = this.targetControl.valueChanges.subscribe(
      (value) => {
        this.currentTarget = value;
        this.loadResults();
      }
    );
  }

  private async loadResults() {
    const period = this.periodControl.value;

    this.loadingResults = true;

    this.results = await this.statisticsService.results(
      this.project,
      period,
      this.currentTarget
    );

    this.loadingResults = false;
  }

  private async loadConversationResults() {
    const period = this.periodControl.value;

    this.conversationResults = await this.statisticsService.conversationResults(
      this.project,
      period,
      this.currentFilter
    );
  }
}
