<app-modal #modal>
  <div class="qr-container">
    <qrcode
      (qrCodeURL)="onChangeURL($event)"
      [qrdata]="link()"
      [allowEmptyString]="true"
      [elementType]="qrCodeType"
      [errorCorrectionLevel]="'M'"
      [scale]="1"
      [width]="300"
    ></qrcode>

    <div class="qr-container__text">
      <div class="qr-description">
        {{ title() | translate }}
      </div>
      <div class="qr-buttons">
        <button class="button--black-white" (click)="download('png')">
          <p>{{ 'profile.export.download' | translate }} .PNG</p>
        </button>
        <button class="button--black-white" (click)="download('svg')">
          <p>{{ 'profile.export.download' | translate }} .SVG</p>
        </button>
        <a
          [href]="getDownloadLink()"
          style="display: none"
          #customLink
          download="qrcode"
          >Download</a
        >
      </div>
    </div>
  </div>
</app-modal>
