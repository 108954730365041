import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateType',
  standalone: true,
})
export class DateTypePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  /**
   * @param date
   * @param parameters, first is type: can be Q1, Q2, Q3, Q4, MONTH, YEAR or EXACT_DATE
   */
  transform(date: string | Date, ...parameters: any[]): any {
    const type = parameters[0];
    const short: boolean = parameters[1] && parameters[1] === 'short';

    switch (type) {
      case 'Q1':
      case 'Q2':
      case 'Q3':
      case 'Q4':
        return type + ' ' + moment(date).format('YYYY');
      case 'MONTH':
        return short
          ? moment(date).format('MMM')
          : moment(date).format('MMMM YYYY');
      case 'YEAR':
        return moment(date).format('YYYY');
      case 'EXACT_DATE':
        return short ? moment(date).format('D MMM') : moment(date).format('ll');
      case 'EVENT':
        if (moment(date).isSame(moment(), 'day'))
          return `${this.translateService.instant(
            'project.detail.events.today'
          )} ${moment(date).format('LT')}`;
        else if (moment(date).isSame(moment().add(1, 'days'), 'day'))
          return `${this.translateService.instant(
            'project.detail.events.tomorrow'
          )} ${moment(date).format('LT')}`;
        else {
          const lang = this.translateService.getBrowserLang();
          let format = lang == 'en' ? 'ddd, MMM D' : 'ddd D MMM';
          if (!moment(date).isSame(moment(), 'year')) format += ', yyyy';
          format += ' LT';
          return moment(date).format(format);
        }
    }
  }
}
