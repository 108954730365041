<div class="poll">
  <div class="poll__title">
    <span class="poll__title__text">
      <span *appVersion="2" inlineSVG="/assets/img/icons/poll.svg"></span>
      <span>{{ 'poll.title' | translate }}</span>
      <ng-container *appVersion="2">
        <span> : {{ poll.title }} </span>
        <span>
          ({{
            (totalVotes === 1 ? 'poll.votes.singular' : 'poll.votes.plural')
              | translate : { votes: totalVotes }
          }})
        </span>
      </ng-container>
    </span>
  </div>

  <div *appVersion="1" class="poll__question">{{ poll.title }}</div>

  <div class="poll__choices" *appVersion="1">
    <ng-container *ngFor="let choice of poll.choices; let index = index">
      <div
        class="poll__choice__result poll__choice__result--text"
        *ngIf="choice.filePath == null"
        [class.poll__choice__result--active]="choice.active"
      >
        <div
          class="poll__choice__result__bar"
          [style.min-width]="choice.percentageSize + '%'"
        ></div>
        <span class="poll__choice__result__percentage"
          >{{ choice.percentage }}%</span
        >
        <span class="poll__radio__label">
          {{ choice.text }}
        </span>
      </div>

      <div
        class="poll__choice poll__choice--image"
        *ngIf="choice.filePath != null"
        [class.poll__choice__result--active]="choice.active"
      >
        <div class="radio poll__radio">
          <label class="poll__radio__label">
            <div
              class="poll__choice__item poll__choice__image"
              [style.background-image]="choice.image"
            >
              <div class="poll_choice__image__label">{{ index + 1 }}</div>

              <div class="poll__choice__image__padding"></div>
              <div
                class="poll__choice__result__bar"
                [style.min-width]="choice.percentageSize + '%'"
              >
                {{ choice.percentage }}%
              </div>
            </div>
          </label>
        </div>
      </div>
    </ng-container>
  </div>

  <div *appVersion="1" class="poll__votes">
    {{
      (totalVotes === 1 ? 'poll.votes.singular' : 'poll.votes.plural')
        | translate : { votes: totalVotes }
    }}
  </div>

  <div class="poll__choices" *appVersion="2">
    <div
      class="poll__choice"
      *ngFor="let choice of poll.choices; let index = index"
    >
      <img
        [src]="choice.filePathThumbnails?.medium"
        *ngIf="choice.filePath != null"
      />
      <div *ngIf="!choice.filePath" class="placeholder"></div>
      <div class="poll__choice__result">
        <span class="poll__choice__result__percentage">
          {{ choice.percentage }}%
          {{ 'poll.votes.percentage' | translate }}
        </span>
        <span class="poll__choice__result__label">
          {{ choice.text }}
        </span>
      </div>
    </div>
  </div>
</div>
