import {
  Component,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { UploadService } from '../services/upload.service';
import { ErrorService } from '../services/error.service';
import { Upload } from '../interfaces/upload';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf } from '@angular/common';
import { Attachment } from '../interfaces/attachment';

@Component({
  selector: 'app-input-file-v2',
  templateUrl: 'input-file-v2.component.html',
  standalone: true,
  imports: [NgIf, InlineSVGModule, ProgressbarModule, TranslateModule],
})
export class InputFileV2Component {
  @Input() public type;
  @Input() uploadImages: boolean = false;
  @Input() uploadVideos: boolean = false;
  @Input() uploadPdfs: boolean = false;
  @Input() public geoJson = false;
  @Input() uploadJson: boolean = false;
  @Input() standalone: boolean = false;
  @Input() selected: boolean = false;
  @Output() uploadStart = new EventEmitter<Attachment>();
  @Output() uploadFinished = new EventEmitter<Attachment>();
  @Output() uploadFailed = new EventEmitter<Attachment>();
  @Output() fileRemoved = new EventEmitter<void>();

  @ViewChild('inputFile') inputFile: ElementRef;
  maxVideoSize: number = 275;
  maxPdfSize: number = 20;
  maxImageSize: number = 20;
  uploader: FileUploader = null;
  sasToken: string;

  private imageTypes = {
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    webp: 'image/webp',
  };

  private pdfTypes = {
    pdf: 'application/pdf',
  };

  private jsonTypes = {
    json: 'application/json',
    ext: '.geojson',
    geojson: 'application/geo+json',
    txt: 'text/plain',
  };

  private videoTypes = {
    mp4: 'video/mp4',
    mov: 'video/quicktime',
  };

  public errors: any = null;

  public uploading: boolean = false;
  public geojsonUploaded: boolean = false;

  constructor(
    private uploadService: UploadService,
    private errorService: ErrorService,
    private translateService: TranslateService
  ) {
    this.uploader = new FileUploader({
      url: '',
      method: 'PUT',
      disableMultipart: true,
      autoUpload: false,
    });

    this.uploader.onAfterAddingFile = (file: FileItem) => {
      file.withCredentials = false;
      const uniqueString = file._file.name + Date.now();
      (file as any).uniqueString = uniqueString;
      this.uploadStart.emit({
        fileName: file._file.name,
        filePath: file._file.name,
        preview: URL.createObjectURL(file._file),
        identifier: uniqueString,
      });
      this.uploading = true;

      this.uploadService
        .getSASToken(this.type, file.file.name)
        .then((res) => {
          this.uploader.onBeforeUploadItem = (item) =>
            (item.withCredentials = false);
          this.uploader.setOptions({
            url: res.sas_token,
            headers: [
              { name: 'x-ms-blob-type', value: 'BlockBlob' },
              { name: 'Content-Type', value: file.file.type },
              { name: 'x-ms-blob-content-type', value: file.file.type },
            ],
          });
          (file as any).responseUrl = res.file_name;
          this.uploader.uploadItem(file);
        })
        .catch((err) => {
          this.handleVideoError(uniqueString);
        });
    };

    this.uploader.onCompleteAll = () => {
      this.inputFile.nativeElement.value = '';
      this.uploader.clearQueue();
      this.uploading = false;
    };

    this.uploader.onSuccessItem = (item) => {
      this.addVideo(item);
    };

    this.uploader.onErrorItem = (item) => {
      this.handleVideoError((item as any).uniqueString);
    };
  }

  /**
   * Method to allow submit if file is wrong format (file will be empty)
   * @returns {void}
   */
  public ignoreInvalidFile(): void {
    if (this.errors != null && this.errors['uploadFileTypeInvalid'] != null) {
      delete this.errors['uploadFileTypeInvalid'];
    }
  }

  /**
   * Add a video as file
   */
  public addVideo(item: FileItem) {
    this.uploadFinished.emit({
      filePath: (item as any).responseUrl,
      fileName: item.file.name,
      identifier: (item as any).uniqueString,
    });
  }

  /**
   * @returns {string[]}
   */
  public getExtensionTypes(): string[] {
    let result: string[] = [];
    if (this.uploadImages) result = result.concat(Object.keys(this.imageTypes));
    if (this.uploadPdfs) result = result.concat(Object.keys(this.pdfTypes));
    if (this.uploadJson) result = result.concat(Object.keys(this.jsonTypes));
    if (this.uploadVideos) result = result.concat(Object.keys(this.videoTypes));

    return result;
  }

  /**
   * @returns {string[]}
   */
  public getMimeTypes(): string[] {
    let result: string[] = [];
    if (this.uploadImages)
      result = result.concat(this.getMimeTypeByType(this.imageTypes));
    if (this.uploadPdfs)
      result = result.concat(this.getMimeTypeByType(this.pdfTypes));
    if (this.uploadJson)
      result = result.concat(this.getMimeTypeByType(this.jsonTypes));
    if (this.uploadVideos)
      result = result.concat(this.getMimeTypeByType(this.videoTypes));

    return result;
  }

  /**
   * @param {object} types
   * @returns {string[]}
   */
  private getMimeTypeByType(types: object): string[] {
    return Object.keys(types).map((key) => {
      return types[key];
    });
  }

  public isVideo(file: File) {
    return (
      /\.mp4$/i.test(file.name) ||
      /\blob:$/i.test(file.name) ||
      /\.mov$/i.test(file.name)
    );
  }

  /**
   * @returns {Promise<void>}
   */
  public async upload(files: File[]): Promise<void> {
    this.uploading = true;
    this.errors = {};

    if (files instanceof FileList) {
      files = Array.from(files);
    }

    if (files.length) {
      files.forEach((file) => {
        const extension = this.getExtension(file);

        const uniqueString = file.name + Date.now();

        if (this.isVideo(file) && this.uploadVideos) {
          if (file.size > this.maxVideoSize * 1024 * 1024) {
            this.handleVideoTooLarge(uniqueString);
          } else {
            this.uploader.addToQueue([file]);
          }
          return;
        }

        if (
          !this.hasCorrectMimeType(file) &&
          extension !== 'json' &&
          extension !== 'geojson'
        ) {
          // json will be parsed afterwards
          this.markInvalidFile(uniqueString);
        } else if (
          (extension == 'json' || extension == 'geojson') &&
          !this.uploadJson
        ) {
          this.markInvalidFile(uniqueString);
        } else if (
          extension === 'pdf' &&
          file.size > this.maxPdfSize * 1024 * 1024
        ) {
          this.handlePdfTooLarge(uniqueString);
        } else if (
          Object.keys(this.imageTypes).includes(extension) &&
          file.size > this.maxImageSize * 1024 * 1024
        ) {
          this.handleImageTooLarge(uniqueString);
        } else {
          let preview;
          if (extension === 'json' || extension === 'geojson') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
              try {
                const data = atob(
                  (reader.result as string).split('base64,')[1]
                );
                this.uploadStart.emit({
                  fileName: file.name,
                  preview: data,
                  filePath: file.name,
                  identifier: uniqueString,
                });
              } catch (error) {
                this.errorService.logError(error);
                console.info('Invalid JSON-file supplied');

                this.markInvalidFile(uniqueString);
                return;
              }
            };
          } else {
            preview = URL.createObjectURL(file);
            this.uploadStart.emit({
              fileName: file.name,
              preview: preview,
              filePath: file.name,
              identifier: uniqueString,
            });
          }

          const upload = async () => {
            try {
              const uploaded: Upload = await this.uploadService.upload(
                this.type,
                file
              );

              URL.revokeObjectURL(preview);

              this.uploadFinished.emit({
                filePath: uploaded.file,
                fileName: file.name,
                identifier: uniqueString,
              });
            } catch (error) {
              this.handleErrors(error);
              this.uploadFailed.emit({
                error: this.errors.length
                  ? this.errors[0]
                  : this.translateService.instant('form_group.general_error'),
                identifier: uniqueString,
              });
            } finally {
              this.uploading = false;
              this.inputFile.nativeElement.value = '';
            }
          };

          if (!this.geoJson) upload();
        }
      });
    }
  }

  private handleVideoError(identifier: string) {
    this.uploadFailed.emit({
      identifier: identifier,
      error: this.translateService.instant('form_group.video_error'),
    });

    this.removeItem();
  }

  private handlePdfTooLarge(identifier: string) {
    this.uploadFailed.emit({
      error: this.translateService.instant(
        'input_file_preview.pdf_size_error',
        { max: this.maxPdfSize }
      ),
      identifier: identifier,
    });

    this.inputFile.nativeElement.value = '';
  }

  private handleVideoTooLarge(identifier: string) {
    this.uploadFailed.emit({
      error: this.translateService.instant('form_group.max_size', {
        max: this.maxVideoSize,
      }),
      identifier: identifier,
    });

    this.inputFile.nativeElement.value = '';
  }

  private handleImageTooLarge(identifier: string) {
    this.uploadFailed.emit({
      error: this.translateService.instant('form_group.max_image_size', {
        max: this.maxImageSize,
      }),
      identifier: identifier,
    });

    this.inputFile.nativeElement.value = '';
  }

  removeFile() {
    this.reset();
    this.fileRemoved.emit();
  }

  public reset() {
    this.errors = null;
    this.inputFile.nativeElement.value = '';
    this.selected = false;
  }

  /**
   * Mark file to be invalid
   */
  protected markInvalidFile(identifier: string) {
    this.errors = {
      uploadFileTypeInvalid: false,
    };

    this.uploadFailed.emit({
      error: this.translateService.instant('form_group.upload_file_type'),
      identifier: identifier,
    });

    this.inputFile.nativeElement.value = '';
  }

  /**
   * @param file
   * @returns {string}
   */
  protected getExtension(file) {
    const name = file.name;
    const nameParts = name.split('.');

    return nameParts[nameParts.length - 1];
  }

  /**
   * @returns {boolean}
   */
  protected hasCorrectMimeType(file: File): boolean {
    const types: string[] = this.getMimeTypes();

    return (
      types.length === 0 ||
      this.getMimeTypes().indexOf(file.type.toString()) !== -1
    );
  }

  removeItem() {
    this.uploader.clearQueue();
    this.inputFile.nativeElement.value = '';
  }

  cancel() {
    this.removeItem();
  }

  private handleErrors(error) {
    this.errors = {};

    // show error returned from server, as it will be a string containing entity violations
    if (error['hydra:description'] !== undefined) {
      this.errors['uploadServerError'] = error['hydra:description'];
    }

    if (error['_body'] !== undefined) {
      try {
        const errorJson = JSON.parse(error['_body']);

        if (!Array.isArray(this.errors['server'])) {
          this.errors['server'] = [];
        }

        this.errors['server'].push(errorJson['message']);
      } catch (error) {
        this.errorService.logError(error);
        console.log('Invalid JSON supplied');
      }
    }
  }
}
