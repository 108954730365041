<div class="target" [hidden]="!targets">
  <label class="label-lg" *ngIf="label">{{
    'target-picker.target.title' | translate
  }}</label>

  <select [formControl]="control" *ngIf="!multiple">
    <option value="">
      {{ 'target-picker.target.placeholder' | translate }}
    </option>
    <option *ngFor="let target of targets" [value]="target.id">
      {{ target.title }}
    </option>
  </select>

  <div *ngIf="multiple">
    <div *ngFor="let target of targets" class="checkbox">
      <input
        type="checkbox"
        id="target{{ target.id }}"
        [formControl]="multipleControls[target.id]"
      />
      <label for="target{{ target.id }}">
        {{ target.title }}
        <span *ngIf="target.devicesCount !== null">({{ target.devicesCount }})</span>
      </label>
    </div>
  </div>
</div>
