import {
  Component,
  Input,
  forwardRef,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { UploadService } from '../services/upload.service';
import { ModalComponent } from './modal.component';
import { resizeBase64ForMaxHeight } from 'resize-base64';
import { ErrorService } from '../services/error.service';
import { Upload } from '../interfaces/upload';
import { FileUploader, FileUploadModule } from 'ng2-file-upload';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { FormGroupComponent } from './form-group.component';
import { ImageRotateInfoComponent } from './image-rotate-info.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf, DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-input-file',
  templateUrl: 'input-file.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputFileComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    InlineSVGModule,
    ImageRotateInfoComponent,
    ModalComponent,
    ReactiveFormsModule,
    FormGroupComponent,
    FileUploadModule,
    ProgressbarModule,
    DecimalPipe,
    TranslateModule,
  ],
})
export class InputFileComponent implements ControlValueAccessor {
  private static readonly PREVIEW_MAX_HEIGHT = 256;
  private static readonly PREVIEW_MAX_WIDTH = 256;

  @Input() public uploadImages = false;
  @Input() public uploadPdfs = false;
  @Input() public uploadJson = false;

  @Input() public chooseVideo = false;
  @Input() public withCustomVideo = false;

  @Input() public withDragDrop = true;
  @Input() public simple = false;
  @Input() public simpleValue = null;
  @Input() public labelSelectedValue = null;
  @Input() public xhrUpload = true;
  @Input() public large = true;
  @Input() public multiple = false;
  @Input() public withName = false;
  @Input() public label = null;
  @Input() public instruction = null;
  @Input() public rotateWarning = null;
  @Input() public fullEntity = false;
  @Input() public type;
  @Input() public unique = '';
  @Input() public withEditor = false;
  @Input() public finishedEditing = false;
  @Output() previewUpdated = new EventEmitter();
  @Output() videoChosen = new EventEmitter();
  @Output() startUploading = new EventEmitter();
  @Output() stopUploading = new EventEmitter();
  @Output() startEditing = new EventEmitter();
  @Output() stopEditing = new EventEmitter();

  @ViewChild('inputFile') inputFile: ElementRef;
  @ViewChild('videoModal', { static: true }) videoModal: ModalComponent;
  public name: string;
  public videoForm: FormGroup;
  videoFileName: string;
  maxVideoSize: number = 275;
  maxPdfSize: number = 20;
  maxImageSize: number = 20;

  uploader: FileUploader = null;
  sasToken: string;
  @ViewChild('videoInput') videoInput: ElementRef;
  @ViewChild('youtubeInput') youtubeInput: ElementRef;

  /**
   * @see https://stackoverflow.com/a/27728417/1646331
   * @type {RegExp}
   */
  private videoUrlRegex =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

  private imageTypes = {
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    webp: 'image/webp',
  };

  private pdfTypes = {
    pdf: 'application/pdf',
  };

  private jsonTypes = {
    json: 'application/json',
    ext: '.geojson',
    geojson: 'application/geo+json',
    txt: 'text/plain',
  };

  public errors: any = null;

  public isLoading = false;
  public uploading = false;

  /**
   * @param uploadService
   * @param formBuilder
   * @param errorService
   */
  constructor(
    private uploadService: UploadService,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private translateService: TranslateService
  ) {
    this.createVideoForm();
    this.uploader = new FileUploader({
      url: '',
      method: 'PUT',
      disableMultipart: true,
      autoUpload: false,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };

    this.uploader.onAfterAddingFile = (file) => {
      if (this.uploader.queue.length > 1) {
        this.uploader.removeFromQueue(this.uploader.queue[0]);
      } else {
        this.uploadService
          .getSASToken(this.type, file.file.name)
          .then((res) => {
            this.videoFileName = res.file_name;
            this.uploader.setOptions({
              url: res.sas_token,
              headers: [
                { name: 'x-ms-blob-type', value: 'BlockBlob' },
                { name: 'Content-Type', value: file.file.type },
                { name: 'x-ms-blob-content-type', value: file.file.type },
              ],
            });
            this.uploader.uploadAll();
          })
          .catch((err) => {
            this.removeItem();
            this.videoForm.get('videoUrl').setErrors({
              incorrect: true,
              videoError: true,
              message: this.translateService.instant('form_group.video_error'),
            });
          });
      }
    };

    this.uploader.onCompleteAll = () => {
      this.uploading = false;
    };

    this.uploader.onErrorItem = () => {
      this.removeItem();
      this.videoForm.get('videoUrl').setErrors({
        incorrect: true,
        videoError: true,
        message: this.translateService.instant('form_group.video_error'),
      });
    };
  }

  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  /**
   * Method to allow submit if file is wrong format (file will be empty)
   * @returns {void}
   */
  public ignoreInvalidFile(): void {
    if (this.errors != null && this.errors['uploadFileTypeInvalid'] != null) {
      delete this.errors['uploadFileTypeInvalid'];
    }
  }

  /**
   * Add a video as file
   */
  public addVideo() {
    if (this.videoForm.valid) {
      if (this.videoForm.get('videoUrl').value) {
        if (this.uploader.queue[0].isUploaded) {
          let copy = Object.assign({}, this.uploader.queue[0].file);
          this.videoChosen.emit({
            url: this.videoFileName,
            type: 'video',
            file: copy,
          });
        }
        this.videoForm
          .get('youtubeUrl')
          .setValidators([
            Validators.required,
            Validators.pattern(this.videoUrlRegex),
          ]);
      } else if (this.videoForm.get('youtubeUrl').value) {
        const url = this.videoForm.get('youtubeUrl').value;

        // must match because of Angular validations
        const videoId = url.match(this.videoUrlRegex)[1];

        this.videoChosen.emit({ url: videoId, type: 'youtube' });
      } else {
        return;
      }
      this.videoInput.nativeElement.value = '';
      this.uploader.clearQueue();
      this.videoModal.close();
      this.videoForm.reset();
      this.videoForm.get('youtubeUrl').enable();
    } else {
      this.videoForm.get('videoUrl').markAsDirty();
      this.videoForm.get('videoUrl').markAsTouched();
      this.videoForm.get('youtubeUrl').markAsDirty();
      this.videoForm.get('youtubeUrl').markAsTouched();
      this.videoForm
        .get('videoUrl')
        .setErrors({ incorrect: true, videoError: true, message: '' });
      this.videoForm.get('youtubeUrl').setErrors({
        incorrect: true,
        videoError: true,
        message: this.translateService.instant('input_file.video.no_video'),
      });
    }
  }

  /**
   * @returns {string[]}
   */
  public getExtensionTypes(): string[] {
    let result: string[] = [];

    if (this.uploadImages) {
      result = result.concat(Object.keys(this.imageTypes));
    }

    if (this.uploadPdfs) {
      result = result.concat(Object.keys(this.pdfTypes));
    }

    if (this.uploadJson) {
      result = result.concat(Object.keys(this.jsonTypes));
    }

    return result;
  }

  /**
   * @returns {string[]}
   */
  public getMimeTypes(): string[] {
    let result: string[] = [];

    if (this.uploadImages) {
      result = result.concat(this.getMimeTypeByType(this.imageTypes));
    }

    if (this.uploadPdfs) {
      result = result.concat(this.getMimeTypeByType(this.pdfTypes));
    }

    if (this.uploadJson) {
      result = result.concat(this.getMimeTypeByType(this.jsonTypes));
    }

    return result;
  }

  /**
   * @param {object} types
   * @returns {string[]}
   */
  private getMimeTypeByType(types: object): string[] {
    return Object.keys(types).map((key) => {
      return types[key];
    });
  }

  public edit(event) {
    this.startEditing.emit(event);
  }

  /**
   * @returns {Promise<void>}
   */
  public async upload(files: File[]): Promise<void> {
    this.errors = {};

    if (files.length > 0) {
      let filesUploaded = 0;
      const fileLength = files.length;
      for (let i = 0; i < files.length; i++) {
        const file: File = files[i];
        const reader = new FileReader();
        const extension = this.getExtension(file);

        if (
          !this.hasCorrectMimeType(file) &&
          extension !== 'json' &&
          extension !== 'geojson'
        ) {
          // json will be parsed afterwards
          this.markInvalidFile();
        } else if (
          (extension == 'json' || extension == 'geojson') &&
          !this.uploadJson
        ) {
          this.markInvalidFile();
        } else if (
          extension === 'pdf' &&
          file.size > this.maxPdfSize * 1024 * 1024
        ) {
          this.handlePdfTooLarge();
        } else if (
          Object.keys(this.imageTypes).includes(extension) &&
          file.size > this.maxImageSize * 1024 * 1024
        ) {
          this.handleImageTooLarge();
        } else {
          this.isLoading = true;

          this.startUploading.emit();
          const upload = async () => {
            try {
              const uploaded: Upload = await this.uploadService.upload(
                this.type,
                file
              );

              if (++filesUploaded === fileLength) {
                this.isLoading = false;
                this.stopUploading.emit(uploaded);
              }

              let result: any = uploaded.file;

              if (uploaded.base64ImagePreview) {
                this.previewUpdated.emit(uploaded.base64ImagePreview);
              }

              if (this.fullEntity) {
                result = {
                  preview: uploaded.base64ImagePreview,
                  filePath: result,
                  fileName: file.name,
                };
              }

              this.propagateChange(result);
              this.propagateTouch(result);
            } catch (error) {
              this.handleErrors(error);
              this.isLoading = false;
              this.propagateChange(null);
              this.propagateTouch(null);
              this.stopUploading.emit(error);
            } finally {
              this.stopEditing.emit();
              this.inputFile.nativeElement.value = ''; // clear
            }
          };

          reader.onloadend = async () => {
            const preview = reader.result as string;

            let data;

            if (extension === 'json' || extension === 'geojson') {
              try {
                data = atob((reader.result as string).split('base64,')[1]);

                JSON.parse(data);
              } catch (error) {
                this.errorService.logError(error);
                console.info('Invalid JSON-file supplied');

                this.markInvalidFile();
                return;
              }
            }
            this.previewUpdated.emit(preview);

            if (data == null) {
              data = atob(preview.split('base64,')[1]);
            }

            this.propagateChange(data);
            this.propagateTouch(data);
          };

          this.name = file.name;

          if (!this.xhrUpload) {
            reader.readAsDataURL(file);
          } else {
            upload();
          }
        }
      }
    }
  }

  private handlePdfTooLarge() {
    this.errors['pdfMaxSizeExceeded'] = this.translateService.instant(
      'input_file_preview.pdf_size_error',
      { max: this.maxPdfSize }
    );

    this.inputFile.nativeElement.value = ''; // clear

    this.propagateChange(null);
    this.propagateTouch(null);
  }

  private handleImageTooLarge() {
    this.errors['imageMaxSizeExceeded'] = this.translateService.instant(
      'form_group.max_image_size',
      { max: this.maxImageSize }
    );

    this.inputFile.nativeElement.value = ''; // clear

    this.propagateChange(null);
    this.propagateTouch(null);
  }

  /**
   * Mark file to be invalid
   */
  protected markInvalidFile() {
    this.errors = {
      uploadFileTypeInvalid: false,
    };

    this.inputFile.nativeElement.value = ''; // clear

    this.propagateChange(null);
    this.propagateTouch(null);
  }

  /**
   * @param file
   * @returns {string}
   */
  protected getExtension(file) {
    const name = file.name;
    const nameParts = name.split('.');

    return nameParts[nameParts.length - 1];
  }

  /**
   * @returns {boolean}
   */
  protected hasCorrectMimeType(file: File): boolean {
    const types: string[] = this.getMimeTypes();

    return (
      types.length === 0 ||
      this.getMimeTypes().indexOf(file.type.toString()) !== -1
    );
  }

  /**
   * @param obj
   */
  writeValue(obj: any): void {
    this.errors = null;

    if (obj == null) {
      this.name = null;
    }
  }

  /**
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /**
   * @param control
   * @returns {any}
   */
  validate(control: FormControl): any {
    control.markAsDirty();
    return this.errors;
  }

  async resizePreview(base64: string): Promise<string> {
    return new Promise((resolve, reject) => {
      resizeBase64ForMaxHeight(
        base64,
        InputFileComponent.PREVIEW_MAX_WIDTH,
        InputFileComponent.PREVIEW_MAX_HEIGHT,
        (result) => resolve(result),
        reject
      );
    });
  }

  removeFile(event) {
    this.uploading = false;

    this.propagateChange(null);
    this.propagateTouch(null);
    this.previewUpdated.emit(null);

    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  /**
   * Creates a form for a youtube url
   */
  private createVideoForm() {
    this.videoForm = this.formBuilder.group({
      videoUrl: [],
      youtubeUrl: [
        null,
        [Validators.required, Validators.pattern(this.videoUrlRegex)],
      ],
    });
    this.videoForm.get('youtubeUrl').valueChanges.subscribe((value) => {
      if (this.videoForm.get('youtubeUrl').valid && value != null)
        this.videoForm.get('videoUrl').patchValue(null);
    });
  }

  removeItem() {
    this.uploader.clearQueue();
    this.videoForm
      .get('youtubeUrl')
      .setValidators([
        Validators.required,
        Validators.pattern(this.videoUrlRegex),
      ]);
    this.videoForm.get('youtubeUrl').updateValueAndValidity();
    this.videoForm.get('youtubeUrl').enable();
    this.videoInput.nativeElement.value = '';
    this.youtubeInput.nativeElement.focus();
    this.uploading = false;
  }

  cancel() {
    this.removeItem();
    this.videoModal.close();
    this.videoForm.reset();
  }

  fileSelected() {
    this.videoForm.get('videoUrl').markAsTouched();
    if (this.uploader != null) {
      let fileItem = this.uploader.queue[0];
      if (fileItem.file.size > this.maxVideoSize * 1024 * 1024) {
        this.videoForm.get('videoUrl').setErrors({
          incorrect: true,
          videoError: true,
          message: this.translateService.instant('form_group.max_size', {
            max: this.maxVideoSize - 25,
          }),
        });
        this.removeItem();
      } else {
        this.uploading = true;
        this.videoForm.get('youtubeUrl').clearValidators();
        this.videoForm.get('youtubeUrl').updateValueAndValidity();
        this.videoForm.get('youtubeUrl').patchValue(null);
        this.videoForm.get('youtubeUrl').disable();
      }
    }
  }

  selectFile() {
    this.videoInput.nativeElement.click();
  }

  private handleErrors(error) {
    this.errors = {};

    // show error returned from server, as it will be a string containing entity violations
    if (error['hydra:description'] !== undefined) {
      this.errors['uploadServerError'] = error['hydra:description'];
    }

    if (error['_body'] !== undefined) {
      try {
        const errorJson = JSON.parse(error['_body']);

        if (!Array.isArray(this.errors['server'])) {
          this.errors['server'] = [];
        }

        this.errors['server'].push(errorJson['message']);
      } catch (error) {
        this.errorService.logError(error);
        console.log('Invalid JSON supplied');
      }
    }
  }

  public getInputFileReference() {
    return this.inputFile;
  }

  clear() {
    this.inputFile.nativeElement.value = '';
  }
}
