import { Entity } from '../interfaces/entity';

export class CollectionHelper {
  public static mapHydraId(array: Entity[]): any[] {
    return array.map((item) => item['@id'].replace(/%25/gi, '%')); // the latter is a fix for api platform bug where identifier gets URL-encoded twice
  }

  public static addHydraId(array: any[], field: string, type: string): any[] {
    array.forEach((item) =>
      item['@id'] == null ? (item['@id'] = `/api/${type}/${item[field]}`) : null
    );

    return array;
  }
}
