<app-modal [title]="'confirm_delete.title' | translate" #modal>
  {{ 'confirm_delete.text' | translate }} {{ message }}

  <div class="justified-buttons">
    <button (click)="modal.close()" class="button button--m button--ghost">
      {{ 'confirm_delete.cancel' | translate }}
    </button>

    <button (click)="confirm()" class="button button--m button--outlined bg--red--hover">
      {{ 'confirm_delete.confirm' | translate }}
    </button>
  </div>
</app-modal>
