<form [formGroup]="form">
  <div class="period">
    <app-switch formControlName="type">
      <app-switch-option *ngIf="hasWeek" value="week">{{
        'period.type.week' | translate
      }}</app-switch-option>
      <app-switch-option *ngIf="hasDay" value="day">{{
        'period.type.day' | translate
      }}</app-switch-option>
      <app-switch-option *ngIf="hasMonth" value="month">{{
        'period.type.month' | translate
      }}</app-switch-option>
      <app-switch-option *ngIf="hasTotal" value="total">{{
        'period.type.total' | translate
      }}</app-switch-option>
      <app-switch-option *ngIf="hasCustom" value="custom">{{
        'period.type.custom' | translate
      }}</app-switch-option>
    </app-switch>

    <div class="manual" [hidden]="type !== 'custom'">
      <span>{{ 'period.from' | translate }}</span>
      <app-date-picker
        formControlName="start"
        [withTime]="false"
        [inline]="true"
      ></app-date-picker>
      <span>{{ 'period.till' | translate }}</span>
      <app-date-picker
        formControlName="end"
        [withTime]="false"
        [inline]="true"
      ></app-date-picker>
    </div>
  </div>
</form>
