import { Injectable } from '@angular/core';
import { RatingTheme } from '../interfaces/rating-theme';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class RatingThemeService {
  static base = '/rating-themes';

  constructor(private http: HttpClient) {}

  /**
   * @returns {Promise<RatingTheme[]>}
   */
  public async list(): Promise<RatingTheme[]> {
    const themes = await this.http
      .get(environment.apiUrl + RatingThemeService.base)
      .toPromise();

    return themes['hydra:member'];
  }
}
