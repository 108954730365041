<section class="smart-reporting">
  <div class="container" *appVersion="1">
    <div class="bouwapp-header">
      <div class="bouwapp-header__text">
        <h1>{{ 'smart_reporting.notices.title' | translate }}</h1>
        <p>{{ 'smart_reporting.notices.description' | translate }}</p>
      </div>
    </div>
  </div>

  <div class="container" *appVersion="1">
    <app-loader *ngIf="!projectList"></app-loader>

    <div class="notices smart-reporting__grow-container" *ngIf="projectList">
      <div class="notices__projects">
        <div
          *ngFor="let project of projectList"
          class="notices__projects__item"
          [class.notices__projects__item--active]="
            project.slug === activeProjectSlug
          "
          routerLink="/smart-reporting/notices/project/{{ project.slug }}"
        >
          <div class="notices__projects__icon">
            <img
              *ngIf="project.coverImageThumbnails"
              [src]="getCoverImageByProject(project)"
              [alt]="project.name"
            />
          </div>

          <div class="notices__projects__item__text">
            {{ project.name }}
          </div>
        </div>
      </div>

      <div class="notices__data">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <ng-container *appVersion="2">
    <div class="bouwapp-header">
      <div class="bouwapp-header__text">
        <h1>{{ 'smart_reporting.notices.title' | translate }}</h1>
      </div>

      <div class="bouwapp-header__buttons" *appVersion="2">
        <a
          type="button"
          class="tertiary"
          href="javascript:void(0)"
          (click)="exportCsv()"
        >
          <span>
            {{ 'smart_reporting.notices.export' | translate }}
          </span>
        </a>
        <a
          type="button"
          appAccess
          role="manager"
          [project]="noticesTable.project"
          routerLink="create"
          class="secondary"
          *ngIf="noticesTable.project"
        >
          <span
            class="icon"
            [inlineSVG]="'/assets/icons/add--white.svg'"
          ></span>
          <span>{{ 'smart_reporting.notices.create' | translate }}</span>
        </a>
      </div>
    </div>
    <app-smart-reporting-notices-table></app-smart-reporting-notices-table>
  </ng-container>
</section>
