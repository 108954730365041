<ng-container *appVersion="1">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1>{{ 'projects.detail.menu.phases' | translate }}</h1>
    </div>
  </div>

  <app-loader *ngIf="!phases"></app-loader>

  <div
    class="bouwapp-phases"
    *ngIf="phases"
    [ngClass]="{ isCompact: project.isCompact }"
  >
    <div class="bouwapp-phases__start">
      <div class="bouwapp-phases__start__date" *ngIf="project">
        <div
          [inlineSVG]="'/assets/img/icons/message-START.svg'"
          class="icon"
        ></div>
        <p>{{ project.startDate | dateType : project.startType : 'long' }}</p>
        <div
          [inlineSVG]="'/assets/img/icons/info-dark.svg'"
          (click)="dateMessage = !dateMessage"
          appAccess
          role="manager"
          [project]="project"
        ></div>
      </div>

      <ul class="bouwapp-phases__start__filter">
        <li
          class="compact"
          [class.active]="project.isCompact"
          (click)="toggleView(project)"
        >
          <span></span>
          <span></span>
          <span></span>
        </li>
        <li
          class="expanded"
          [class.active]="!project.isCompact"
          (click)="toggleView(project)"
        >
          <span></span>
          <span></span>
          <span></span>
        </li>
      </ul>

      <p class="bouwapp-phases__start__info" *ngIf="dateMessage">
        {{ 'projects.detail.phase.date.info' | translate }}
      </p>
    </div>

    <div class="bouwapp-phases__overview">
      <div
        class="bouwapp-phases__phase"
        *ngFor="let phase of phases"
        [class.collapsed]="phase.isCollapsed"
        [class.stacked]="phase.isStacked"
      >
        <div class="bouwapp-phases__phase__head" (click)="togglePhase(phase)">
          <p *ngIf="phase.name">
            {{
              phase.name.length > 50
                ? (phase.name | slice : 0 : 50) + '...'
                : phase.name
            }}
            /
            <span>
              {{
                'project.detail.phases.list.phase_title_appendix' +
                  (!phase.endDate ? '.without_end' : '')
                  | translate
                    : {
                        from: (phase.startDate | dateType : phase.startType),
                        till: (phase.endDate | dateType : phase.endType)
                      }
              }}
            </span>
          </p>
          <span [inlineSVG]="'/assets/img/icons/caret-down-dark.svg'"></span>
        </div>

        <div class="bouwapp-phases__phase__link" *ngIf="phase.isStacked"></div>

        <div class="bouwapp-phases__phase__bar">
          <div class="bouwapp-phases__phase__bar__self"></div>
          <div class="bouwapp-phases__phase__bar__text">
            <span>
              {{ phase.name }}
            </span>
          </div>
        </div>

        <div class="bouwapp-phases__phase__messages">
          <div class="bouwapp-phases__phase__message">
            <div class="bouwapp-phases__phase__message__top">
              <ul>
                <li>
                  <span>{{
                    'projects.detail.announcement.tile.name' | translate
                  }}</span>
                  <span>{{ phase.name }}</span>
                </li>
                <li class="date">
                  <span>{{
                    'projects.detail.announcement.tile.start' | translate
                  }}</span>
                  <span>{{
                    phase.startDate | dateType : phase.startType : 'long'
                  }}</span>
                </li>
                <li class="date" [hidden]="!phase.endDate">
                  <span>{{
                    'projects.detail.announcement.tile.end' | translate
                  }}</span>
                  <span>{{
                    phase.endDate | dateType : phase.endType : 'long'
                  }}</span>
                </li>
                <li *ngIf="phase.attachments.length">
                  <span>{{
                    'projects.detail.announcement.tile.attachment' | translate
                  }}</span>
                  <span>
                    {{ phase.attachments.length }}
                  </span>
                </li>
              </ul>
            </div>

            <div class="bouwapp-phases__phase__message__bottom">
              <div [innerHTML]="phase.text | words : 64 : '...'"></div>
              <div appAccess role="manager" [project]="project">
                <a
                  routerLink="/projects/{{ project.slug }}/phases/{{
                    phase.id
                  }}/edit"
                  >{{ 'projects.detail.announcement.tile.edit' | translate }}</a
                >
                <span
                  (click)="
                    confirmDeletePhase.confirmDelete(phase);
                    $event.stopPropagation();
                    (false)
                  "
                  >{{
                    'projects.detail.announcement.tile.remove' | translate
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="alert alert-info" *ngIf="phases && phases.length == 0">
        {{ 'project.detail.phases.list.empty' | translate }}
      </div>

      <a
        *ngIf="phases && phases.length == 0"
        routerLink="/projects/{{ project.slug }}/phases/new"
        class="button button--l button--flat bg--orange"
        appAccess
        role="manager"
        [project]="project"
      >
        {{ 'project.detail.phases.list.new' | translate }}
      </a>

      <div *ngIf="phases && phases.length > 0" class="bouwapp-bottombar">
        <a
          *ngIf="phases"
          routerLink="/projects/{{ project.slug }}/phases/new"
          class="button button--l button--flat bg--orange"
          appAccess
          role="manager"
          [project]="project"
        >
          {{ 'project.detail.phases.list.new' | translate }}
        </a>
      </div>
    </div>

    <div class="bouwapp-phases__end" *ngIf="project.endDate">
      <div class="bouwapp-phases__end__date">
        <div
          [inlineSVG]="'/assets/img/icons/finish-flag.svg'"
          class="icon"
        ></div>
        <p>{{ project.endDate | dateType : project.endType : 'long' }}</p>
        <div
          [inlineSVG]="'/assets/img/icons/info-dark.svg'"
          (click)="dateMessage = !dateMessage"
          appAccess
          role="manager"
          [project]="project"
        ></div>
      </div>

      <p class="bouwapp-phases__end__info" *ngIf="dateMessage">
        {{ 'projects.detail.phase.date.info' | translate }}
      </p>
    </div>
  </div>
</ng-container>

<ng-container *appVersion="2">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1>{{ 'projects.detail.menu.phases' | translate }}</h1>
    </div>
    <div class="bouwapp-header__buttons">
      <a
        [routerLink]="['..', 'events', 'new']"
        type="button"
        class="secondary"
        appAccess
        role="manager"
      >
        <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
        <span>{{ 'project.detail.events.new' | translate }}</span>
      </a>
      <a [routerLink]="['new']" class="primary" appAccess role="manager">
        <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
        <span>{{ 'project.detail.phases.new' | translate }}</span>
      </a>
    </div>
  </div>
  <div class="bouwapp-phases" *ngIf="planningItems">
    <div class="bouwapp-phases__start">
      <span inlineSVG="/assets/v2/img/icons/rocket.svg"></span>
      <span
        >{{
          (projectStarted
            ? 'project.detail.phases.started'
            : 'project.detail.phases.starts'
          ) | translate
        }}
        {{ project.startDate | dateType : project.startType : 'long' }}</span
      >
      <a [routerLink]="['..', 'general']" fragment="planning">{{
        'project.detail.phases.list.edit' | translate
      }}</a>
    </div>

    <div
      class="bouwapp-phases__item past"
      *ngIf="!showPastPhases && showPastButton && planningItems.length > 0"
    >
      <button (click)="showPastPhases = true">
        <span inlineSVG="/assets/v2/img/icons/arrow-up.svg"></span>
        <span>{{ 'project.detail.phases.past' | translate }}</span>
      </button>
    </div>

    <div class="bouwapp-phases__item empty" *ngIf="!planningItems.length">
      <p>{{ 'project.detail.phases.list.empty' | translate }}</p>
    </div>

    <div
      *ngFor="let item of planningItems"
      [hidden]="!showPastPhases && isPast(item)"
      class="bouwapp-phases__item"
      [ngClass]="
        item.type == 'phase' && {
          first: (item['index'] + 1) % 3 == 1,
          second: (item['index'] + 1) % 3 == 2,
          third: (item['index'] + 1) % 3 == 0
        }
      "
    >
      <ng-container *ngIf="item.type === 'phase'; else event">
        <ng-template
          [ngTemplateOutlet]="phaseTemplate"
          [ngTemplateOutletContext]="{ $implicit: item, phase: item }"
        ></ng-template>
      </ng-container>
      <ng-template #event>
        <ng-template
          [ngTemplateOutlet]="eventTemplate"
          [ngTemplateOutletContext]="{ $implicit: item, event: item }"
        ></ng-template>
      </ng-template>
    </div>

    <div class="bouwapp-phases__end" *ngIf="project.endDate">
      <span inlineSVG="/assets/v2/img/icons/flag-planning.svg"></span>
      <span
        >{{
          (projectEnded
            ? 'project.detail.phases.ended'
            : 'project.detail.phases.ends'
          ) | translate
        }}
        {{ project.endDate | dateType : project.endType : 'long' }}</span
      >
      <a [routerLink]="['..', 'general']" fragment="planning">{{
        'project.detail.phases.list.edit' | translate
      }}</a>
    </div>
  </div>
</ng-container>

<ng-template #phaseTemplate let-phase>
  <div class="phase">
    <div class="left">
      <span *ngIf="isCurrent(phase)" class="current">
        <span inlineSVG="/assets/v2/img/icons/flag-planning.svg"></span>
        <span>{{ 'project.detail.phases.current' | translate }}</span>
      </span>
      <h3>
        {{ phase.name }}
      </h3>
      <span>
        {{ phase.startDate | dateType : phase.startType : 'long' }} -
        {{ phase.endDate | dateType : phase.endType : 'long' }}
      </span>
      <div [innerHTML]="phase.text"></div>
    </div>
    <div class="right">
      <a (click)="confirmDeletePhase.confirmDelete(phase)">{{
        'project.detail.phases.list.delete' | translate
      }}</a>
      <a [routerLink]="[phase.id, 'edit']">{{
        'project.detail.phases.list.edit' | translate
      }}</a>
    </div>
  </div>
  <div class="events">
    <ng-template
      *ngFor="let event of phase.events"
      [ngTemplateOutlet]="eventTemplate"
      [ngTemplateOutletContext]="{ $implicit: event, event: event }"
    ></ng-template>
  </div>
</ng-template>

<ng-template #eventTemplate let-event>
  <div class="event">
    <div class="event__content">
      <div class="left">
        <h3>{{ event.title }}</h3>
        <span>
          {{
            (hasStarted(event)
              ? 'project.detail.events.started'
              : 'project.detail.events.starts'
            ) | translate
          }}
          {{ event.startDate | dateType : 'EVENT' }}
        </span>
        <span *ngIf="event.endDate">
          {{
            (hasEnded(event)
              ? 'project.detail.events.ended'
              : 'project.detail.events.ends'
            ) | translate
          }}
          {{ event.endDate | dateType : 'EVENT' }}
        </span>
        <div>
          <span *ngIf="isUpcomingEvent(event)">
            {{ 'project.detail.events.starts_in' | translate }}
            {{ event.startDate | countdown }}
          </span>
          <ng-container *ngIf="isCurrentEvent(event)">
            <span *ngIf="getEndDateDiff(event) < 1">
              {{ 'project.detail.events.less_than_hour' | translate }}
            </span>
            <span *ngIf="getEndDateDiff(event) >= 1">
              {{ event.endDate | countdown }}
              {{ 'project.detail.events.remaining' | translate }}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="right">
        <a (click)="confirmDeleteEvent.confirmDelete(event)">{{
          'project.detail.phases.list.delete' | translate
        }}</a>
        <a [routerLink]="['..', 'events', event.id, 'edit']">{{
          'project.detail.phases.list.edit' | translate
        }}</a>
        <span *ngIf="isUpcomingEvent(event)" class="upcoming">
          {{ 'project.detail.events.upcoming' | translate }}
        </span>
        <span *ngIf="isCurrentEvent(event)" class="current">
          {{
            (getEndDateDiff(event) < 3
              ? 'project.detail.events.ends_soon'
              : 'project.detail.events.happening_now'
            ) | translate
          }}
        </span>
      </div>
    </div>
  </div>
</ng-template>

<app-confirm-delete
  #confirmDeletePhase
  (confirmed)="deletePhase($event)"
></app-confirm-delete>
<app-confirm-delete
  #confirmDeleteEvent
  (confirmed)="deleteEvent($event)"
></app-confirm-delete>
