import { Component, viewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Project } from 'src/app/interfaces/project';
import { Update } from 'src/app/interfaces/update';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { UpdateService } from 'src/app/services/update.service';
import { UpdatesTileComponent } from '../updates/tile.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AccessDirective } from 'src/app/directives/access.directive';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { SurveyService } from 'src/app/services/survey.service';
import { TicketService } from 'src/app/services/ticket.service';
import { Ticket } from 'src/app/interfaces/ticket';
import { ThemeService } from 'src/app/services/theme.service';
import { StatisticsService } from 'src/app/services/statistics.service';
import { StatisticsType } from 'src/app/enums/statistics-type';
import { LoaderComponent } from 'src/app/components/loader.component';
import { ModalComponent } from 'src/app/components/modal.component';
import { Module } from 'src/app/enums/module';
import { DatePickerV2Component } from 'src/app/components/date-picker-v2.component';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { FormGroupComponent } from 'src/app/components/form-group.component';
import { ExportService } from 'src/app/services/export.service';
import { ErrorService } from 'src/app/services/error.service';
import { PlatformService } from 'src/app/services/platform.service';
import { QrModalComponent } from '../../../components/qr-modal/qr-modal.component';
import { environment } from 'src/environments/environment';
import { ProjectStatus } from 'src/app/enums/project-status';

@Component({
  selector: 'app-default-projects-detail-dashboard',
  templateUrl: 'detail-dashboard.component.html',
  standalone: true,
  imports: [
    RouterLink,
    AccessDirective,
    NgFor,
    NgIf,
    TranslateModule,
    UpdatesTileComponent,
    InlineSVGModule,
    BaseChartDirective,
    DatePipe,
    LoaderComponent,
    ModalComponent,
    DatePickerV2Component,
    FormGroupComponent,
    ReactiveFormsModule,
    QrModalComponent,
  ],
})
export class DetailDashboardComponent {
  readonly modal = viewChild<ModalComponent>('chartModal');

  public ProjectStatus = ProjectStatus;
  public project: Project;
  public updates: Update[];
  public tickets: Ticket[];
  public surveyResponses: any[];
  public charts: any[];
  public loading: boolean = true;
  public currentChartTitle: string;
  public currentChartDescription: string;
  public surveysEnabled: boolean;
  public form: FormGroup;

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        backgroundColor: '#ffffff',
        titleColor: '#212121',
        bodyColor: '#212121',
        bodyFont: { weight: 'normal', family: 'Mukta' },
        titleFont: { weight: 'bold', family: 'Mukta' },
        cornerRadius: 5,
        borderColor: '#e9e9e9',
        borderWidth: 1,
        padding: 8,
        boxPadding: 8,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: '',
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
        },
        border: {
          width: 0,
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
        border: {
          width: 0,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  constructor(
    private projectDataBusService: ProjectDataBusService,
    private updateService: UpdateService,
    private surveyService: SurveyService,
    private ticketService: TicketService,
    private statisticsService: StatisticsService,
    private themeService: ThemeService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private errorService: ErrorService,
    private exportService: ExportService
  ) {
    this.themeService.getVersion().then((version) => {
      if (version == 1) {
        this.router.navigate(['general'], { relativeTo: this.route.parent });
      } else {
        this.projectDataBusService.projectObservable.subscribe((project) => {
          this.project = project;
          this.surveysEnabled =
            project.enabledModules &&
            project.enabledModules.includes(Module.SURVEY);
          if (this.project?.id) {
            this.loadDashboard();
            this.createForm();
          }
        });
      }
    });
  }

  private createForm() {
    this.form = this.fb.group(
      {
        from: [
          moment(this.project.startDate).format('YYYY-MM-DDTHH:mm'),
          Validators.required,
        ],
        till: [moment().format('YYYY-MM-DDTHH:mm'), Validators.required],
        project: [this.project, Validators.required],
      },
      {
        validator: this.dateRangeValidator,
      }
    );
  }

  async downloadExport() {
    this.errorService.markFormGroupTouchedAndDirty(this.form);

    if (!this.form.valid) {
      return;
    }

    await this.exportService.export({
      showPeriod: true,
      ...this.form.value,
    });
  }

  dateRangeValidator(control: FormGroup): ValidationErrors | null {
    const fromControl = control.get('from');
    const tillControl = control.get('till');

    if (fromControl.value) fromControl.setErrors(null);
    if (tillControl.value) tillControl.setErrors(null);

    if (
      fromControl.value &&
      tillControl.value &&
      fromControl.value > tillControl.value
    ) {
      const error = { invalidDateRange: true };
      fromControl.setErrors(error);
      tillControl.setErrors(error);
      return error;
    }

    return null;
  }

  loadDashboard() {
    this.loading = true;
    this.charts = [];
    this.loadStatistics();
    this.loadLatestUpdates();
    this.loadLatestConversations();
    this.loadLatestSurveyResponses();
  }

  setupChartData(label: string, data: any) {
    const chartType = Object.values(data).length > 8 ? 'line' : 'bar';
    const labelCode = Object.keys(StatisticsType).find(
      (key) => StatisticsType[key] === label
    );
    const options: ChartOptions = structuredClone(this.chartOptions);
    if (chartType == 'line') options.scales.x.ticks['autoSkipPadding'] = 10;
    if (label === StatisticsType.RATING) {
      options.scales.y.min = 0;
      options.scales.y.max = 5;
    }
    const chart = {
      description: this.translateService.instant(
        'projects.detail.dashboard.chart.' + labelCode + '.desc'
      ),
      config: {
        options: options,
        type: chartType,
        data: {
          datasets: [
            {
              data: Object.values(data),
              label: this.translateService.instant(
                'projects.detail.dashboard.chart.' + labelCode
              ),
              fill: false,
              borderWidth: chartType == 'bar' ? 0 : 3,
              backgroundColor: '#f5b049',
              borderColor: '#f5b049',
              tension: 0.2,
              pointRadius: 0,
              minBarLength: 3,
            },
          ],
          labels: Object.keys(data),
        },
      },
    };
    this.charts.push(chart);
  }

  async loadStatistics() {
    try {
      const statistics = await this.statisticsService.getDashboardStatistics(
        this.project
      );
      for (const key in statistics) {
        this.setupChartData(key, statistics[key]);
      }
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  async loadLatestSurveyResponses() {
    const response = await this.surveyService.getLastUniqueResponsesForProject(
      this.project
    );
    this.surveyResponses = response;
  }

  async loadLatestConversations() {
    const response = await this.ticketService.list(
      this.project,
      {
        sort: {
          field: 'recent',
          direction: 'desc',
        },
        search: '',
        startDate: '',
        endDate: '',
      },
      ['OPEN'],
      1,
      2,
      null
    );
    this.tickets = response['hydra:member'];
  }

  async loadLatestUpdates() {
    const response = await this.updateService.listByProjectV2(
      this.project,
      1,
      10
    );

    this.updates = response['hydra:member'];
  }

  openModal(index: number) {
    this.currentChartTitle = this.charts[index].config.data.datasets[0].label;
    this.currentChartDescription = this.charts[index].description;
    this.modal().open();
  }

  getProjectLink(): string {
    const sufix = '/projects/' + this.project?.slug;
    //TODO: This is how is supposed to work, but since we only want to
    // show this for bouwapp and sitepodium i need to do some sketchy shit
    // please look away come back only if you want to uncomment this
    // return (
    //   'https://' + this.project.pwaDomain + sufix
    // );

    return PlatformService.platform.id !== 'ba'
      ? 'https://sitepodium.website' + sufix
      : environment.appUrl + sufix;
  }
}
