import { Component, OnInit } from '@angular/core';
import { Phase } from '../../../../interfaces/phase';
import { PhaseService } from '../../../../services/phase.service';
import { ProjectDataBusService } from '../../../../services/project-data-bus.service';
import { Project } from '../../../../interfaces/project';
import * as moment from 'moment';
import { DateTypePipe } from '../../../../pipes/date-type.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { ConfirmDeleteComponent } from '../../../../components/confirm-delete.component';
import { RouterLink } from '@angular/router';
import { AccessDirective } from '../../../../directives/access.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LoaderComponent } from '../../../../components/loader.component';
import {
  NgIf,
  NgClass,
  NgFor,
  SlicePipe,
  DatePipe,
  NgTemplateOutlet,
} from '@angular/common';
import { VersionDirective } from 'src/app/directives/version.directive';
import { Event } from 'src/app/interfaces/event';
import { EventService } from 'src/app/services/event.service';
import { ThemeService } from 'src/app/services/theme.service';
import { CountdownPipe } from 'src/app/pipes/countdown.pipe';

@Component({
  selector: 'app-detail-phases-list',
  templateUrl: './list.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    NgClass,
    InlineSVGModule,
    AccessDirective,
    NgFor,
    RouterLink,
    ConfirmDeleteComponent,
    SlicePipe,
    TruncateModule,
    TranslateModule,
    CountdownPipe,
    DateTypePipe,
    DatePipe,
    VersionDirective,
    NgTemplateOutlet,
  ],
})
export class DetailPhasesListComponent implements OnInit {
  public project: Project;
  public phases: Phase[];
  public planningItems: (Phase | Event)[];
  public events: Event[];
  public dragging = false;
  public dateMessage = false;
  public now = Date.now();
  public version: number;
  public showPastPhases: boolean = false;
  public showPastButton: boolean = false;
  public projectEnded: boolean;
  public projectStarted: boolean;

  constructor(
    private phaseService: PhaseService,
    private projectBusService: ProjectDataBusService,
    private eventService: EventService,
    private themeService: ThemeService
  ) {
    this.projectBusService.projectObservable.subscribe((project) => {
      this.project = project;
      this.projectEnded = project.endDate
        ? moment(project.endDate).isBefore(moment())
        : false;
      this.projectStarted = project.startDate
        ? moment(project.startDate).isBefore(moment())
        : false;
    });
  }

  public async ngOnInit(): Promise<void> {
    this.version = await this.themeService.getVersion();
    this.load();
  }

  async getPhaseEnd() {
    let checkDate;
    this.phases.forEach((phase: Phase) => {
      phase.isStacked = moment(phase.startDate) < checkDate;
      checkDate = moment(phase.endDate);
    });
  }

  async deletePhase(phase: Phase): Promise<void> {
    await this.phaseService.remove(phase);
    this.load();
  }

  async deleteEvent(event: Event): Promise<void> {
    await this.eventService.remove(event);
    this.load();
  }

  async togglePhase(phase: Phase): Promise<void> {
    phase.isCollapsed = !phase.isCollapsed;
  }

  async toggleView(project: Project): Promise<void> {
    project.isCompact = !project.isCompact;
  }

  private async load() {
    if (this.version === 1) {
      this.phases = await this.phaseService.listByProject(this.project);
      this.getPhaseEnd();
    } else {
      this.planningItems = await this.phaseService.listWithEvents(this.project);
      let index = 0;
      this.planningItems.forEach((item: Phase | Event) => {
        if (this.isPast(item)) this.showPastButton = true;
        if ((item as any).type === 'phase') {
          (item as any).index = index;
          index++;
        }
      });
    }
  }

  isPast(item: Phase | Event): boolean {
    if (item.endDate) return moment().isAfter(item.endDate);
    else return moment().isAfter(item.startDate);
  }

  isCurrent(phase: Phase): boolean {
    return moment().isBetween(moment(phase.startDate), moment(phase.endDate));
  }

  hasStarted(event: Event): boolean {
    return moment(event.startDate).isBefore(moment());
  }

  hasEnded(event: Event): boolean {
    return moment(event.endDate).isBefore(moment());
  }

  getEndDateDiff(event: Event): number {
    return moment(event.endDate).diff(moment(), 'hours');
  }

  isUpcomingEvent(event: Event): boolean {
    return moment(event.startDate).isAfter(moment());
  }

  isCurrentEvent(event: Event): boolean {
    return event.endDate
      ? moment().isBetween(event.startDate, event.endDate)
      : false;
  }
}
