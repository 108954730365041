import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { LocaleService } from '../services/locale.service';
import { switchMap } from 'rxjs/operators';
import { platform } from '../services/platform.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private localeService: LocaleService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isI18NRequest = !!request.url.match(/\/assets\/i18n\/[a-z]+\.json$/i);

    return from(
      (async () => {
        let token = null;

        if (!isI18NRequest) {
          await this.localeService.waitForInit();
        }

        return token;
      })()
    ).pipe(
      switchMap((token: string) => {
        const headers = {
          'Accept-Language': this.localeService.currentLocale,
          'X-App-Version': 'frontend',
          'X-Platform': platform.id,
          'X-Locale': isI18NRequest ? '' : this.localeService.currentLocale,
        };

        if (
          request.headers.get('Content-Type') === null &&
          !request.headers.get('X-File-Upload')
        ) {
          headers['Content-Type'] = 'application/json';
        }

        request = request.clone({
          setHeaders: headers,
        });

        return next.handle(request);
      })
    );
  }
}
