import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import * as moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerComponent } from './date-picker.component';
import { SwitchOptionComponent } from './switch-option.component';
import { NgIf } from '@angular/common';
import { SwitchComponent } from './switch.component';

@Component({
    selector: 'app-period',
    templateUrl: 'period.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PeriodComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PeriodComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [ReactiveFormsModule, SwitchComponent, NgIf, SwitchOptionComponent, DatePickerComponent, TranslateModule]
})
export class PeriodComponent implements ControlValueAccessor {
  form: FormGroup;
  type = 'week';

  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  @Input() hasWeek = true;
  @Input() hasDay = false;
  @Input() hasTotal = true;
  @Input() hasMonth = true;
  @Input() hasCustom = true;

  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  /**
   * @param value
   */
  writeValue(value: any): void {
    this.type = value;
    this.form.patchValue(value);
  }

  /**
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /**
   * @param control
   * @returns {any}
   */
  validate(control: FormControl): any {
    return this.form.get('type').value !== 'custom' ||
      moment(this.form.get('start').value).isSameOrBefore(
        this.form.get('end').value
      )
      ? null
      : { invalidPeriod: true };
  }

  /**
   * Create the form
   */
  private createForm() {
    this.form = this.formBuilder.group({
      type: ['week'],
      start: [moment().subtract(7, 'days').format()],
      end: [moment().format()],
    });

    this.form.valueChanges.subscribe((value) => {
      this.type = value.type;

      switch (this.type) {
        case 'day':
          value.end = moment().format();
          value.start = moment();
          break;
        case 'week':
          value.end = moment().format();
          value.start = moment().subtract(7, 'days').format();
          break;
        case 'month':
          value.end = moment().format();
          value.start = moment().subtract(30, 'days').format();
          break;
        case 'total':
          value.start = null;
          value.end = null;
          break;
      }

      this.propagateChange(value);
      this.propagateTouch(value);
    });
  }
}
