import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-toggle',
    templateUrl: 'toggle.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleComponent),
            multi: true,
        },
    ],
    standalone: true
})
export class ToggleComponent implements ControlValueAccessor {
  @Input() public small = false;
  value = false;

  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  /**
   * @param value
   */
  writeValue(value: boolean): void {
    this.value = value;
  }

  /**
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /**
   * @returns {void}
   */
  toggle() {
    this.value = !this.value;

    this.propagateChange(this.value);
    this.propagateTouch(this.value);
  }
}
