import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from './modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';
import { ProjectDataBusService } from '../services/project-data-bus.service';
import { Project } from '../interfaces/project';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-picker',
  templateUrl: './project-picker.component.html',
  standalone: true,
  imports: [
    ModalComponent,
    NgFor,
    NgIf,
    TranslateModule,
    InlineSVGModule,
    FormsModule,
  ],
})
export class ProjectPickerComponent {
  @ViewChild(ModalComponent, { static: true }) private modal: ModalComponent;

  @Input() project: Project;

  public projects: Project[];
  public searchTerm: string = '';

  constructor(
    private projectDataBusService: ProjectDataBusService,
    private router: Router
  ) {
    this.projectDataBusService.shortListObservable.subscribe((list) => {
      this.projects = list;
    });
  }

  filteredItems() {
    return !this.searchTerm
      ? this.projects
      : this.projects.filter((item: Project) =>
          item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
  }

  public async pick() {
    this.modal.open();
  }

  public openProject(slug: string) {
    this.router.navigateByUrl('/projects/' + slug, { replaceUrl: true });
    this.modal.close();
  }
}
