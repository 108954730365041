<app-modal
  [title]="'project.list.new_update.title' | translate"
  #newUpdate
  class="new-update"
>
  <p class="modal-description">
    {{ 'project.list.new_update.description' | translate }}
  </p>
  <form [formGroup]="newUpdateForm" (submit)="createUpdate()">
    <app-form-group [form]="newUpdateForm" errorPath="option">
      <label>{{
        'project.detail.settings.users.create.type' | translate
      }}</label>

      <div class="radio-inline">
        <div class="radio" *ngFor="let option of radioOptions">
          <input
            type="radio"
            [id]="option.id"
            [value]="option.id"
            formControlName="optionType"
            [class.disabled]="customers?.length === 0"
            [attr.disabled]="customers?.length === 0 ? true : null"
          />
          <label [for]="option.id">{{ option.name | translate }}</label>
        </div>
      </div>
    </app-form-group>
    <app-form-group [form]="newUpdateForm" errorPath="entity">
      <label for="entity">
        <ng-container
          *ngIf="newUpdateForm.get('optionType').value === 'project-option'"
          >{{ 'project' | translate }}</ng-container
        >
        <ng-container
          *ngIf="
            newUpdateForm.get('optionType').value === 'organisation-option'
          "
          >{{ 'project' | translate }}</ng-container
        >
      </label>
      <div class="input-button-group">
        <select id="entity" formControlName="entity">
          <ng-container
            *ngIf="newUpdateForm.get('optionType').value === 'project-option'"
          >
            <option value="">
              {{ 'project.list.new_update.project.select' | translate }}
            </option>
            <option *ngFor="let project of projects" [value]="project.slug">
              {{ project.name }}
            </option>
          </ng-container>
          <ng-container
            *ngIf="
              newUpdateForm.get('optionType').value === 'organisation-option'
            "
          >
            <option value="">
              {{ 'customer_picker.select_company' | translate }}
            </option>
            <option *ngFor="let customer of customers" [value]="customer.slug">
              {{ customer.name }}
            </option>
          </ng-container>
        </select>
        <div class="input-button-group">
          <button class="btn">
            {{ 'project.list.new_update.confirm' | translate }}
          </button>
        </div>
      </div>
    </app-form-group>
  </form>
</app-modal>
