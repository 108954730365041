import { Customer } from '../interfaces/customer';
import { AccessControlList } from '../interfaces/access-control-list';
import { Project } from '../interfaces/project';
import { ProjectUser } from '../interfaces/project-user';
import { PermissionType } from '../enums/permission-type';

export class SecurityVoter {
  public static hasCustomers(acl: AccessControlList): boolean {
    return acl.customers.length > 0;
  }

  public static findCustomerPermission(user: ProjectUser, customer: Customer) {
    return user.customersProjectUser.find(
      (item) =>
        parseInt(item.customer.id as any) === parseInt(customer.id as any)
    );
  }

  public static isAccountAdmin(acl: AccessControlList): boolean {
    return acl.customers.some((item) =>
      [PermissionType.CREATE, PermissionType.EDIT].includes(item.permissionType)
    );
  }

  public static isProjectAdmin(
    acl: AccessControlList,
    project: Project
  ): boolean {
    return (
      SecurityVoter.getRoleForProject(acl, project) === PermissionType.CREATE
    );
  }

  public static isAccountAdminForCustomer(
    acl: AccessControlList,
    customer: Customer
  ): boolean {
    return acl.customers.some(
      (item) =>
        parseInt(item.customer.id as any) === parseInt(customer.id as any) ||
        item.customer['@id'] === customer['@id']
    );
  }

  public static isProjectAdminForProject(
    acl: AccessControlList,
    project: Project
  ) {
    return (
      SecurityVoter.isAccountAdminForCustomer(acl, project.customer) ||
      this.isProjectAdmin(acl, project)
    );
  }

  public static isManagerForProject(acl: AccessControlList, project: Project) {
    const role = SecurityVoter.getRoleForProject(acl, project);
    return role === PermissionType.CREATE || role === PermissionType.EDIT;
  }

  public static isViewerForProject(acl: AccessControlList, project: Project) {
    const role = SecurityVoter.getRoleForProject(acl, project);
    return role === 'view';
  }

  public static isManager(acl: AccessControlList) {
    return (
      SecurityVoter.hasCustomers(acl) ||
      acl.mappings.some((it) =>
        [PermissionType.CREATE, PermissionType.EDIT].includes(it.permissionType)
      )
    );
  }

  public static isProjectAdminForCustomer(
    acl: AccessControlList,
    customer: Customer
  ): boolean {
    return acl.mappings.some(
      (item) =>
        item.customer !== undefined &&
        parseInt(item.customer.id as any) === parseInt(customer.id as any)
    );
  }

  public static canViewProject(acl: AccessControlList, project: Project) {
    const role = SecurityVoter.getRoleForProject(acl, project);
    return (
      SecurityVoter.isAccountAdminForCustomer(acl, project.customer) ||
      role !== null
    );
  }

  public static hasCreateRole(acl: AccessControlList) {
    const mapping = acl.mappings.find(
      (item) => item.permissionType === PermissionType.CREATE
    );

    return mapping !== undefined;
  }

  public static canEditProject(acl: AccessControlList, project: Project) {
    const role = SecurityVoter.getRoleForProject(acl, project);
    return (
      SecurityVoter.isAccountAdminForCustomer(acl, project.customer) ||
      role === PermissionType.CREATE ||
      role === PermissionType.EDIT
    );
  }

  private static getRoleForProject(
    list: AccessControlList,
    project: Project
  ): string {
    if (list?.mappings == null) {
      return null;
    }
    let mapping = list.mappings.find((item) => item.project.id === project.id);

    if (undefined === mapping) {
      mapping = list.mappings.find((item) =>
        item.project.childrenProjects.some((child) => child.id === project.id)
      );
    }

    return mapping == undefined ? null : mapping.permissionType;
  }
}
