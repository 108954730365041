<form *ngIf="!!projectList" [formGroup]="form" (submit)="submit($event)">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1>{{ 'smart_reporting.settings.title' | translate }}</h1>
      <p>{{ 'smart_reporting.settings.description' | translate }}</p>
    </div>
    <div class="bouwapp-header__buttons" [appLoading]="formLoading">
      <button class="primary">
        <span>
          {{ 'smart_reporting.settings.form.save' | translate }}
        </span>
      </button>
    </div>
    <div *ngIf="formError" class="alert alert-danger">
      {{ 'smart_reporting.settings.form.save' | translate }}
    </div>
    <div *ngIf="formSubmitted" class="alert alert-success">
      {{ 'smart_reporting.settings.form.saved' | translate }}
    </div>
  </div>

  <div class="bouwapp-box smart-reporting__box smart-reporting__settings">
    <app-loader *ngIf="!projectList"></app-loader>
    <app-form-group [form]="form" formArrayName="projects">
      <div class="bouwapp-box__title">
        <h2>{{ 'smart_reporting.settings.form.projects' | translate }}</h2>
      </div>

      <div class="bouwapp-box__self">
        <div class="bouwapp-box__subtitle">
          <p>
            {{
              'smart_reporting.settings.form.projects.description' | translate
            }}
          </p>
        </div>

        <div
          class="checkbox"
          *ngFor="let project of projectList; let index = index"
        >
          <input
            type="checkbox"
            id="project{{ project.id }}"
            [formControlName]="index"
          />
          <label for="project{{ project.id }}">{{ project.name }}</label>
        </div>
      </div>
    </app-form-group>
  </div>
</form>
