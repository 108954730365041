<app-loader *ngIf="!projects"></app-loader>

<div class="container">
    <div class="bouwapp-header">
        <div class="bouwapp-header__text">
            <h1>{{ 'profile.export.title' | translate }}</h1>
            <p>{{ 'profile.export.description' | translate }}</p>
        </div>
        <div class="bouwapp-header__buttons">
            <button class="primary" (click)="download()">
                {{ 'profile.export.download' | translate }}
            </button>
        </div>
    </div>

    <form [formGroup]="form" class="bouwapp-group" *ngIf="projects">
        <div class="bouwapp-group__header">
            <h2>{{ 'profile.export.title' | translate }}</h2>
        </div>

        <div class="bouwapp-group__row">
            <div class="bouwapp-group__item">
                <app-form-group [form]="form" errorPath="project">
                    <label for="project">{{ 'profile.export.project' | translate }}</label>
                    <select id="project" formControlName="project">
                        <option [value]="null">
                            {{ 'profile.export.project.placeholder' | translate }}
                        </option>
                        <option *ngFor="let project of projects" [ngValue]="project">
                            {{ project.name }}
                        </option>
                    </select>
                </app-form-group>
            </div>
        </div>

        <div class="bouwapp-group__row">
            <a href="javascript:void(0);" (click)="togglePeriod()">
                {{ 'profile.export.period.' + (showPeriod ? 'remove' : 'add') | translate }}
            </a>
        </div>

        <div class="bouwapp-group__row two" *ngIf="showPeriod">
            <div class="bouwapp-group__item">
                <app-form-group [form]="form" errorPath="from">
                    <label>{{ 'profile.export.period.from' | translate }}</label>
                    <app-date-picker formControlName="from" [withTime]="true"></app-date-picker>
                </app-form-group>
            </div>
            <div class="bouwapp-group__item">
                <app-form-group [form]="form" errorPath="till">
                    <label>{{ 'profile.export.period.till' | translate }}</label>
                    <app-date-picker formControlName="till" [withTime]="true"></app-date-picker>
                </app-form-group>
            </div>
        </div>
    </form>
</div>