import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  NgZone,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { AttachmentHelper } from '../shared/attachment-helper';
import { Attachment } from '../interfaces/attachment';
import { debounceTime } from 'rxjs/operators';
import { Sortable } from '../enums/sortable';
import { DragulaService, DragulaModule } from 'ng2-dragula';
import { ModalComponent } from './modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { InputFileV2Component } from './input-file-v2.component';
import { ToggleComponent } from './toggle.component';
import { FormGroupComponent } from './form-group.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgFor, NgIf } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUrlPipe } from '../pipes/file-url.pipe';

type ChangeFn = (value: any) => void;

@Component({
  selector: 'app-multi-attachment-control',
  templateUrl: './multi-attachment-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiAttachmentControlComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    DragulaModule,
    FileUploadModule,
    NgFor,
    NgIf,
    InlineSVGModule,
    ModalComponent,
    ReactiveFormsModule,
    FormGroupComponent,
    ToggleComponent,
    InputFileV2Component,
    TranslateModule,
    FileUrlPipe,
  ],
})
export class MultiAttachmentControlComponent implements ControlValueAccessor {
  public form: FormGroup;
  public attachmentPreviews: Attachment[] = [];
  public removed: number[] = [];
  public oldPreview: Attachment = null;
  public activePreview: Attachment = null;
  public activeIndex: number;
  public Sortable = Sortable;
  public mode: string = 'create';
  public uploading: boolean = false;
  public isYoutube: boolean = false;

  private onChangeFn: ChangeFn;
  private onTouchedFn: ChangeFn;
  private maxSortOrder: number = 0;

  get attachments(): FormArray {
    return this.form.get('attachments') as FormArray;
  }

  @Input() public previewBasePath: string;
  @Input() public withDescription = true;
  @Input() public with360 = false;
  @Input() public withVideo = true;
  @Input() public type;
  @Input() public id;

  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @ViewChild('mirrorElement') mirrorElement: ElementRef;
  @ViewChild('mediaModal', { static: true }) mediaModal: ModalComponent;
  @ViewChild(InputFileV2Component) inputFile: InputFileV2Component;

  public videoForm: FormGroup;
  private videoUrlRegex =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

  public convertMessage = false;
  ready: boolean = false;
  @ViewChildren('file') fileElements: QueryList<ElementRef>;

  constructor(
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private dragulaService: DragulaService,
    private element: ElementRef,
    private ngZone: NgZone
  ) {
    this.createForm();

    this.dragulaService.createGroup(Sortable.ATTACHMENTS, {
      moves: (el, container, handle) => {
        return (
          handle.classList.contains('file__drag') ||
          handle.parentElement.classList.contains('file__drag') ||
          handle.parentElement.parentElement.classList.contains('file__drag')
        );
      },
      accepts: (el, target, source, sibling) => {
        if (!sibling) return false;
        return true;
      },
      direction: 'horizontal',
      mirrorContainer: this.element.nativeElement,
    });
  }

  confirm() {
    if (this.isYoutube && !this.activePreview.isUploaded) {
      this.activePreview.isUploaded = true;
      this.activePreview.sortOrder = this.attachments.controls
        .at(this.activeIndex)
        .get('sortOrder').value;
      this.attachmentPreviews.push(this.activePreview);
    }
    this.attachmentPreviews[this.activeIndex].title =
      this.attachments.controls[this.activeIndex].get('title').value;
    this.attachmentPreviews[this.activeIndex].is360 =
      this.attachments.controls[this.activeIndex].get('is360').value;
    this.mediaModal.close();
  }

  public cancel() {
    if (this.oldPreview) {
      const control = this.attachments.controls.at(this.activeIndex);
      control.reset();
      control.patchValue(this.oldPreview);
    } else {
      this.attachments.removeAt(this.activeIndex);
    }

    if (this.mediaModal.isOpen) this.mediaModal.close();
  }

  fileDropped(files: FileList) {
    this.inputFile.upload(Array.from(files));
  }

  uploadError(event: Attachment) {
    const index = this.attachmentPreviews.findIndex(
      (attachment) =>
        attachment.identifier === event.identifier && !attachment.isUploaded
    );
    if (index !== -1) {
      this.attachmentPreviews[index] = event;
      this.attachments.controls.at(index).reset();
      this.attachments.controls.at(index).patchValue(event);
    } else {
      this.attachmentPreviews.push(event);
      this.createAttachmentControl(event);
    }
  }

  addAttachment(event: Attachment) {
    this.attachmentPreviews.push(event);
    this.createAttachmentControl(event);
  }

  updateAttachment(event: Attachment) {
    const index = this.attachmentPreviews.findIndex(
      (attachment) => attachment.identifier === event.identifier
    );
    this.attachmentPreviews[index].isUploaded = true;
    this.attachmentPreviews[index].filePath = event.filePath;
    this.attachments.controls.at(index).patchValue(event);
  }

  ngOnInit() {
    this.mediaModal.onClose.subscribe((event) => {
      if (event) {
        this.cancel();
      }
      this.attachments.controls[this.activeIndex]?.markAsPristine();
      this.activeIndex = undefined;
      this.activePreview = null;
      this.oldPreview = null;
      this.isYoutube = false;
      this.videoForm.reset();
      this.emitChange();
    });
  }

  public removeAttachment(index: number = null) {
    this.attachments.removeAt(index || this.activeIndex);
    this.attachmentPreviews.splice(index || this.activeIndex, 1);
    this.mediaModal.close();

    this.removed.push(this.activeIndex);
    this.emitChange();

    this.convertMessage = false;
    this.checkConvertMessage();
  }

  public checkConvertMessage() {
    this.attachmentPreviews.forEach((attachment, _index) => {
      if (
        (this.isVideo(attachment) || this.isPdf(attachment)) &&
        attachment.isConverted != 1 &&
        this.removed.indexOf(_index) == -1
      ) {
        this.convertMessage = true;
      }
    });
  }

  public isRemoved(index: number) {
    return this.removed.includes(index);
  }

  public getUrl(attachment: Attachment, small: boolean = false): string {
    return attachment.preview
      ? attachment.preview
      : small
      ? attachment.thumbnailPathThumbnails?.medium ??
        attachment.filePathThumbnails?.small
      : attachment.thumbnailPathThumbnails?.medium ??
        attachment.filePathThumbnails?.medium;
  }

  public getVideoPoster(attachment: Attachment) {
    if (attachment.thumbnailPathThumbnails) {
      return this.sanitizer.bypassSecurityTrustUrl(
        attachment.filePathThumbnails.small
      );
    } else {
      return '';
    }
  }

  public getPreviewImage(
    attachment: Attachment,
    small: boolean = false
  ): SafeStyle | string {
    if (this.isVideo(attachment)) {
      if (attachment.thumbnailPathThumbnails) {
        return 'url("' + attachment.thumbnailPathThumbnails.small + '")';
      } else {
        return 'url("/assets/img/icons/type-video.svg")';
      }
    } else {
      const url =
        attachment.videoId != null
          ? AttachmentHelper.getVideoImageForId(attachment.videoId)
          : this.getUrl(attachment, small);

      return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
    }
  }

  public getPreviewVideo(attachment: Attachment): SafeUrl | string {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      attachment.preview
        ? attachment.preview
        : attachment.filePathThumbnails.medium
        ? attachment.filePathThumbnails.medium
        : attachment.filePath
    );
  }

  openMediaModal(attachment: Attachment, index: number): void {
    if (attachment.isUploaded) {
      this.isYoutube = this.isYoutubeVideo(attachment);
      this.oldPreview = attachment;
      this.activePreview = attachment;
      this.activeIndex = index;
      this.ngZone.run(() => {
        this.mediaModal.open();
      });
    }
  }

  openYoutubeModal() {
    this.isYoutube = true;
    this.createAttachmentControl();
    this.activeIndex = this.attachments.length - 1;
    this.activePreview = null;
    this.ngZone.run(() => {
      this.mediaModal.open();
    });
  }

  isPdf(attachment: Attachment) {
    return /\.pdf$/.test(attachment.filePath);
  }

  isYoutubeVideo(attachment: Attachment) {
    return attachment.videoId ? true : false;
  }

  isVideo(attachment: Attachment) {
    return (
      /\.mp4$/i.test(attachment.filePath) ||
      /\blob:$/i.test(attachment.filePath) ||
      /\.mov$/i.test(attachment.filePath)
    );
  }

  isImage(attachment: Attachment) {
    return (
      /\.jpg$/i.test(attachment.filePath) ||
      /\.jpeg:$/i.test(attachment.filePath) ||
      /\.png$/i.test(attachment.filePath)
    );
  }

  public writeValue(attachments: Attachment[]): void {
    for (const attachment of attachments) {
      // set video id as name and path for reference
      if (attachment.videoId != null) {
        attachment.filePath = attachment.videoId;
        attachment.fileName = attachment.videoId;
      }

      this.createAttachmentControl(attachment);

      if (attachment.sortOrder > this.maxSortOrder) {
        this.maxSortOrder = attachment.sortOrder;
      }
    }

    this.attachmentPreviews = structuredClone(attachments);

    this.attachmentPreviews.forEach((attachment) => {
      attachment.isUploaded = true;
      if (
        (this.isVideo(attachment) || this.isPdf(attachment)) &&
        attachment.isConverted != 1
      )
        this.convertMessage = true;
    });
  }

  public registerOnChange(fn: ChangeFn): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: ChangeFn): void {
    this.onTouchedFn = fn;
  }

  private createAttachmentControl(attachment?: Attachment): void {
    this.maxSortOrder++;

    const group: FormGroup = this.fb.group({
      '@id': [''], // for api platform: keep reference
      id: [''],
      title: [''],
      videoId: [''],
      fileName: ['', Validators.required],
      filePath: ['', Validators.required],
      is360: [false],
      sortOrder: [this.maxSortOrder],
      identifier: [''],
    });
    if (attachment) {
      let preview = this.attachmentPreviews.find(
        (a) => a.identifier === attachment.identifier
      );
      if (preview) preview.sortOrder = this.maxSortOrder;
      group.patchValue(attachment);
    }

    group.valueChanges
      .pipe(debounceTime(20))
      .subscribe((_) => this.emitChange());

    this.attachments.push(group);
    this.emitChange();
    this.checkConvertMessage();
  }

  private emitChange() {
    const data = { ...this.form.value };

    for (const attachment of data.attachments) {
      delete attachment.preview;

      if (attachment.videoId != null && attachment.videoId !== '') {
        // file path is for reference only in case of a video, so don't fill
        delete attachment.filePath;
        delete attachment.fileName;
      } else {
        delete attachment.videoId;
      }
    }

    const object = { ...data.attachments };

    for (const removedIndex of this.removed) {
      delete object[removedIndex];
    }

    this.onTouchedFn(object);
    this.onChangeFn(object);
  }

  public supports360(): boolean {
    if (!this.with360) return false;
    if (this.activePreview) {
      return this.isImage(this.activePreview);
    } else {
      return false;
    }
  }

  private createForm() {
    this.form = this.fb.group({
      attachments: this.fb.array([]),
    });

    this.videoForm = this.fb.group({
      youtubeUrl: [
        null,
        [Validators.required, Validators.pattern(this.videoUrlRegex)],
      ],
    });

    this.videoForm.get('youtubeUrl').valueChanges.subscribe((value) => {
      if (this.videoForm.get('youtubeUrl').valid && value != null) {
        const videoId = value.match(this.videoUrlRegex)[1];
        const uniqueString = videoId + Date.now();
        this.attachments.controls[this.activeIndex].patchValue({
          videoId: videoId,
          identifier: uniqueString,
        });
        this.activePreview = {
          videoId: videoId,
          preview: videoId,
          identifier: uniqueString,
        } as Attachment;
      } else {
        this.activePreview = null;
      }
    });
  }

  canSave(): boolean {
    const attachment = this.attachments.controls[this.activeIndex];
    if (this.isYoutube) {
      if (this.activePreview?.isUploaded) return attachment?.dirty;
      else return this.videoForm?.valid;
    }
    return attachment?.dirty && attachment?.valid;
  }

  updateAttachmentsSortOrder(event: Attachment[]) {
    event.map((a: Attachment, index) => {
      a.sortOrder = index;
    });

    this.attachments.reset();
    this.attachments.patchValue(event);
  }

  ngOnDestroy() {
    this.dragulaService.destroy(Sortable.ATTACHMENTS);
  }
}
