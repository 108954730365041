<div [ngClass]="{ container: !project }">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <div class="bouwapp-header__text__title">
        <h1>{{ getPrefix() + '.title' | translate }}</h1>
        <ng-container *appVersion="2">
          <app-customer-picker
            *ngIf="!project()"
            (selectedCustomer)="changeCustomer($event)"
          ></app-customer-picker>
        </ng-container>
      </div>
      <ng-container *appVersion="1">
        <p *ngIf="!loading() && customer">{{ customer.name }}</p>
      </ng-container>
      <p *ngIf="project() && !customer">
        {{ 'project.detail.settings.users.description' | translate }}
        <br />
        <strong>{{ project()?.name }}</strong>
      </p>
    </div>
    <div class="bouwapp-header__buttons">
      <a (click)="openModal()" class="primary">
        <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
        <span>{{ getPrefix() + '.new' | translate }}</span>
      </a>
    </div>
  </div>
</div>

<section class="projects settings create">
  <app-modal
    [title]="
      getPrefix() + (editing ? '.edit' : '.create') + '.title' | translate
    "
    #create
    (onClose)="reset()"
  >
    <p *ngIf="!editing">
      {{ getPrefix() + '.create.description' | translate }}
    </p>

    <ng-container *appVersion="1">
      <form [formGroup]="form" [hidden]="showCreateProjectUser">
        <app-form-group *ngIf="editing">
          <label>{{
            'project.detail.settings.users.edit.email' | translate
          }}</label>
          <div>{{ editingMapping?.user.email }}</div>
        </app-form-group>

        <app-form-group [form]="form" *ngIf="!editing" errorPath="email">
          <label for="email">{{
            'project.detail.settings.users.create.email' | translate
          }}</label>
          <input
            type="email"
            id="email"
            class="input input--m"
            formControlName="email"
          />
        </app-form-group>

        <app-form-group [form]="form" errorPath="permissionType">
          <label>{{
            'project.detail.settings.users.create.type' | translate
          }}</label>

          <div class="radio-inline">
            <div class="radio" *ngFor="let option of radios">
              <ng-container *ngIf="!option.hidden">
                <input
                  type="radio"
                  [value]="option.value"
                  formControlName="permissionType"
                  [id]="option.id"
                />
                <label [for]="option.id">{{ option.label | translate }}</label>
              </ng-container>
            </div>
          </div>
        </app-form-group>

        <app-form-group
          [form]="form"
          errorPath="permissionType"
          *ngIf="
            customer && form.get('permissionType').value === 'project-create'
          "
        >
          <label>{{ 'project.list.new_update.project' | translate }}</label>
          <ng-select
            [isOpen]="true"
            [placeholder]="'profile.export.project.placeholder' | translate"
            [multiple]="true"
            [closeOnSelect]="false"
            [searchable]="false"
            id="projects"
            formControlName="projects"
          >
            <ng-option
              *ngFor="let project of customer.projects"
              [value]="project.id"
            >
              {{ project.name }}
            </ng-option>
          </ng-select>
        </app-form-group>
        <br />

        <button
          type="button"
          class="btn btn-primary"
          (click)="check()"
          [appLoading]="formLoading"
        >
          {{ getFormActionButtonText() | translate }}
        </button>
      </form>
    </ng-container>

    <ng-container *appVersion="2">
      <form [formGroup]="form" [hidden]="showCreateProjectUser">
        <app-form-group *ngIf="editing">
          <label>{{
            'project.detail.settings.users.edit.email' | translate
          }}</label>
          <div>{{ editingMapping?.user.email }}</div>
        </app-form-group>

        <app-form-group [form]="form" *ngIf="!editing" errorPath="email">
          <label for="email">{{
            'project.detail.settings.users.create.email' | translate
          }}</label>
          <input
            type="email"
            id="email"
            class="input input--m"
            formControlName="email"
          />
        </app-form-group>

        <app-form-group [form]="form" errorPath="permissionType">
          <label>{{
            'project.detail.settings.users.create.type' | translate
          }}</label>

          <div class="radio-inline">
            <div class="radio" *ngFor="let option of radios">
              <ng-container *ngIf="!option.hidden">
                <input
                  type="radio"
                  [value]="option.value"
                  formControlName="permissionType"
                  [id]="option.id"
                />
                <label [for]="option.id">{{ option.label | translate }}</label>
              </ng-container>
            </div>
          </div>
        </app-form-group>

        <div
          *ngIf="
            customer && form.get('permissionType').value === 'project-create'
          "
          class="permissions-form"
        >
          <ng-container *ngFor="let permission of projects() | keyvalue">
            <div *ngFor="let project of permission.value; let i = index">
              <span>{{ getProjectName(project) }}</span>
              <select
                [value]="permission.key"
                (change)="updateProjectPermission($event, project)"
              >
                <option value="create">
                  {{
                    'project.detail.settings.users.table.type.create'
                      | translate
                  }}
                </option>
                <option value="edit">
                  {{
                    'project.detail.settings.users.table.type.edit' | translate
                  }}
                </option>
                <option value="view">
                  {{
                    'project.detail.settings.users.table.type.view' | translate
                  }}
                </option>
              </select>
              <button
                type="button"
                (click)="removeProject(i, permission.key)"
                class="button button--icon bg--red--hover button--ghost"
              >
                <span inlineSVG="/assets/img/icons/trash.svg"></span>
              </button>
            </div>
          </ng-container>
          <div
            class="permissions-form__add"
            *ngIf="customer?.projects"
            [class.has-error]="showProjectError"
          >
            <label>
              {{ 'project.detail.settings.users.project.add' | translate }}
            </label>
            <select (change)="showProjectError = false" #projectList>
              <option value="" disabled selected>
                {{
                  'project.detail.settings.users.project.add.placeholder'
                    | translate
                }}
              </option>
              <option
                *ngFor="let project of customer.projects"
                [value]="project.id"
              >
                {{ project.name }}
              </option>
            </select>
            <button
              type="button"
              (click)="addProject(projectList.value)"
              class="button button--m button--outlined bg--blue--hover"
            >
              {{
                'project.detail.settings.users.project.add.button' | translate
              }}
            </button>
            <div *ngIf="showProjectError" class="errors">
              {{
                'project.detail.settings.users.project.add.error' | translate
              }}
            </div>
          </div>
        </div>
        <br />

        <button
          type="button"
          class="btn btn-primary permissions-form__button"
          (click)="check()"
          [appLoading]="formLoading"
        >
          {{ getFormActionButtonText() | translate }}
        </button>
      </form>
    </ng-container>

    <app-create-user
      *ngIf="showCreateProjectUser"
      (userCreated)="handleUserCreated($event)"
      [userEmail]="form.get('email').value"
    ></app-create-user>
  </app-modal>
</section>

<app-confirm-delete
  #confirmDelete
  (confirmed)="triggerDelete($event)"
></app-confirm-delete>
