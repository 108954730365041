import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { Component } from '@angular/core';
import { ProjectDataBusService } from '../../../../../services/project-data-bus.service';
import { Project } from '../../../../../interfaces/project';
import { Module } from '../../../../../enums/module';
import { ProjectService } from '../../../../../services/project.service';
import { AccessService } from '../../../../../services/access.service';
import { AccessControlList } from '../../../../../interfaces/access-control-list';
import { SecurityVoter } from '../../../../../security/security-voter';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingDirective } from '../../../../../directives/loading.directive';
import { FormGroupComponent } from '../../../../../components/form-group.component';
import { LoaderComponent } from '../../../../../components/loader.component';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
  selector: 'app-smart-reporting-settings',
  templateUrl: './settings.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    ReactiveFormsModule,
    NgClass,
    FormGroupComponent,
    NgFor,
    LoadingDirective,
    TranslateModule,
  ],
})
export class SmartReportingSettingsComponent {
  public form: FormGroup;
  public projectList: Project[];
  public formLoading = false;
  public formError = false;
  public formSubmitted = false;
  public list: AccessControlList;

  constructor(
    private fb: FormBuilder,
    private projectDataBusService: ProjectDataBusService,
    private projectService: ProjectService,
    private accessService: AccessService
  ) {
    this.createForm();

    this.accessService.accessControlList.subscribe((list) => {
      this.list = list;

      this.projectDataBusService.shortListObservable.subscribe((list) => {
        this.updateProjectList(list);
      });
    });
  }

  public async submit($event) {
    $event.preventDefault();

    if (this.formLoading || !this.form.valid) {
      return;
    }

    const currentValues = this.form.value.projects;
    const updates: Project[] = [];
    currentValues.forEach((value, index) => {
      value = !!value;
      const project = this.projectList[index];

      if (!project.enabledModules) {
        project.enabledModules = [];
      }

      const previous = project.enabledModules.includes(Module.SMART_REPORTING);

      if (previous !== value) {
        if (!value) {
          project.enabledModules = project.enabledModules.filter(
            (i) => i !== Module.SMART_REPORTING
          );
        } else {
          project.enabledModules.push(Module.SMART_REPORTING);
        }

        updates.push(project);
      }
    });

    try {
      this.formError = false;
      this.formLoading = true;
      this.formSubmitted = false;

      await Promise.all(
        updates.map((p) => this.projectService.updateModules(p.slug, p))
      );

      this.formSubmitted = true;
    } catch (error) {
      console.error(error);
      this.formError = true;
    } finally {
      this.formLoading = false;
    }
  }

  private createForm() {
    this.form = this.fb.group({
      projects: this.fb.array([]),
    });
  }

  private updateProjectList(list: Project[]) {
    if (list === null) {
      return;
    }

    list = list.filter(
      (l) =>
        SecurityVoter.isProjectAdminForProject(this.list, l) &&
        l.customer.enabledModules &&
        l.customer.enabledModules.includes(Module.SMART_REPORTING)
    );

    const formProjects = this.form.get('projects') as FormArray;
    formProjects.controls = [];

    list.forEach((project) =>
      formProjects.push(
        this.fb.control(
          project.enabledModules &&
            project.enabledModules.includes(Module.SMART_REPORTING)
        )
      )
    );

    this.projectList = list;
  }
}
