import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Upload } from '../interfaces/upload';

@Injectable()
export class UploadService {
  /**
   * @type {string}
   */
  static base = '/uploads';
  //private account = 'bouwapptest'

  constructor(private http: HttpClient) {}

  /**
   * @param {string} type
   * @param {File} file
   * @returns {Promise<Upload>}
   */
  public async upload(type: string, file: File): Promise<Upload> {
    const uploadFormData: FormData = new FormData();
    uploadFormData.append('file', file, file.name);

    let headers = new HttpHeaders();
    headers = headers.append('X-File-Upload', 'true');

    return this.http
      .post<Upload>(
        environment.apiUrl + UploadService.base + '/' + type,
        uploadFormData,
        {
          headers,
        }
      )
      .toPromise();
  }

  public getSASToken(type: string, filename: string): Promise<any> {
    return this.http
      .get(
        environment.apiUrl +
          UploadService.base +
          '/' +
          type +
          '/token?filename=' +
          filename
      )
      .toPromise();
  }
}
