import { Component, Input } from '@angular/core';
import * as striptags from 'striptags';
import { decode } from 'html-entities';

@Component({
    selector: 'app-character-count',
    templateUrl: 'character-count.component.html',
    standalone: true
})
export class CharacterCountComponent {
  @Input() public text: string;
  @Input() public stripHtml = true;
  @Input() public limit: number;

  get count() {
    const value = this.text;

    if (value != null) {
      if (this.stripHtml) {
        return decode(striptags(value)).length;
      } else {
        return value.length;
      }
    } else {
      return 0;
    }
  }
}
