import { Pipe, PipeTransform } from '@angular/core';
import { constants } from '../../constants';

@Pipe({
  name: 'fileUrl',
  standalone: true,
})
export class FileUrlPipe implements PipeTransform {
  constructor() {}

  transform(file: string): string {
    if (!file.startsWith('/')) {
      file = '/' + file;
    }

    if (Object.hasOwn(constants.fileHashes, file)) {
      return `${file}?h=${constants.fileHashes[file]}`;
    }

    return file;
  }
}
