import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MapComponent, NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MapboxService } from '../../services/mapbox.service';
import { MapMouseEvent } from 'mapbox-gl';
import * as mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { environment } from 'src/environments/environment';
import { Project } from 'src/app/interfaces/project';
import { Update } from 'src/app/interfaces/update';

@Component({
  selector: 'app-update-location',
  templateUrl: 'update-location.component.html',
  standalone: true,
  imports: [NgxMapboxGLModule]
})
export class UpdateLocationComponent implements AfterViewInit {
  @Input() project: Project;
  @Input() update: Update;
  @Input() noDefault: boolean = false;
  @Output() locationPicked: EventEmitter<[number, number]> = new EventEmitter<
    [number, number]
  >();
  @ViewChild(MapComponent) public mapComponent: MapComponent;

  public locationLat: number;
  public locationLong: number;
  public marker: mapboxgl.Marker;

  constructor(
    private mapboxService: MapboxService,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.locationLat =
      this.update?.locationLat ?? this.project?.locationLat ?? 52.3676;
    this.locationLong =
      this.update?.locationLong ?? this.project?.locationLong ?? 4.9041;
    this.cd.detectChanges();
  }

  private placeMarker(location: number[]) {
    if (!this.marker) {
      this.marker = this.mapboxService.drawMarker(
        this.mapComponent,
        location,
        null,
        {},
        31,
        40
      );
    } else {
      this.marker.setLngLat([location[1], location[0]]);
    }
  }

  public mapClick($event: MapMouseEvent) {
    if ($event.lngLat) {
      this.placeMarker([$event.lngLat.lat, $event.lngLat.lng]);
      this.locationPicked.emit([$event.lngLat.lat, $event.lngLat.lng]);
    }
  }

  public async initMap() {
    if (!this.noDefault) {
      this.locationPicked.emit([this.locationLat, this.locationLong]);

      this.placeMarker([this.locationLat, this.locationLong]);
    }

    const geocoder = new MapboxGeocoder({
      accessToken: environment.mapboxToken,
      mapboxgl: mapboxgl,
      marker: false,
    });
    geocoder.on('result', (event) => {
      const locationPicked = event.result.center.reverse();
      this.placeMarker(locationPicked);
      this.locationPicked.emit(locationPicked);

      this.mapboxService.setLocation(this.mapComponent, locationPicked);
    });

    this.mapComponent.mapInstance.addControl(geocoder, 'top-left');
    this.mapComponent.mapInstance.panTo([this.locationLong, this.locationLat]);
  }
}
