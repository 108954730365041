import {
  Component,
  forwardRef,
  QueryList,
  ContentChildren,
  AfterViewInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { SwitchOptionComponent } from './switch-option.component';

@Component({
    selector: 'app-switch',
    templateUrl: 'switch.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SwitchComponent),
            multi: true,
        },
    ],
    standalone: true
})
export class SwitchComponent implements ControlValueAccessor, AfterViewInit {
  @ContentChildren(SwitchOptionComponent, { descendants: true })
  switchOptions: QueryList<SwitchOptionComponent>;

  private value: string;

  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  /**
   * @param value
   */
  writeValue(value: string): void {
    this.updateValue(value);
  }

  /**
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /**
   * @param value
   * @returns {void}
   */
  updateValue(value: string) {
    this.value = value;

    if (this.switchOptions) {
      this.switchOptions.forEach((item: SwitchOptionComponent) => {
        item.switchComponent = this;
        item.setValue(value);
      });

      this.propagateChange(value);
      this.propagateTouch(value);
    }
  }

  /**
   * @returns {void}
   */
  ngAfterViewInit(): void {
    setTimeout(() => this.updateValue(this.value)); // prevent angular complaining about it because it happens in change detection
  }
}
