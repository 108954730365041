import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Project } from '../interfaces/project';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { ProjectService } from './project.service';
import { LocaleService } from './locale.service';

interface ExportParams {
  from: Date;
  till: Date;
  project: Project;
  showPeriod: boolean;
}

@Injectable()
export class ExportService {
  constructor(
    private httpClient: HttpClient,
    private localeService: LocaleService
  ) {}

  public async export(data: ExportParams) {
    const period = data.showPeriod
      ? `&from=${moment(data.from)
          .format()
          .replace(/\+/g, '%2B')}&till=${moment(data.till)
          .format()
          .replace(/\+/g, '%2B')}`
      : '';

    const tokenResponse: { token: string } = await this.httpClient
      .post<{ token: string }>(`${environment.apiUrl}/project-export-tokens`, {
        project: `/api${ProjectService.base}/${data.project.slug}`,
      })
      .toPromise();
    const token = tokenResponse.token;

    window.location.href = `${environment.apiUrl}/projects/${
      data.project.slug
    }/export?token=${encodeURIComponent(token)}${period}`;
  }
}
