import { Component, effect } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorService } from '../../../services/error.service';
import { CustomerService } from '../../../services/customer.service';
import { Customer } from '../../../interfaces/customer';
import { ProjectUser } from 'src/app/interfaces/project-user';
import { ProjectUserService } from 'src/app/services/project-user.service';
import { UserService } from 'src/app/services/user.service';
import { Project } from 'src/app/interfaces/project';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorMessageComponent } from '../../../components/error-message.component';
import { SuccessMessageComponent } from '../../../components/success-message.component';
import { ListUsersComponent } from '../../../components/manage-users/list-users.component';
import { ManageUsersComponent } from '../../../components/manage-users/manage-users.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ThemeService } from 'src/app/services/theme.service';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Sort } from 'src/app/interfaces/sort';
import { VersionDirective } from 'src/app/directives/version.directive';
import { UserRolesType } from 'src/app/enums/user-roles-type';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { LoaderComponent } from 'src/app/components/loader.component';

@Component({
  selector: 'app-user-admin',
  templateUrl: 'user-admin.component.html',
  standalone: true,
  imports: [
    ManageUsersComponent,
    ListUsersComponent,
    SuccessMessageComponent,
    ErrorMessageComponent,
    TranslateModule,
    NgIf,
    VersionDirective,
    NgFor,
    NgClass,
    ReactiveFormsModule,
    LoaderComponent,
  ],
})
export class UserAdminComponent {
  public formSaved = false;
  public customer: Customer;
  public projects: Project[];

  public inviteSaved: boolean = false;
  public cannotSave: boolean = false;
  public editSaved: boolean = false;
  public formLoading: boolean = false;
  public deleted: boolean = false;
  public loading: boolean = false;
  public project: Project;

  public projectUsers: ProjectUser[] = [];
  public editingMapping: ProjectUser;

  public showCreateProjectUser = false;
  public projectUser: ProjectUser;
  public version: number;
  public roleCounts: any;
  public filterForm: FormGroup;
  private search = null;
  private roles = null;
  public sortItems: Sort[] = [
    {
      field: 'projectRole',
      direction: 'desc',
      label: 'project.detail.settings.users.sort.direction.role.descending',
    },
    {
      field: 'projectRole',
      direction: 'asc',
      label: 'project.detail.settings.users.sort.direction.role.ascending',
    },
    {
      field: 'lastName',
      direction: 'desc',
      label: 'project.detail.settings.users.sort.direction.name.descending',
    },
    {
      field: 'lastName',
      direction: 'asc',
      label: 'project.detail.settings.users.sort.direction.name.ascending',
    },
    {
      field: 'lastEvent',
      direction: 'desc',
      label: 'project.detail.settings.users.sort.direction.event.descending',
    },
    {
      field: 'lastEvent',
      direction: 'asc',
      label: 'project.detail.settings.users.sort.direction.event.ascending',
    },
  ];
  private sort: Sort = this.sortItems[0];

  constructor(
    private errorService: ErrorService,
    private projectUserService: ProjectUserService,
    private userService: UserService,
    private customerService: CustomerService,
    private projectDataBusService: ProjectDataBusService,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private formBuilder: FormBuilder
  ) {
    this.activatedRoute.params.subscribe((params) => {
      if (params['id'] != null) {
        this.loadCustomer(params['id']);
      }
    });

    this.activatedRoute.parent?.params.subscribe((params) => {
      if (!params.slug) {
        this.project = null;
      } else {
        this.projectDataBusService.projectObservable.subscribe((project) => {
          this.project = project;
          this.loadUsers();
        });
      }
    });

    this.createFilterForm();
  }

  async loadCustomer(id: number): Promise<void> {
    this.loading = true;
    this.customer = await this.customerService.fetch(id);

    this.loadUsers();
  }

  async ngOnInit() {
    this.version = await this.themeService.getVersion();
  }

  private createFilterForm() {
    const roles = this.formBuilder.array(
      Object.values(UserRolesType).map((role) => {
        return this.formBuilder.control(true);
      })
    );

    this.filterForm = this.formBuilder.group({
      sort: [this.sortItems[0], Validators.required],
      customer: [''],
      search: [''],
      roles,
      customers: this.formBuilder.array([]),
    });

    this.filterForm.get('sort').setValue(this.sort);
    this.filterForm.valueChanges.subscribe((value) => {
      this.updateFilter();
    });
  }

  updateFilter() {
    this.sort = this.filterForm.get('sort').value as Sort;
    this.search = this.filterForm.get('search').value;
    this.roles = this.filterForm
      .get('roles')
      .value.map((item, index) => (!item ? null : this.allRoles[index]))
      .filter((it) => !!it);

    this.loadUsers();
  }

  private async loadUsers(): Promise<void> {
    try {
      this.loading = true;

      if (this.project) {
        await this.projectUserService
          .fetchListForProject(this.project, this.sort, this.search, this.roles)
          .then((result) => {
            this.projectUsers = result;
          });
      } else {
        if (!this.customer) {
          return;
        }
        await this.projectUserService
          .fetchListForCustomer(
            this.customer,
            this.sort,
            this.search,
            this.roles
          )
          .then((result) => {
            if (this.version === 1) {
              this.projectUsers = result.filter(
                (item) =>
                  item.sortedPermissions.account_admin.length > 0 ||
                  item.sortedPermissions.project_admin.length > 0
              );
            } else {
              this.projectUsers = result;
            }
          });
      }
    } catch (error) {
      this.errorService.logError(error);
    } finally {
      this.loading = false;
    }
  }

  public async create(data: {
    projectUser: ProjectUser;
    formData: any;
  }): Promise<void> {
    this.cannotSave = false;
    this.inviteSaved = false;

    try {
      await this.userService.inviteMultiple(data.projectUser, data.formData);
    } catch (e) {
      this.cannotSave = true;
    }

    if (!this.cannotSave) {
      this.inviteSaved = true;
    }

    this.loadUsers();
  }

  public async update(data: { projectUser: ProjectUser; formData: any }) {
    this.cannotSave = false;
    this.editSaved = false;

    try {
      await this.userService.updateInviteMultiple(
        data.projectUser,
        data.formData
      );
    } catch (error) {
      console.error(error);
      this.cannotSave = true;
    } finally {
      if (!this.cannotSave) {
        this.editSaved = true;
      }

      this.formLoading = false;
    }
    this.loadUsers();
  }

  public async getRoleCount() {
    this.roleCounts = [];
  }

  get allRoles() {
    return Object.values(UserRolesType);
  }

  public async delete(projectUser: ProjectUser) {
    this.deleted = false;
    if (this.project) {
      for (const role in projectUser.sortedPermissions) {
        if (Object.values(UserRolesType).includes(role as UserRolesType)) {
          const permissions =
            projectUser.sortedPermissions[role as UserRolesType];

          if (permissions) {
            const deletePromises = permissions.map((permission) =>
              this.userService.deletePermission(permission.id)
            );
            await Promise.all(deletePromises);
          }
        }
      }
    } else {
      await this.userService.deletePermissions(projectUser, this.customer);
    }
    this.deleted = true;
    this.loadUsers();
  }

  public changeCustomer(customer: Customer) {
    this.loadCustomer(customer.id);
    this.loadUsers();
  }
}
