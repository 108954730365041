import { AbstractControl, ValidationErrors } from '@angular/forms';

export function passwordRequirementsValidator(
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value || '';

  const hasMinLength = value.length >= 14;
  const hasLowercase = /[a-z]/.test(value);
  const hasUppercase = /[A-Z]/.test(value);
  const hasNumber = /[0-9]/.test(value);
  const hasSpecialChar = /[^a-zA-Z0-9]/.test(value);

  const optionalConditions = [
    hasLowercase,
    hasUppercase,
    hasNumber,
    hasSpecialChar,
  ];
  const metOptionalConditions = optionalConditions.filter(
    (condition) => condition
  ).length;

  const passwordValid = hasMinLength && metOptionalConditions >= 3;

  return passwordValid
    ? null
    : {
        minLength: !hasMinLength,
        lowercase: !hasLowercase,
        uppercase: !hasUppercase,
        number: !hasNumber,
        specialChar: !hasSpecialChar,
        passwordValid: passwordValid,
      };
}
