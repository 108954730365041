import {
  Component,
  effect,
  ElementRef,
  OnInit,
  viewChild,
  ViewChild,
} from '@angular/core';
import { UserDataService } from '../services/user-data.service';
import { User } from '../interfaces/user';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
} from '@angular/router';
import { TicketService } from '../services/ticket.service';
import { Customer } from '../interfaces/customer';
import { CustomerPickerComponent } from './customer-picker.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModuleService } from '../services/module.service';
import { Module } from '../enums/module';
import { SmartReportingService } from '../services/smart-reporting.service';
import { AccessService } from '../services/access.service';
import { AuthService } from '../services/auth.service';
import { ProjectDataBusService } from '../services/project-data-bus.service';
import { AccessControlList } from '../interfaces/access-control-list';
import { Project } from '../interfaces/project';
import { TranslateModule } from '@ngx-translate/core';
import { AccessDirective } from '../directives/access.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { platform, PlatformService } from '../services/platform.service';
import { VersionDirective } from '../directives/version.directive';
import { ProjectPickerComponent } from './project-picker.component';
import { ThemeService } from '../services/theme.service';
import { ProductFruitsService } from '../services/product-fruits.service';
import { SecurityVoter } from '../security/security-voter';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    VersionDirective,
    RouterLink,
    InlineSVGModule,
    NgClass,
    AccessDirective,
    CustomerPickerComponent,
    ProjectPickerComponent,
    TranslateModule,
  ],
})
export class HeaderComponent implements OnInit {
  private readonly productFruitLauncher = viewChild<ElementRef>(
    'productFruitLauncher'
  );

  public withProjectPermissions: boolean = false;
  public authenticated: boolean = false;
  public showTwoFactorNotice: boolean = false;
  public user: User;
  public customers: Customer[];
  public viewAllOrganisations: boolean = false;
  public current: {
    name: string;
    parent?: string;
  } = { name: '' };
  public currentOnProjects: string;
  public acl: AccessControlList;
  public navState: boolean = false;
  public openTickets: number;
  public openNotices: number;
  public logo: SafeResourceUrl;
  private readonly customerPicker = viewChild<CustomerPickerComponent>(
    CustomerPickerComponent
  );
  private readonly projectPicker = viewChild<ProjectPickerComponent>(
    ProjectPickerComponent
  );
  public hasSmartReportingModule: boolean;
  public Module = Module;
  public loading: boolean = false;
  public avatar: SafeResourceUrl;
  public currentProject: Project;
  public hasThemeOption: boolean = false;
  public hasSettingsOption: boolean = false;
  public version: number = 1;
  public userMenuVisible: boolean = false;
  public newsCount: number = 0;
  public isAccountAdmin: boolean = false;

  constructor(
    private userDataService: UserDataService,
    private authService: AuthService,
    private router: Router,
    private ticketService: TicketService,
    private moduleService: ModuleService,
    private themeService: ThemeService,
    private smartReportingService: SmartReportingService,
    private accessService: AccessService,
    private projectDataBusService: ProjectDataBusService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private productFruitsService: ProductFruitsService
  ) {
    this.checkCurrentPage(this.router.url);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.navState = false;
        this.checkCurrentPage(event.url);
      }
    });
    this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(platform.logo);
    this.projectDataBusService.projectObservable.subscribe(
      (project: Project) => {
        if (project?.slug !== '') {
          this.currentProject = project;
        } else {
          this.currentProject = null;
        }
      }
    );
    this.projectDataBusService.isLoadingObservable.subscribe((value) => {
      this.loading = value;
    });

    effect(() => {
      if (this.productFruitsService.isReady() && this.productFruitLauncher()) {
        window.productFruits.api.announcementsV2.listen(
          'newsfeed-unread-count-changed',
          (data) => {
            this.newsCount = data.count;
          }
        );
        if (this.productFruitLauncher()?.nativeElement) {
          window.productFruits.api.announcementsV2.attachNewsWidgetToElement(
            this.productFruitLauncher().nativeElement
          );
        }
      }
    });
  }

  showUserMenu() {
    this.userMenuVisible = true;
  }

  hideUserMenu() {
    this.userMenuVisible = false;
  }

  isBA() {
    return PlatformService.platform.id === 'ba';
  }

  async checkCurrentPage(url) {
    if (url === '/') {
      this.current = { name: 'home' };
    } else if (/^\/projects/.test(url)) {
      this.current = { name: 'projects' };
      if (/\/targets/.test(url)) {
        this.currentOnProjects = 'targets';
      } else if (/\/updates/.test(url)) {
        this.currentOnProjects = 'updates';
      } else if (/\/contact/.test(url)) {
        this.currentOnProjects = 'contact';
      } else if (/\/phases/.test(url)) {
        this.currentOnProjects = 'phases';
      } else if (/\/events/.test(url)) {
        this.currentOnProjects = 'events';
      } else if (/\/feedback/.test(url)) {
        this.currentOnProjects = 'feedback';
      } else if (/\/satisfaction-monitor/.test(url)) {
        this.currentOnProjects = 'satisfaction-monitor';
      } else if (/\/tickets/.test(url)) {
        this.currentOnProjects =
          'tickets-' + this.route.snapshot.queryParams.tab;
      } else if (/\/statistics/.test(url)) {
        this.currentOnProjects = 'statistics';
      } else if (/\/livestreams/.test(url)) {
        this.currentOnProjects = 'livestreams';
      } else if (/\/widget/.test(url)) {
        this.currentOnProjects = 'widget';
      } else if (/\/settings/.test(url)) {
        this.currentOnProjects = 'settings';
      } else if (/\/users/.test(url)) {
        this.currentOnProjects = 'users';
      } else if (/\/general/.test(url)) {
        this.currentOnProjects = 'general';
      } else if (/\/smart-reporting/.test(url)) {
        this.currentOnProjects = 'smart-reporting';
      } else {
        this.currentOnProjects = 'dashboard';
      }
    } else if (/\/updates/.test(url)) {
      this.current = { name: 'updates' };
    } else if (/^\/tickets/.test(url)) {
      this.current = { name: 'tickets' };
      if (this.route.snapshot.queryParams.tab === 'open')
        this.current = { name: 'open', parent: 'tickets' };
      if (this.route.snapshot.queryParams.tab === 'pending')
        this.current = { name: 'pending', parent: 'tickets' };
      if (this.route.snapshot.queryParams.tab === 'closed')
        this.current = { name: 'closed', parent: 'tickets' };
    } else if (/^\/smart-reporting/.test(url)) {
      this.current = { name: 'smart-reporting' };
      if (/\/notices/.test(url))
        this.current = { name: 'notices', parent: 'smart-reporting' };
      if (/\/themes/.test(url))
        this.current = { name: 'themes', parent: 'smart-reporting' };
      if (/\/settings/.test(url))
        this.current = { name: 'settings', parent: 'smart-reporting' };
    } else if (/^\/resident-letters/.test(url)) {
      this.current = { name: 'resident-letters' };
    } else if (/^\/news/.test(url)) {
      this.current = { name: 'news' };
    } else if (/^\/knowledge/.test(url)) {
      this.current = { name: 'knowledge' };
    } else if (/^\/profile/.test(url)) {
      this.current = { name: 'profile' };
      if (/\/company/.test(url))
        this.current = { name: 'company', parent: 'profile' };
      if (/\/admin/.test(url))
        this.current = { name: 'admin', parent: 'profile' };
      if (/\/export/.test(url))
        this.current = { name: 'export', parent: 'profile' };
    } else if (/^\/organization/.test(url)) {
      this.current = { name: 'organization' };
      if (/\/profile/.test(url))
        this.current = { name: 'profile', parent: 'organization' };
      if (/\/admin/.test(url))
        this.current = { name: 'admin', parent: 'organization' };
      if (/\/export/.test(url))
        this.current = { name: 'export', parent: 'organization' };
    } else {
      this.current = { name: '' };
    }
  }

  /**
   * @returns {Promise<void>}
   */
  async ngOnInit(): Promise<void> {
    this.authenticated = this.authService.isAuthenticated();
    if (this.authenticated) {
      await Promise.all([
        this.userDataService.retrieveUser().then((user) => (this.user = user)),
        this.userDataService.retrieveCustomers().then((customers) => {
          this.customers = customers;
          this.updateCustomers();
        }),
      ]);
      if (this.user?.projectUser) {
        if (
          !this.user.projectUser?.twoFactorEnabled &&
          this.user.projectUser?.forceTwoFactor &&
          !this.user.ssoLogin
        ) {
          this.router.navigate(['/two-factor']);
        }
        if (this.user.projectUser?.avatarThumbnails) {
          this.avatar = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.user.projectUser.avatarThumbnails.small
          );
        }
      }
    }

    this.countOpenTickets();

    this.ticketService.ticketStatusChanged.subscribe(() =>
      this.countOpenTickets()
    );
    this.smartReportingService.noticeStateChanged$.subscribe(() =>
      this.countOpenNotices()
    );

    this.accessService.accessControlList.subscribe(async (list) => {
      if (!list) {
        return;
      }

      this.acl = list;
      this.checkPermissions();
      this.updateCustomers();

      this.hasSmartReportingModule =
        await this.moduleService.someCustomerOrProjectHasModule(
          Module.SMART_REPORTING,
          list
        );

      if (this.hasSmartReportingModule) {
        this.countOpenNotices();
      }
    });

    this.version = await this.themeService.getVersion();
    if (this.version === 2) {
      this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(
        platform.logo.replace('.png', '_beta.png')
      );
    }
  }

  isActive(name: string, parent?: string): boolean {
    if (parent) {
      return name === this.current.name && parent === this.current.parent;
    }
    return name === this.current.name || name === this.current.parent;
  }

  updateCustomers() {
    if (this.acl !== undefined) {
      this.acl.mappings.forEach((item) => {
        if (
          !this.customers.some((customer) => {
            return customer.id === item.project.customer.id;
          })
        ) {
          this.customers.unshift(item.project.customer);
        }
      });
    }
    this.customers = this.customers.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    );
  }

  getCalculatedCustomers() {
    return this.viewAllOrganisations
      ? this.customers
      : this.customers.slice(0, 4);
  }

  viewAllOrganizations() {
    this.viewAllOrganisations = !this.viewAllOrganisations;
  }

  getRemainingItemCount() {
    return this.customers.length - 4;
  }

  isActiveOnProjects(type: string): boolean {
    return type === this.currentOnProjects;
  }

  getCustomers(): string {
    return null == this.customers || this.customers.length === 0
      ? '-'
      : this.customers.map((item) => item.name).join(', ');
  }

  async pickCustomer(to: string, module?: Module): Promise<void> {
    this.navState = false;
    const customer = await this.customerPicker().pick(module);
    if (customer) this.router.navigateByUrl(`/${to}/${customer.id}`);
  }

  async pickProject(): Promise<void> {
    this.navState = false;
    await this.projectPicker().pick();
  }

  public toggleNav() {
    this.navState = !this.navState;
  }

  public forceTwoFactor() {
    return this.customers.some((item) => item.forceTwoFactor === true);
  }

  private checkPermissions() {
    if (this.acl) {
      let customers = this.acl.customers.map((item) => item.customer);
      this.hasThemeOption = customers.some(
        (customer) =>
          customer.enabledModules &&
          customer.enabledModules.includes(Module.SMART_REPORTING)
      );
      this.hasSettingsOption = this.hasThemeOption;
      this.isAccountAdmin = SecurityVoter.isAccountAdmin(this.acl);
    }
  }

  private countOpenTickets() {
    this.ticketService
      .countOpenTickets()
      .subscribe((value) => (this.openTickets = value));
  }

  private countOpenNotices() {
    this.smartReportingService
      .countOpenNotices()
      .subscribe((value) => (this.openNotices = value));
  }

  public logout() {
    this.authService.logout();
  }
}
