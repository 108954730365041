<div class="container">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1>
        {{ 'blog.knowledge.title' | translate }}
      </h1>
    </div>
  </div>
</div>

<form [formGroup]="filterForm">
  <div class="container">
    <div class="bouwapp-toolbar hasSorting">
      <div>
        <input
          [attr.placeholder]="'news.list.search' | translate"
          type="search"
          class="search"
          formControlName="search"
          id="search"
        />
      </div>
      <div>
        <select
          [compareWith]="compareSort"
          [attr.title]="'news.list.sort' | translate"
          id="sort"
          class="select select--m"
          formControlName="sort"
        >
          <option *ngFor="let sort of sortItems" [ngValue]="sort">
            {{ sort.label | translate }}
          </option>
        </select>
      </div>
    </div>

    <div class="bouwapp-filter isHorizontal">
      <span>{{ 'news.list.filter.category.title' | translate }}</span>
      <ul formArrayName="categories">
        <li *ngFor="let category of categories; let index = index">
          <input
            type="checkbox"
            [id]="'category' + index"
            [formControlName]="index"
          />
          <label [for]="'category' + index">{{ category.title }}</label>
        </li>
      </ul>
    </div>

    <div class="bouwapp-overview">
      <div class="bouwapp-overview__list" *ngIf="articles && articles.length">
        <span>{{
          'news.list.result.title' | translate : { articles: totalItems }
        }}</span>

        <ul
          class="news__overview__items"
          *ngIf="articles"
          infiniteScroll
          (scrolled)="loadArticles()"
          [infiniteScrollContainer]="'div.app__content'"
          [infiniteScrollDisabled]="articles.length == totalItems"
          [fromRoot]="true"
        >
          <li
            *ngFor="let article of articles"
            (click)="openArticle(article)"
            class="article"
          >
            <div
              class="article__tag"
              [ngStyle]="{ background: article.blogCategory.color }"
              *ngIf="article.blogCategory.title"
            >
              {{ article.blogCategory.title }}
            </div>

            <button
              (click)="toggleFavorite(article, $event)"
              class="article__favorite"
              [ngClass]="{ isFavorited: article.isFavorited }"
            >
              <span [inlineSVG]="'/assets/img/icons/favorite.svg'"></span>
            </button>

            <div
              class="article__image"
              [style.backgroundImage]="getImageByArticle(article)"
              *ngIf="article.image"
            ></div>
            <div class="article__image noImage" *ngIf="!article.image"></div>

            <div class="article__content">
              <h3>{{ article.title }}</h3>
              <span>{{ article.publishedAt | date : 'd MMMM y HH:mm' }}</span>
              <div
                [ngClass]="article.latestBlogPostRead ? 'read' : 'unread'"
                class="icon"
                [inlineSVG]="'/assets/img/icons/check.svg'"
              ></div>
            </div>
          </li>
        </ul>
      </div>

      <p
        class="bouwapp-overview__empty"
        *ngIf="articles && articles.length === 0"
      >
        {{ 'news.list.empty' | translate }}
      </p>

      <app-loader *ngIf="loading"></app-loader>
    </div>
  </div>
</form>
