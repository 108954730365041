import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { VersionDirective } from '../directives/version.directive';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  standalone: true,
  imports: [TranslateModule, VersionDirective],
})
export class FooterComponent {}
