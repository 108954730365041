import { Injectable } from '@angular/core';
import { Point } from '../interfaces/point';
import { Chart, Tooltip } from 'chart.js';

@Injectable()
export class ChartJsService {
  private initialized = false;

  public initialize() {
    if (this.initialized) {
      return;
    }

    this.addResponseDataAnnotationListener();

    this.initialized = true;
  }

  private addResponseDataAnnotationListener() {
    Chart.register([
      Tooltip,
      {
        id: 'afterUpdatePlugin',
        afterUpdate: function (chart) {
          const xAxis = chart.scales.x;
          const yAxis = chart.scales.y;
          const points: Point[] = [];

          if (chart.config.data.datasets.length > 0) {
            const data = chart.config.data.datasets[0].data;
            let index = 0;

            for (const item of data) {
              if (
                (<any>item).responses != null &&
                (<any>item).responses.length > 0
              ) {
                points.push({
                  x: xAxis.getPixelForTick(index),
                  y: yAxis.getPixelForTick(0),
                  responses: (<any>item).responses,
                });
              } else {
                points.push(null);
              }
              index++;
            }
          }
        },
      },
    ]);
  }
}
