import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { LoaderComponent } from '../../../components/loader.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html',
    styleUrls: ['./auth-callback.component.scss'],
    standalone: true,
    imports: [NgIf, LoaderComponent]
})
export class AuthCallbackComponent implements OnInit {
  error = false;

  constructor(private authService: AuthService, private router: Router) {
    this.checkToken();
  }

  ngOnInit(): void {
    this.authService.tradeToken();
  }

  async checkToken() {
    // If no token was received, try to login again
    const timeout = setTimeout(() => this.authService.login(), 20 * 1000);
    await this.authService.waitForToken();
    clearTimeout(timeout);

    await this.router.navigate(['']);
  }
}
