import * as randomColor from 'randomcolor';

export class ColorHelper {
  public static generateColorStream(
    colors: string[],
    based: number = 6,
    requiredLength: number
  ): string[] {
    if (colors.length < requiredLength) {
      const color = randomColor();
      colors.push(color);

      return ColorHelper.generateColorStream(colors, based, requiredLength);
    } else {
      return colors;
    }
  }

  public static generateRgbaColorsFromHex(
    colors: string[],
    opacity: number
  ): string[] {
    return colors.map((hex) => {
      const rgb = this.convertHexToRgb(hex);

      return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
    });
  }

  private static convertHexToRgb(hex: string): number[] {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return [result[1], result[2], result[3]].map((item) => parseInt(item, 16));
  }
}
