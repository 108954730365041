import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';


export function LatitudeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const latitude = parseFloat(control.value);
      if (isNaN(latitude) || latitude < -90 || latitude > 90) {
        return { invalidLocation: true };
      }
      return null;
    };
  }
  
  export function LongitudeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const longitude = parseFloat(control.value);
      if (isNaN(longitude) || longitude < -180 || longitude > 180) {
        return { invalidLocation: true };
      }
      return null;
    };
  }