import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ErrorService } from '../../../../services/error.service';
import { ProjectDataBusService } from '../../../../services/project-data-bus.service';
import { ProjectService } from '../../../../services/project.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { Project } from '../../../../interfaces/project';
import { Event } from '../../../../interfaces/event';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoadingDirective } from '../../../../directives/loading.directive';
import { MultiAttachmentControlComponent } from '../../../../components/multi-attachment-control.component';
import { DatePickerComponent } from '../../../../components/date-picker.component';
import { QuillComponent } from '../../../../components/quill.component';
import { FormGroupComponent } from '../../../../components/form-group.component';
import { LoaderComponent } from '../../../../components/loader.component';
import { DatePipe, NgIf } from '@angular/common';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-default-projects-detail-events-edit',
  templateUrl: 'edit.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    ReactiveFormsModule,
    FormChangeDetectorDirective,
    FormGroupComponent,
    QuillComponent,
    DatePickerComponent,
    MultiAttachmentControlComponent,
    RouterLink,
    LoadingDirective,
    TranslateModule,
    DatePipe,
    InlineSVGModule,
  ],
})
export class DetailEventsEditComponent {
  public form: FormGroup;
  public project: Project;
  public edit = false;
  public loading = false;
  public event: Event;
  public uploading = false;
  public dateRangeValid = true;
  private id: number;

  constructor(
    private projectDataBusService: ProjectDataBusService,
    private projectService: ProjectService,
    private formBuilder: FormBuilder,
    private eventService: EventService,
    private errorService: ErrorService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.createForm();
    this.projectDataBusService.projectObservable.subscribe(
      (project) => (this.project = project)
    );

    this.activatedRoute.params.subscribe((params) => {
      if (params['id'] != null) {
        this.id = params['id'];
        this.edit = true;

        this.loadEvent(this.id);
      } else {
        this.id = null;
        this.edit = false;
      }
    });

    window.scroll(0, 0);
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(60)]],
      startDate: [null, Validators.required],
      endDate: [null],
    });

    this.form.valueChanges.subscribe((value) => {
      const startDate = new Date(value.startDate).getTime();
      const endDate = new Date(value.endDate).getTime();
      const currDate = new Date().getTime();
      const days = 24 * 60 * 60 * 1000;

      if (startDate) {
        if (startDate < currDate - days * 30) {
          this.form.get('startDate').setErrors({
            invalidDate: true,
            message: this.translateService.instant(
              'form_group.invalid_date_past'
            ),
          });
        } else if (startDate > currDate + days * 365) {
          this.form.get('startDate').setErrors({
            invalidDate: true,
            message: this.translateService.instant(
              'form_group.invalid_date_future'
            ),
          });
        } else {
          this.form.get('startDate').setErrors(null);
        }
      }

      if (endDate) {
        if (endDate <= startDate) {
          this.form.get('endDate').setErrors({
            invalidDate: true,
            message: this.translateService.instant(
              'form_group.invalid_date_end'
            ),
          });
        } else if (endDate - startDate > days * 30) {
          this.form.get('endDate').setErrors({
            invalidDate: true,
            message: this.translateService.instant(
              'form_group.invalid_date_diff'
            ),
          });
        } else {
          this.form.get('endDate').setErrors(null);
        }
      }
    });
  }

  async save(): Promise<void> {
    this.errorService.markFormGroupTouchedAndDirty(this.form);

    if (this.form.valid) {
      this.loading = true;

      try {
        const data = this.form.getRawValue();

        if (data.startDate)
          data.startDate = new Date(data.startDate).toISOString();
        if (data.endDate) data.endDate = new Date(data.endDate).toISOString();

        if (this.edit) {
          data.id = this.id;

          await this.eventService.update(data);
        } else {
          await this.eventService.create(this.project, data);
        }

        this.router.navigate(['/projects', this.project.slug, 'phases']);
        this.projectService.updateLastEdited(this.project);
      } catch (error) {
        this.errorService.parseErrorsToForm(this.form, error.error);
      } finally {
        this.loading = false;
      }
    } else {
      await this.errorService.scrollToInvalidFormGroup();
    }
  }

  async loadEvent(id: number): Promise<void> {
    try {
      this.event = await this.eventService.fetch(id);
      let offset = new Date().getTimezoneOffset() * 60000;

      let adjustedStartDate = new Date(
        new Date(this.event.startDate).getTime() - offset
      );
      this.event.startDate = adjustedStartDate
        .toISOString()
        .substring(0, 16) as any;

      if (this.event.endDate) {
        let adjustedEndDate = new Date(
          new Date(this.event.endDate).getTime() - offset
        );
        this.event.endDate = adjustedEndDate
          .toISOString()
          .substring(0, 16) as any;
      }

      this.form.patchValue(this.event);
    } catch (error) {
      this.router.navigate(['/404']);
    }
  }
}
