import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { LoaderComponent } from '../../../components/loader.component';

@Component({
    selector: 'app-login-redirect',
    templateUrl: './login-redirect.component.html',
    styleUrls: ['./login-redirect.component.scss'],
    standalone: true,
    imports: [LoaderComponent]
})
export class LoginRedirectComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.login();
  }
}
