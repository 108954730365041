<div *ngIf="createTicket" class="create-ticket">
  <form appFormChangeDetector [formGroup]="ticketForm" (submit)="saveTicket()">
    <app-form-group [form]="ticketForm" errorPath="name">
      <label for="subject">{{ 'ticket_list.table.name' | translate }}</label>
      <input
        type="text"
        class="input input--m"
        name="name"
        id="name"
        formControlName="name"
      />
    </app-form-group>
    <app-form-group [form]="ticketForm" errorPath="subject">
      <label for="subject">{{ 'ticket_list.table.subject' | translate }}</label>
      <input
        type="text"
        name="subject"
        id="subject"
        class="input input--m"
        formControlName="subject"
      />
    </app-form-group>
    <app-form-group [form]="ticketForm" errorPath="content">
      <label for="content">{{
        'projects.detail.announcement.edit.message' | translate
      }}</label>
      <textarea
        name="content"
        id="content"
        class="textarea"
        formControlName="content"
      ></textarea>
    </app-form-group>
    <button
      class="button button--m button--flat bg--orange"
      type="submit"
      [appLoading]="loading"
    >
      {{ 'conversation.submit' | translate }}
    </button>
  </form>
</div>
<div class="tickets__head" [hidden]="tickets.length == 0">
  <ul class="head">
    <li [class.isCustomer]="!withProject">
      <span>
        {{ 'ticket_list.table.status' | translate }}
      </span>
      <span>
        {{ 'ticket_list.table.date' | translate }}
      </span>
      <span *ngIf="!withProject">
        {{ 'ticket_list.table.project' | translate }}
      </span>
      <span>
        {{ 'ticket_list.table.name' | translate }}
      </span>
      <span>
        {{ 'ticket_list.table.subject' | translate }}
      </span>
      <span>
        {{ 'ticket_list.table.rating' | translate }}
      </span>
    </li>
  </ul>

  <ul class="body">
    <li
      app-ticket
      *ngFor="let ticket of tickets"
      [ticket]="ticket"
      [withProject]="withProject"
      (click)="openTicket(ticket, $event)"
      [class.isCustomer]="!withProject"
    ></li>
  </ul>
</div>

<div class="list" [hidden]="!isEmpty() || loading">
  <div class="no-results">{{ 'ticket_list.table.no_results' | translate }}</div>
</div>
