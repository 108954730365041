import { Injectable } from '@angular/core';
import { Project } from '../interfaces/project';
import { ProjectService } from './project.service';
import { Update } from '../interfaces/update';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Event } from '../interfaces/event';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class EventService {
  static base = '/events';

  constructor(private http: HttpClient) {}

  public listByProject(project: Project): Promise<Event[]> {
    return firstValueFrom(
      this.http.get<Event[]>(
        environment.apiUrl +
          ProjectService.base +
          '/' +
          project.slug +
          EventService.base
      )
    );
  }

  public async create(project: Project, data: any): Promise<Update> {
    return firstValueFrom(
      this.http.post<Update>(
        environment.apiUrlV3 +
          ProjectService.base +
          '/' +
          project.slug +
          EventService.base,
        data
      )
    );
  }

  public async update(data: Event): Promise<Event> {
    return await firstValueFrom(
      this.http.put<Event>(
        environment.apiUrl + EventService.base + '/' + data.id,
        data
      )
    );
  }

  public async remove(event: Event): Promise<void> {
    await firstValueFrom(
      this.http.delete(environment.apiUrl + EventService.base + '/' + event.id)
    );

    return;
  }

  public fetch(id: number): Promise<Event> {
    return firstValueFrom(
      this.http.get<Event>(environment.apiUrl + EventService.base + '/' + id)
    );
  }
}
