import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { ProjectService } from '../services/project.service';
import { Project } from '../interfaces/project';
import {
  AbstractControl,
  FormBuilder,
  ReactiveFormsModule,
} from '@angular/forms';
import { ProjectDataBusService } from '../services/project-data-bus.service';
import { Article } from '../interfaces/article';
import { NewsService } from '../services/news.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgClass, NgFor, DatePipe } from '@angular/common';
import { VersionDirective } from '../directives/version.directive';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: 'breadcrumbs.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    VersionDirective,
    NgClass,
    ReactiveFormsModule,
    NgFor,
    DatePipe,
    TranslateModule,
  ],
})
export class BreadcrumbsComponent implements OnInit {
  public current: string;

  public currentProjectSlug: string;

  public control: AbstractControl;

  public projects: Project[];

  private isCreatingProject = false;

  public showLastEdited = false;

  public currentProject: Project;

  public article: Article;

  public currentCustomerId: number;

  constructor(
    private router: Router,
    private projectService: ProjectService,
    private formBuilder: FormBuilder,
    private projectDataBusService: ProjectDataBusService,
    private newsService: NewsService
  ) {
    this.createControl();

    this.checkBreadCrumbs(this.router.url);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkBreadCrumbs(event.url);
      }
    });

    this.initLastUpdated();
  }

  ngOnInit(): void {
    // this won't be called too often, as it's above the default router-outlet, so just load projects on init
    this.loadProjectList();
  }

  checkBreadCrumbs(url: any) {
    this.currentProjectSlug = null;
    this.article = null;

    if (/^\/$/.test(url)) {
      this.current = 'project-list';
    } else if (/^\/projects\/([a-zA-Z0-9-]+)/.test(url)) {
      if (this.isCreatingProject) {
        // has just created a project
        this.loadProjectList();
      }

      const match = url.match(/^\/projects\/([a-zA-Z0-9-]+)/);
      this.currentProjectSlug = match[1];

      this.control.setValue(this.currentProjectSlug);
      this.current = 'project-view';
      this.showLastEdited = true;
    } else if (/^\/projects$/.test(url)) {
      this.isCreatingProject = true;
      this.current = 'project-create';
      this.showLastEdited = false;
    } else if (/^\/smart-reporting\/([0-9]+)\/notices\/([0-9]+)/.test(url)) {
      this.current = 'smart-reporting-detail';
      this.showLastEdited = false;
    } else if (/^\/smart-reporting/.test(url)) {
      this.current = 'smart-reporting';
      this.showLastEdited = false;
    } else if (/^\/profile\/personal/.test(url)) {
      this.current = 'profile-personal';
      this.showLastEdited = false;
    } else if (/^\/profile\/password/.test(url)) {
      this.current = 'profile-password';
      this.showLastEdited = false;
    } else if (/^\/profile\/export/.test(url)) {
      this.current = 'profile-export';
      this.showLastEdited = false;
    } else if (/^\/profile\/company\/admin/.test(url)) {
      this.current = 'profile-admin';
      this.showLastEdited = false;
      const match = url.match(/^\/profile\/company\/admin\/([0-9-]+)/);
      this.currentCustomerId = match[1];
    } else if (/^\/profile\/company/.test(url)) {
      this.current = 'profile-company';
      this.showLastEdited = false;
      const match = url.match(/^\/profile\/company\/([0-9-]+)/);
      this.currentCustomerId = match[1];
    } else if (/^\/tickets/.test(url)) {
      this.current = 'tickets';
      this.showLastEdited = false;
    } else if (/^\/news/.test(url)) {
      this.current = 'news';
      this.showLastEdited = false;
    } else if (/^\/knowledge/.test(url)) {
      this.current = 'knowledge';
      this.showLastEdited = false;
    } else if (/^\/article/.test(url)) {
      this.current = 'article-view';
      const match = url.match(/^\/articles\/([a-zA-Z0-9-]+)/);
      this.loadArticle(match[1]);
      this.showLastEdited = false;
    }
  }

  createControl(): void {
    this.control = this.formBuilder.control(null);
    this.control.valueChanges.subscribe((value) => {
      if (value !== this.currentProjectSlug) {
        this.router.navigate(['/projects', value]);
      }
    });
  }

  async loadArticle(slug: string): Promise<void> {
    this.article = await this.newsService.fetch(slug);
  }

  async loadProjectList(): Promise<void> {
    const response: any = await this.projectService.shortList();
    const result: Project[] = response['hydra:member'];

    this.projects = result;
    this.projectDataBusService.emitShortList(result);
    setTimeout(() => {
      this.control.setValue(this.currentProjectSlug);
    });
  }

  private initLastUpdated(): void {
    this.projectDataBusService.projectObservable.subscribe((value) => {
      this.currentProject = value;
    });
  }
}
