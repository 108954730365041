import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DetailGeneralTargetsComponent } from './detail-general/detail-general-targets.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-default-projects-detail-targets',
  templateUrl: './detail-targets.component.html',
  standalone: true,
  imports: [DetailGeneralTargetsComponent, TranslateModule, NgIf],
})
export class DetailTargetsComponent {}
