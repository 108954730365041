import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalComponent } from './modal.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-confirm-delete',
    templateUrl: 'confirm-delete.component.html',
    standalone: true,
    imports: [ModalComponent, TranslateModule]
})
export class ConfirmDeleteComponent {
  @ViewChild('modal', { static: true }) modal: ModalComponent;

  private entity: any;

  @Input() message = '';
  @Output() confirmed = new EventEmitter();

  confirm(): void {
    this.confirmed.emit(this.entity);

    this.modal.close();
  }

  confirmDelete(entity: any): boolean {
    this.entity = entity;
    this.modal.open();

    return false;
  }
}
