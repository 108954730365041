<h2 class="title">
  {{ 'projects.detail.concept.step.location.title' | translate }}
</h2>
<div [formGroup]="generalForm" class="form-group" #mapContainer>
  <p>{{ 'projects.detail.general.location.description' | translate }}</p>
  <app-form-group [form]="generalForm" errorPath="locationLat">
  </app-form-group>
  <div class="project-map">
    <mgl-map
      #map
      (mapClick)="mapClick($event)"
      (mapLoad)="initMap()"
      class="map"
      [style]="'mapbox://styles/mapbox/streets-v11'"
      [zoom]="12"
      [maxZoom]="50"
    >
      <mgl-control mglNavigation [showCompass]="false"></mgl-control>
    </mgl-map>
  </div>
  <div>
    <div class="project-shapefile" *ngIf="canEdit">
      <app-form-group [form]="generalForm" errorPath="locationShapeFileData">
        <h3>
          {{ 'projects.detail.general.location.shapefile' | translate }}
        </h3>
        <div class="alert alert-info" [hidden]="!shapeFileInfo">
          {{ 'projects.detail.general.location.info' | translate }}
          <span
            [innerHTML]="
              'projects.detail.general.location.info.more' | translate
            "
          ></span>
        </div>
        <app-input-file
          *appVersion="1"
          (previewUpdated)="updateLocationShapeFile($event)"
          [simple]="true"
          [xhrUpload]="false"
          formControlName="locationShapeFileData"
          [label]="
            'projects.detail.general.location.shapefile.label' | translate
          "
          [labelSelectedValue]="
            'projects.detail.general.location.shapefile.label.selected'
              | translate
          "
          [simpleValue]="locationShapeFileData"
          [uploadJson]="true"
          #shapeFileInput
        >
        </app-input-file>
        <app-input-file-v2
          *appVersion="2"
          [geoJson]="true"
          (uploadStart)="updateLocationShapeFile($event)"
          (fileRemoved)="clearShapeFiles()"
          [uploadJson]="true"
          [standalone]="true"
          [selected]="locationShapeFileData"
          #shapeFileInput2
        >
          <span *ngIf="locationShapeFileData">
            <strong>{{
              'projects.detail.general.location.shapefile.label.selected'
                | translate
            }}</strong>
          </span>
          <span *ngIf="!locationShapeFileData">
            {{ 'projects.detail.general.location.shapefile.label' | translate }}
          </span>
        </app-input-file-v2>
      </app-form-group>
    </div>
  </div>
</div>
