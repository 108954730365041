<form class="data-filter" [formGroup]="form">
  <div formArrayName="target">
    <h4>{{ 'data_filter.targets' | translate }}</h4>
    <div class="checkbox">
      <input
        [checked]="allChecked"
        id="targets-all"
        type="checkbox"
        (change)="toggleAll($event)"
      />
      <label for="targets-all">{{
        'data_filter.targets.all' | translate
      }}</label>
    </div>
    <div
      *ngFor="let target of demography.targets; let index = index"
      class="data-filter__holder"
    >
      <div class="checkbox">
        <input
          type="checkbox"
          id="targetDemography{{ target.id }}"
          name="target[]"
          [value]="target.id"
          [formControlName]="index"
        />
        <label
          for="targetDemography{{ target.id }}"
          [class.data-filter--unknown]="target.id == UNKNOWN"
          >{{ target.name }}</label
        >
      </div>
      <span class="data-filter__count text-muted">{{ target.count }}</span>
    </div>
  </div>

  <div *ngIf="demography.themes">
    <h4>{{ 'data_filter.themes' | translate }}</h4>
    <div
      *ngFor="let theme of demography.themes; let index = index"
      class="data-filter__holder"
    >
      <div class="radio">
        <input
          type="radio"
          id="theme{{ theme.id }}"
          name="theme"
          [value]="theme.id"
          formControlName="theme"
        />
        <label for="theme{{ theme.id }}">{{ parseTitle(theme.name) }}</label>
      </div>
      <span class="data-filter__count text-muted">{{ theme.count }}</span>
    </div>
  </div>

  <div *ngIf="demography.types" formArrayName="type">
    <h4>{{ 'data_filter.type_message' | translate }}</h4>
    <div
      *ngFor="let type of demography.types; let index = index"
      class="data-filter__holder"
    >
      <div class="checkbox">
        <input
          type="checkbox"
          id="type{{ type.id }}"
          name="type[]"
          [value]="type.id"
          [formControlName]="index"
        />
        <label
          for="type{{ type.id }}"
          [class.data-filter--unknown]="type.id == UNKNOWN"
          >{{ type.name }}</label
        >
      </div>
      <span class="data-filter__count text-muted">{{ type.count }}</span>
    </div>
  </div>
</form>
