import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { Livestream } from '../interfaces/livestream';
import { Project } from '../interfaces/project';
import { CollectionResponse } from '../interfaces/collection-response';
import { ProjectService } from './project.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class LivestreamService {
  static base = '/live-streams';
  static storeKey = 'livestreams';

  public updated$ = new BehaviorSubject<void>(null);

  constructor(
    private http: HttpClient,
    private storageService: LocalStorageService
  ) {}

  public list(project: Project): Observable<Livestream[]> {
    return Observable.create((observer: Observer<Livestream[]>) => {
      const stored = this.storageService.retrieve(this.createStorageKey());
      if (stored != null) {
        observer.next(stored);
      }

      this.fetchProject(project)
        .then((data: Livestream[]) => {
          observer.next(data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  public async fetchProject(project: Project): Promise<Livestream[]> {
    const response: CollectionResponse<Livestream> = await this.http
      .get<CollectionResponse<Livestream>>(
        environment.apiUrl +
          `${ProjectService.base}/${project.slug}/live-streams`
      )
      .toPromise();

    const data = response['hydra:member'];
    this.storageService.store(this.createStorageKey(project), data);

    return data;
  }

  public update(data: Livestream): Promise<Livestream> {
    return this.http
      .put(environment.apiUrl + LivestreamService.base + '/' + data.id, data)
      .toPromise()
      .then((response) => {
        this.updated$.next(null);
        return response as Promise<Livestream>;
      });
  }

  public create(data: Livestream, project: Project): Promise<Livestream> {
    return this.http
      .post(environment.apiUrl + LivestreamService.base, {
        ...data,
        project: '/api' + ProjectService.base + '/' + project.slug,
      })
      .toPromise()
      .then((response) => {
        this.updated$.next(null);
        return response as Promise<Livestream>;
      });
  }

  public delete(livestream: Livestream): Promise<Livestream> {
    return this.http
      .delete(environment.apiUrl + `${LivestreamService.base}/${livestream.id}`)
      .toPromise()
      .then((response) => {
        this.updated$.next(null);
        return response as Promise<Livestream>;
      });
  }

  private createStorageKey(project: Project | null = null): string {
    return `${LivestreamService.storeKey}-${
      project != null ? project.slug : 'global'
    }`;
  }

  public convertToEmbedUrl(url: string): string {
    try {
      const parsedUrl = new URL(url);

      if (this.isYoutube(url)) {
        if (parsedUrl.pathname.includes('/embed/')) {
          return url;
        } else if (parsedUrl.pathname.includes('/watch')) {
          const videoID = parsedUrl.searchParams.get('v');
          if (videoID) {
            return `https://www.youtube.com/embed/${videoID}`;
          } else {
            throw new Error('Invalid YouTube URL: missing video ID');
          }
        }
      } else {
        throw new Error('URL is not a YouTube URL');
      }
    } catch (e) {
      return url;
    }

    return url;
  }

  public isYoutube(url: string) {
    try {
      const parsedUrl = new URL(url);

      return ['youtube.com', 'www.youtube.com'].includes(parsedUrl.hostname);
    } catch (e) {
      return false;
    }
  }
}
