import { Component, ViewChild } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ProjectDataBusService } from '../../../../../services/project-data-bus.service';
import { Project } from '../../../../../interfaces/project';
import { Module } from '../../../../../enums/module';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '../../../../../components/loader.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { VersionDirective } from 'src/app/directives/version.directive';
import { ThemeService } from 'src/app/services/theme.service';
import { SmartReportingNoticesTableComponent } from './table/table.component';
import { AccessDirective } from 'src/app/directives/access.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';

@Component({
  selector: 'app-smart-reporting-notices',
  templateUrl: './notices.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    NgFor,
    NgClass,
    RouterLink,
    RouterOutlet,
    TranslateModule,
    VersionDirective,
    SmartReportingNoticesTableComponent,
    AccessDirective,
    InlineSVGModule,
  ],
})
export class SmartReportingNoticesComponent {
  public static readonly LAST_PROJECT_KEY = 'lastProject';
  public projectList: Project[];
  public activeProjectSlug: string;

  @ViewChild(SmartReportingNoticesTableComponent) public noticesTable;

  constructor(
    private projectDataBusService: ProjectDataBusService,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private router: Router
  ) {
    this.themeService.getVersion().then((version) => {
      if (version === 1) {
        this.projectDataBusService.shortListObservable.subscribe((list) => {
          this.updateProjectList(list);
        });

        this.router.events
          .pipe(
            filter(
              (it) =>
                it instanceof NavigationEnd || it instanceof NavigationCancel
            )
          )
          .subscribe((value) => {
            this.update();
          });
        this.update();
      }
    });
  }

  private update() {
    if (this.activatedRoute.children.length > 0) {
      if (this.activatedRoute.children[0].snapshot) {
        const params = this.activatedRoute.children[0].snapshot.params;
        this.activeProjectSlug = params.projectSlug;

        window.localStorage.setItem(
          SmartReportingNoticesComponent.LAST_PROJECT_KEY,
          this.activeProjectSlug
        );
      } else {
        setTimeout(() => {
          const params = this.activatedRoute.children[0].snapshot.params;
          this.activeProjectSlug = params.projectSlug;
        });
      }
    } else if (this.activeProjectSlug && this.projectList) {
      this.router.navigateByUrl(
        `/smart-reporting/notices/project/${this.lastProjectList}`
      );
    }
  }

  public getCoverImageByProject(project: Project): SafeStyle | string {
    if (project.coverImage == null) {
      return '';
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        project.coverImageThumbnails?.medium
      );
    }
  }

  private updateProjectList(list: Project[]) {
    if (list === null) {
      return;
    }

    list = list.filter(
      (l) =>
        l.enabledModules && l.enabledModules.includes(Module.SMART_REPORTING)
    );

    this.projectList = list;
    if (!this.activeProjectSlug) {
      this.router.navigateByUrl(
        `/smart-reporting/notices/project/${this.lastProjectList}`
      );
    }
  }

  private get lastProjectList(): string {
    const slug = window.localStorage.getItem(
      SmartReportingNoticesComponent.LAST_PROJECT_KEY
    );

    if (slug && this.projectList.some((it) => it.slug === slug)) {
      return slug;
    }

    return this.projectList[0].slug;
  }

  public exportCsv() {
    this.noticesTable.exportCsv();
  }
}
