import {
  ChangeDetectorRef,
  Component,
  Host,
  HostBinding,
  Input,
} from '@angular/core';
import { SwitchComponent } from './switch.component';

@Component({
    selector: 'app-switch-option',
    templateUrl: 'switch-option.component.html',
    standalone: true
})
export class SwitchOptionComponent {
  @HostBinding('class.switch-option') baseClass = true;
  @HostBinding('class.active') active = false;

  @Input() value: string;

  /**
   * Cannot use @Host binding, as constructor has to be empty
   * @type {SwitchComponent}
   */
  switchComponent: SwitchComponent;

  /**
   * @returns {void}
   */
  select() {
    this.emitValue(this.value);
  }

  /**
   * @param {string} value
   */
  setValue(value: string) {
    this.active = value === this.value;
  }

  /**
   * @param {string} value
   */
  emitValue(value: string) {
    if (this.switchComponent) {
      this.switchComponent.updateValue(value);
    }
  }
}
