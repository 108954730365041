import { Component, OnInit, ViewChild } from '@angular/core';
import { Project } from '../../../interfaces/project';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ErrorService } from '../../../services/error.service';
import { ProjectDataBusService } from '../../../services/project-data-bus.service';
import { ModalComponent } from '../../../components/modal.component';
import { ProjectService } from '../../../services/project.service';
import { Router } from '@angular/router';
import { SecurityVoter } from 'src/app/security/security-voter';
import { AccessService } from 'src/app/services/access.service';
import { AccessControlList } from 'src/app/interfaces/access-control-list';
import { ProjectStatus } from 'src/app/enums/project-status';
import { DateTypePipe } from '../../../pipes/date-type.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDeleteComponent } from '../../../components/confirm-delete.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { FormGroupComponent } from '../../../components/form-group.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { AccessDirective } from '../../../directives/access.directive';
import { LoaderComponent } from '../../../components/loader.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-default-projects-detail-projects',
    templateUrl: './detail-projects.component.html',
    standalone: true,
    imports: [NgIf, LoaderComponent, AccessDirective, NgFor, InlineSVGModule, ModalComponent, ReactiveFormsModule, FormGroupComponent, LoadingDirective, ConfirmDeleteComponent, TranslateModule, DateTypePipe]
})
export class DetailProjectsComponent implements OnInit {
  @ViewChild('addChildProject', { static: true })
  private childProjectModal: ModalComponent;

  childProjectsForm: FormGroup;
  formLoading: boolean;

  public project: Project;
  public availableProjects: Project[];
  private allProjects: Project[];
  private accessControlList: AccessControlList;
  public loading = false;

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private router: Router,
    private projectDataBusService: ProjectDataBusService,
    private projectService: ProjectService,
    accessService: AccessService
  ) {
    accessService.accessControlList.subscribe(
      (acl) => (this.accessControlList = acl)
    );

    this.projectDataBusService.projectObservable.subscribe((project) => {
      this.project = project;
    });

    this.projectDataBusService.shortListObservable.subscribe((projects) => {
      this.allProjects = projects;

      if (this.allProjects) {
        this.getAvailableProjects();
      }
    });

    this.createForms();
  }

  ngOnInit(): void {}

  private createForms() {
    this.childProjectsForm = this.fb.group({
      projectId: [null, Validators.required],
    });
  }

  private getAvailableProjects() {
    this.availableProjects = this.allProjects.filter(
      (project) => project.id !== this.project.id
    );
    this.availableProjects = this.availableProjects.filter((project) => {
      if (
        !project.hasParentProject &&
        this.project.childrenProjects &&
        !project.isParentProject &&
        project.status === ProjectStatus.PUBLISHED
      ) {
        return !this.project.childrenProjects.includes(project);
      }
    });

    this.availableProjects.filter((project) => {
      return SecurityVoter.canEditProject(this.accessControlList, project);
    });
  }

  openChildProjectAdd() {
    this.childProjectModal.open();
  }

  resetChildProject() {
    this.childProjectsForm.reset();
  }

  async deleteChildProject(child: Project): Promise<void> {
    this.loading = true;

    try {
      this.project.childrenProjects.forEach((item, index) => {
        if (item.id === child.id) {
          this.project.childrenProjects.splice(index, 1);
        }
      });

      child.parentProject = null;
      await this.projectService.update(child.slug, child);
    } finally {
      this.loading = false;
    }
  }

  async saveChildProject(): Promise<void> {
    this.errorService.markFormGroupTouchedAndDirty(this.childProjectsForm);

    if (!this.childProjectsForm.valid) {
      return;
    }

    this.loading = true;

    try {
      const data = this.childProjectsForm.getRawValue();
      let childProject: Project = this.allProjects.find((project: Project) => {
        return project.id === data.projectId;
      });
      childProject = await this.projectService.fetch(childProject.slug);

      childProject.parentProject = this.project['@id'];

      await this.projectService.update(childProject.slug, childProject);
      this.project.childrenProjects.push(childProject);

      this.childProjectModal.close();
    } catch (error) {
      this.errorService.parseErrorsToForm(this.childProjectsForm, error.error);
    } finally {
      this.loading = false;
    }
  }

  openProject(project: Project) {
    if (!SecurityVoter.canViewProject(this.accessControlList, project)) return;

    this.router.navigate(['/projects', project.slug]);

    window.scroll(0, 0);
  }
}
