<div class="input-file" *ngIf="withDragDrop" [class.input-lg]="large">
  <div *ngIf="!simple">
    <div class="input-file__left">
      <div class="input-file-icons">
        <div
          [inlineSVG]="'/assets/img/icons/type-img.svg'"
          class="inline-svg__item input-file-icon"
          *ngIf="uploadImages"
        ></div>
        <div
          [inlineSVG]="'/assets/img/icons/type-pdf.svg'"
          class="inline-svg__item input-file-icon"
          *ngIf="uploadPdfs"
        ></div>
        <div
          [inlineSVG]="'/assets/img/icons/type-video.svg'"
          class="inline-svg__item input-file-icon"
          *ngIf="chooseVideo"
        ></div>

        <div *ngIf="chooseVideo" class="input-file-video">
          <a
            class="button button--m button--outlined bg--orange--hover"
            (click)="videoModal.open()"
          >
            {{ 'input_file.video.button.text' | translate }}
          </a>
        </div>
      </div>
    </div>
    <div class="input-file__right">
      <div class="input-file-instruction" *ngIf="instruction == null">
        {{ 'input_file.instruction' | translate }}

        <span *ngIf="uploadImages && !uploadPdfs">{{
          'input_file.instruction.image' | translate
        }}</span>
        <span *ngIf="!uploadImages && uploadPdfs">{{
          'input_file.instruction.document' | translate
        }}</span>
        <span *ngIf="uploadImages && uploadPdfs">{{
          'input_file.instruction.image_or_document' | translate
        }}</span>

        {{ 'input_file.instruction.appendix' | translate }}<br />
        ({{ getExtensionTypes().join(', ') }})

        <div *ngIf="chooseVideo" class="input-file-video">
          {{ 'input_file.video.link.start' | translate }}
          <a (click)="videoModal.open()">{{
            'input_file.video.link.here' | translate
          }}</a>
          {{ 'input_file.video.link.end' | translate }}
        </div>

        <div class="input-file-small-instruction" *ngIf="rotateWarning == null">
          <app-image-rotate-info></app-image-rotate-info>
        </div>
      </div>
      <div class="input-file-instruction">
        {{ name != null && withName ? name : instruction }}
      </div>
    </div>
    <div class="input-file-loader" [hidden]="!isLoading">
      <i class="spinner"></i>
    </div>
    <input
      type="file"
      [id]="'media' + type + unique"
      [accept]="getMimeTypes()"
      (change)="withEditor ? edit($event) : upload($event.target.files)"
      [multiple]="multiple"
      #inputFile
    />
  </div>
  <label *ngIf="label" [class.input-file-simple-label]="simpleValue">
    {{ simpleValue ? labelSelectedValue : label }}
  </label>
  <input
    *ngIf="simple"
    type="file"
    [id]="'media' + type + unique"
    [accept]="getMimeTypes()"
    (change)="withEditor ? edit($event) : upload($event.target.files)"
    [multiple]="multiple"
    #inputFile
  />

  <ng-container *ngIf="simpleValue">
    <div
      inlineSVG="/assets/img/icons/delete.svg"
      (click)="removeFile($event)"
      class="input-file-clear"
    ></div>
  </ng-container>
</div>

<div *ngIf="!withDragDrop" class="input-file-standalone">
  <label
    [for]="'media' + type + unique"
    class="btn"
    [class.loading]="isLoading"
    [class.btn-xlg]="large"
  >
    <ng-content></ng-content> <i class="spinner" [hidden]="!isLoading"></i>
  </label>
  <input
    type="file"
    [id]="'media' + type + unique"
    [accept]="getMimeTypes()"
    (change)="withEditor ? edit($event) : upload($event.target.files)"
    hidden
    #inputFile
  />
</div>

<app-modal
  class="video-modal"
  #videoModal
  [title]="'input_file.video.title' | translate"
>
  <p>
    <span *ngIf="withCustomVideo"
      >{{ 'input_file.video.instruction.custom' | translate }} <br />
      {{ 'input_file.video.instruction.custom.part_2' | translate }}</span
    >
    <span *ngIf="!withCustomVideo">{{
      'input_file.video.instruction' | translate
    }}</span>
  </p>

  <form [formGroup]="videoForm" (submit)="addVideo()">
    <app-form-group
      [form]="videoForm"
      errorPath="videoUrl"
      [hidden]="!withCustomVideo"
    >
      <label for="video">
        {{ 'input_file.video.file' | translate }}
        <ng-container *ngIf="uploader?.queue[0] as item"
          >: {{ item.file.name }} ({{
            item.file.size / 1024 / 1024 | number : '1.0-0'
          }}MB)</ng-container
        >
      </label>
      <button
        type="button"
        class="button button--m button--outlined bg--grey--hover"
        (click)="selectFile()"
      >
        {{ 'input_file.video.title' | translate }}
      </button>
      <input
        (onFileSelected)="fileSelected()"
        #videoInput
        formControlName="videoUrl"
        id="video"
        accept="video/mp4,video/quicktime"
        type="file"
        ng2FileSelect
        [uploader]="uploader"
      />
      <div class="flex flex-row" *ngIf="uploader?.queue[0] as item">
        <progressbar [value]="item.progress" [striped]="true"
          >{{ item.progress || 1 }}%</progressbar
        >
        <button
          type="button"
          class="button button--m button--outlined button--no-border"
          (click)="removeItem()"
        >
          <img src="/assets/img/icons/close--red.svg" />
        </button>
      </div>
    </app-form-group>

    <app-form-group [form]="videoForm" errorPath="youtubeUrl">
      <label for="youtube">{{ 'input_file.video.url' | translate }}</label>
      <input
        #youtubeInput
        type="text"
        id="youtube"
        formControlName="youtubeUrl"
      />
    </app-form-group>

    <div class="justified-buttons">
      <button type="button" (click)="cancel()" class="btn btn-secondary">
        {{ 'input_file.video.cancel' | translate }}
      </button>

      <button type="submit" [disabled]="uploading" class="btn">
        {{ 'input_file.video.confirm' | translate }}
      </button>
    </div>
  </form>
</app-modal>
