<div class="article-detail" *ngIf="article">
  <div class="container">
    <div class="article-detail__action">
      <div (click)="goBack()" class="article-detail__back">
        <span [inlineSVG]="'/assets/img/icons/caret-left--black.svg'"></span>
        <span>{{ 'news.item.back' | translate }}</span>
      </div>
      <div
        (click)="toggleFavorite()"
        [ngClass]="{ isFavorited: article.isFavorited }"
      >
        <span
          [inlineSVG]="'/assets/img/icons/favorite-border.svg'"
          *ngIf="!article.isFavorited"
        ></span>
        <span
          [inlineSVG]="'/assets/img/icons/favorite.svg'"
          *ngIf="article.isFavorited"
        ></span>
        <span *ngIf="!article.isFavorited">{{
          'news.item.favorite' | translate
        }}</span>
        <span *ngIf="article.isFavorited">{{
          'news.item.unfavorite' | translate
        }}</span>
      </div>
    </div>

    <div class="article-detail__inner">
      <div
        class="article-detail__tag"
        [ngStyle]="{ background: article.blogCategory.color }"
      >
        {{ article.blogCategory.title }}
      </div>

      <div class="article-detail__head">
        <h1>{{ article.title }}</h1>
        <p>
          {{ 'news.item.published' | translate }}
          {{ article.publishedAt | date : 'd MMMM y HH:mm' }}
        </p>
        <div class="article-detail__image" *ngIf="article.image">
          <img [src]="getImageByArticle(article)" />
        </div>
      </div>

      <div class="article-detail__content" [innerHTML]="getText()"></div>
    </div>
  </div>
</div>

<div
  class="news__overview related container"
  *ngIf="relatedArticles && article"
>
  <div>
    <p>{{ 'news.item.related.title' | translate }}</p>
    <ul class="news__overview__items">
      <li
        *ngFor="let article of relatedArticles"
        (click)="openArticle(article)"
        class="article"
      >
        <div
          class="article__tag"
          [ngStyle]="{ background: article.blogCategory.color }"
          *ngIf="article.blogCategory.title"
        >
          {{ article.blogCategory.title }}
        </div>

        <div
          class="article__image"
          [style.backgroundImage]="getImageByArticle(article, true)"
          *ngIf="article.image"
        ></div>
        <div class="article__image noImage" *ngIf="!article.image"></div>

        <div class="article__content">
          <h3>{{ article.title }}</h3>
          <span>{{ article.publishedAt | date : 'MMMM d y HH:mm' }}</span>
        </div>
      </li>
    </ul>
  </div>
</div>

<app-loader *ngIf="!article"></app-loader>
