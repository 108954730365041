import { Component, Input } from '@angular/core';
import { Ticket } from '../interfaces/ticket';
import { TicketService } from '../services/ticket.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ErrorService } from '../services/error.service';
import { Project } from '../interfaces/project';
import { ProjectDataBusService } from '../services/project-data-bus.service';
import { UserDataService } from '../services/user-data.service';
import { TranslateModule } from '@ngx-translate/core';
import { TicketComponent } from './ticket.component';
import { LoadingDirective } from '../directives/loading.directive';
import { FormGroupComponent } from './form-group.component';
import { AccessDirective } from '../directives/access.directive';
import { NgIf, NgFor } from '@angular/common';
import { FormChangeDetectorDirective } from '../directives/form-change-detector.directive';
import { VersionDirective } from '../directives/version.directive';
import { LoaderComponent } from './loader.component';
import { SkeletonLoaderComponent } from './skeleton-loader.component';

@Component({
  selector: 'app-ticket-list',
  templateUrl: 'ticket-list.component.html',
  standalone: true,
  imports: [
    NgIf,
    AccessDirective,
    VersionDirective,
    FormChangeDetectorDirective,
    ReactiveFormsModule,
    FormGroupComponent,
    LoadingDirective,
    NgFor,
    TicketComponent,
    SkeletonLoaderComponent,
    LoaderComponent,
    TranslateModule,
  ],
})
export class TicketListComponent {
  public ticketForm: FormGroup;
  public project: Project = null;

  @Input() tickets: Ticket[];
  @Input() ticketsAllCount: number;
  @Input() shownStatus = '';
  @Input() withProject = false;
  @Input() createTicket = false;
  @Input() loading: boolean = false;

  constructor(
    private ticketService: TicketService,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private projectDataBusService: ProjectDataBusService,
    private userDataService: UserDataService
  ) {
    this.createTicketForm();

    this.projectDataBusService.projectObservable.subscribe(
      (project: Project) => {
        this.project = project;
      }
    );
  }

  async saveTicket(): Promise<void> {
    this.errorService.markFormGroupTouchedAndDirty(this.ticketForm);
    this.loading = true;

    if (this.ticketForm.valid) {
      const data = this.ticketForm.getRawValue();
      const projectUser = await this.userDataService.retrieveProjectUser();
      data.project = '/api' + TicketService.base + '/' + this.project.slug;
      data.name = data.name || projectUser.firstName;
      data.messages = [];
      data.messages.push({
        content: data.content,
      });
      this.ticketService
        .createTicket(data)
        .then((ticket: Ticket) => {
          this.tickets.push(ticket);
          this.ticketForm.reset();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  isEmpty() {
    return this.tickets.length === 0;
  }

  /**
   * Open a ticket to view it's contents
   */
  public async openTicket(ticket: Ticket, $event: any) {
    const tickets = await this.ticketService.getOpenTickets();

    if (tickets.indexOf(ticket) !== -1) {
      return;
    }

    try {
      for (const item of tickets) {
        item.open = false;
        await this.ticketService.closeTicket(item);
      }
    } catch (error) {
      this.errorService.logError(error);
    } finally {
      ticket.open = true;
      await this.ticketService.openTicket(ticket);
      if ($event) $event.stopPropagation();
    }
  }

  private createTicketForm(): void {
    this.ticketForm = this.formBuilder.group({
      subject: ['', Validators.required],
      content: ['', Validators.required],
      name: [],
    });
  }
}
