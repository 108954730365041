import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputFileComponent } from './input-file.component';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { AbstractControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingDirective } from '../directives/loading.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';

@Component({
  selector: 'app-image-cropbox',
  templateUrl: './image-cropbox.component.html',
  standalone: true,
  imports: [
    InlineSVGModule,
    ImageCropperComponent,
    LoadingDirective,
    TranslateModule,
  ],
})
export class ImageCropboxComponent implements OnInit {
  @Input() imageFormElement: AbstractControl;
  @Input() imageInput: InputFileComponent;
  @Input() aspectRatio: string;
  @Input() editing: boolean;
  @Input() imageChangedEvent: any;
  @Input() startUploadingEvent: any;
  @Input() stopUploadingEvent: any;
  @Output() editorClosed = new EventEmitter();

  croppedImage: any;
  cropperReady: boolean = false;
  canvasRotation: number = 0;
  imgLoading: boolean = false;
  uploading: boolean = false;

  ngOnInit(): void {
    this.startUploadingEvent.subscribe(() => (this.uploading = true));
    this.stopUploadingEvent.subscribe(() => (this.uploading = false));
  }

  base64ToFile(data: string, filename: string) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  imageCropped(event: ImageCroppedEvent) {
    const fileName =
      this.imageChangedEvent.target.files[0]?.name ?? this.croppedImage.name;
    this.croppedImage = this.base64ToFile(event.base64, fileName);
  }

  cropperIsReady() {
    this.cropperReady = true;
    this.imgLoading = false;
  }

  loadImageFailed() {
    this.cancel();
  }

  cancel() {
    this.editorClosed.emit();
    if (this.editing) {
      return;
    }
    this.imageChangedEvent = null;
    this.cropperReady = false;
    this.imageInput.clear();
  }

  upload() {
    this.imageInput.finishedEditing = true;
    this.imageInput.upload([this.croppedImage]);
    this.editorClosed.emit();
  }

  rotate() {
    this.imgLoading = true;
    setTimeout(() => {
      this.canvasRotation =
        this.canvasRotation === 3 ? 0 : this.canvasRotation + 1;
    });
  }
}
