import {
  Component,
  effect,
  input,
  OnInit,
  output,
  viewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ModalComponent } from 'src/app/components/modal.component';
import { ProjectUser } from 'src/app/interfaces/project-user';
import { ProjectUserService } from 'src/app/services/project-user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Project } from 'src/app/interfaces/project';
import { ErrorService } from '../../services/error.service';
import { Customer } from '../../interfaces/customer';
import { ManageUsersService } from './manage-users.service';
import { ConfirmDeleteComponent } from '../confirm-delete.component';
import { AccessService } from 'src/app/services/access.service';
import { AccessControlList } from 'src/app/interfaces/access-control-list';
import { SecurityVoter } from 'src/app/security/security-voter';
import { TranslateModule } from '@ngx-translate/core';
import { CreateUserComponent } from './create-user.component';
import { LoadingDirective } from '../../directives/loading.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormGroupComponent } from '../form-group.component';
import { ModalComponent as ModalComponent_1 } from '../modal.component';
import { NgIf, NgFor, KeyValuePipe, NgClass } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { VersionDirective } from 'src/app/directives/version.directive';
import { CustomerPickerComponent } from '../customer-picker.component';
import { ThemeService } from 'src/app/services/theme.service';
import { ProjectUserPermissions } from 'src/app/interfaces/project-user-permissions';
import { PermissionType } from 'src/app/enums/permission-type';
import { UserRolesType } from 'src/app/enums/user-roles-type';

@Component({
  selector: 'app-manage-users',
  templateUrl: 'manage-users.component.html',
  standalone: true,
  imports: [
    NgIf,
    ModalComponent_1,
    ReactiveFormsModule,
    FormGroupComponent,
    InlineSVGModule,
    NgFor,
    NgClass,
    NgSelectModule,
    LoadingDirective,
    CreateUserComponent,
    ConfirmDeleteComponent,
    TranslateModule,
    VersionDirective,
    CustomerPickerComponent,
    KeyValuePipe,
  ],
})
export class ManageUsersComponent implements OnInit {
  private readonly createModal = viewChild<ModalComponent>('create');
  public readonly confirmDeleteComponent =
    viewChild<ConfirmDeleteComponent>('confirmDelete');

  public readonly projectUsers = input<ProjectUser[]>();
  public customerInput = input<Customer>();
  public readonly project = input<Project>();
  public readonly loading = input<boolean>();
  public customer?: Customer;
  public createUser = output<{
    projectUser: ProjectUser;
    formData: any;
  }>();
  public editUser = output<{
    projectUser: ProjectUser;
    formData: any;
  }>();
  public selectedCustomer = output<Customer>();
  public deleteUser = output<ProjectUser>();
  public radios: {
    id: string;
    value: string;
    label: string;
    hidden: boolean;
  }[] = [
    {
      value: 'customer-edit',
      label: 'user.admin.create.type.account.admin',
      id: 'typeAdmin',
      hidden: false,
    },
    {
      value: 'project-create',
      label: 'user.admin.create.type.project.admin',
      id: 'typeManager',
      hidden: false,
    },
    {
      value: 'project-view',
      label: 'project.detail.settings.users.table.type.project_reader',
      id: 'typeViewer',
      hidden: false,
    },
    {
      value: 'project-edit',
      label: 'project.detail.settings.users.table.type.project_user',
      id: 'typeUser',
      hidden: false,
    },
  ];

  public form: FormGroup;

  public editingMapping: ProjectUser;
  public inviteSaved: boolean = false;
  public cannotSave: boolean = false;
  public editSaved: boolean = false;
  public formLoading: boolean = false;
  public formSaved: boolean = false;
  public deleted: boolean = false;
  private acl: AccessControlList;
  public showCreateProjectUser: boolean = false;
  public editing: boolean = false;
  public version: number;
  public showProjectError: boolean = false;
  private validators: any = [Validators.required, Validators.email];

  constructor(
    private errorService: ErrorService,
    private fb: FormBuilder,
    private projectUserService: ProjectUserService,
    private manageUsersService: ManageUsersService,
    private accessService: AccessService,
    private themeService: ThemeService,
  ) {
    this.accessService.accessControlList.subscribe((acl) => {
      this.acl = acl;
    });

    this.form = this.fb.group({
      email: [null, this.validators],
      permissionType: [null, [Validators.required]],
      projects: [null],
      create: [[]],
      edit: [[]],
      view: [[]],
      customer: [null],
    });

    effect(() => {
      this.customer = this.customerInput();
    });
  }

  projects(): any {
    return {
      create: this.form.get(PermissionType.CREATE).value,
      edit: this.form.get(PermissionType.EDIT).value,
      view: this.form.get(PermissionType.VIEW).value,
    };
  }

  getPrefix() {
    let prefix = !this.project()
      ? 'user.admin'
      : 'project.detail.settings.users';
    if (this.version === 2 && !this.project()) prefix = prefix + '.v2';
    return prefix;
  }

  getProjectName(projectId: number) {
    return this.customer?.projects.find((project) => project.id === projectId)
      .name;
  }

  hideRadios(create: boolean = false) {
    if (!this.project()) {
      this.radios.map((item) => {
        if (['typeViewer', 'typeUser'].includes(item.id)) {
          item.hidden = true;
        }
      });
      return;
    }
    if (
      false ===
      SecurityVoter.isAccountAdminForCustomer(this.acl, this.project().customer)
    ) {
      this.radios.map((item) => {
        if (['typeAdmin', 'typeManager'].includes(item.id)) {
          item.hidden = true;
        }
      });
      return;
    }
    this.radios.map((item) => {
      item.hidden = false;
    });
  }

  getFormActionButtonText() {
    return !this.editing
      ? 'project.detail.settings.users.create.continue'
      : 'project.detail.settings.users.edit.save';
  }

  async ngOnInit(): Promise<void> {
    this.version = await this.themeService.getVersion();

    this.manageUsersService.deleteEvent$.subscribe((projectUser: any) => {
      this.confirmDeleteComponent().confirmDelete(projectUser);
    });

    this.manageUsersService.editEvent$.subscribe((projectUser: any) => {
      this.openModal(projectUser);
    });
  }

  private removeValidators() {
    this.form.get('email').clearValidators();
    this.form.get('email').updateValueAndValidity();
  }

  private addValidators() {
    this.form.get('email').setValidators(this.validators);
    this.form.get('email').updateValueAndValidity();
  }

  public updateProjectPermission(event: any, projectId: number) {
    const permissions: Record<string, number[]> = (this.editingMapping as any)
      .permissions;
    Object.entries(permissions).find(([key, value]) => {
      if (value.includes(projectId)) {
        permissions[key] = value.filter((item) => item !== projectId);
      }
    });
    (this.editingMapping as any).permissions[event.target.value].push(
      projectId
    );
  }

  public openModal(projectUser?: ProjectUser): void {
    if (projectUser !== undefined) {
      this.hideRadios();

      this.editing = true;
      this.editingMapping = projectUser;
      if (!this.project()) {
        this.form.patchValue({
          permissionType:
            projectUser.sortedPermissions[UserRolesType.ACCOUNT_ADMIN].length >
            0
              ? 'customer-edit'
              : 'project-create',
          projects: projectUser.sortedPermissions[UserRolesType.PROJECT_ADMIN]
            .filter(
              (permission: ProjectUserPermissions) =>
                permission.permissionType === PermissionType.CREATE &&
                permission.customer === undefined
            )
            .map((obj) => {
              return obj.project.id;
            }),
          create: projectUser.sortedPermissions[UserRolesType.PROJECT_ADMIN]
            .filter(
              (permission: ProjectUserPermissions) =>
                permission.permissionType === PermissionType.CREATE &&
                permission.customer === undefined
            )
            .map((obj) => {
              return obj.project.id;
            }),
          edit: projectUser.sortedPermissions[UserRolesType.PROJECT_USER]
            .filter(
              (permission: ProjectUserPermissions) =>
                permission.permissionType === PermissionType.EDIT
            )
            .map((obj) => {
              return obj.project.id;
            }),
          view: projectUser.sortedPermissions[UserRolesType.PROJECT_READER]
            .filter(
              (permission: ProjectUserPermissions) =>
                permission.permissionType === PermissionType.VIEW
            )
            .map((obj) => {
              return obj.project.id;
            }),
        });
        (this.editingMapping as any).permissions = structuredClone(
          this.projects()
        );
      } else {
        const prefix =
          projectUser.sortedPermissions[UserRolesType.ACCOUNT_ADMIN].length > 0
            ? 'customer-'
            : 'project-';
        this.form.patchValue({
          permissionType:
            prefix + this.manageUsersService.getPermissionsType(projectUser),
          project: null,
        });
      }
    } else {
      this.hideRadios(true);

      this.editingMapping = {} as ProjectUser;
      this.form.patchValue({
        permissionType: null,
        project: null,
      });
      this.editing = false;
      (this.editingMapping as any).permissions = structuredClone(
        this.projects()
      );
    }
    this.createModal().open();
  }

  removeProject(index: number, type: PermissionType) {
    this.form.get(type).value.splice(index, 1);
    (this.editingMapping as any).permissions[type].splice(index, 1);
  }

  addProject(id: number) {
    if (!id) {
      return;
    } else if (
      this.form.get(PermissionType.CREATE)?.value.includes(+id) ||
      this.form.get(PermissionType.EDIT)?.value.includes(+id) ||
      this.form.get(PermissionType.VIEW)?.value.includes(+id)
    ) {
      this.showProjectError = true;
      return;
    }
    this.form.get(PermissionType.CREATE).value.push(+id);
    if (this.editingMapping) {
      (this.editingMapping as any).permissions[PermissionType.CREATE].push(+id);
    }
  }

  public async check() {
    if (this.editingMapping) {
      this.form.patchValue((this.editingMapping as any).permissions);
    }

    if (this.editing) {
      this.removeValidators();
    }
    this.errorService.markFormGroupTouchedAndDirty(this.form);
    if (!this.form.valid) {
      return;
    }

    this.formLoading = true;
    let permissions = this.preparePermissionsForSubmission();

    try {
      if (this.editing) {
        this.editUser.emit({
          projectUser: this.editingMapping,
          formData: permissions,
        });
      } else {
        const user = await this.projectUserService.check(this.form.value.email);
        this.createUser.emit({
          projectUser: user.projectUser,
          formData: permissions,
        });
      }
      this.createModal().close();
    } catch (error) {
      if ((error as HttpErrorResponse).status === 404) {
        this.showCreateProjectUser = true;
      } else {
        this.errorService.parseErrorsToForm(this.form, error.error, {
          'data.type': 'type',
        });
        console.error(error);
      }
    } finally {
      this.formLoading = false;
      this.addValidators();
    }
  }

  public preparePermissionsForSubmission() {
    let permissions = {};

    const { prefix, permission } =
      this.manageUsersService.extractPrefixAndValue(
        this.form.value.permissionType
      );

    if (this.version === 1) {
      permissions[permission] = {
        customer: this.customer?.id ?? this.project().customer.id,
        project:
          this.project() && prefix === 'project'
            ? [this.project().id]
            : this.form.value.projects,
      };
    } else {
      if (this.form.get('permissionType').value === 'customer-edit') {
        permissions = {
          edit: {
            customer: this.customer?.id ?? this.project().customer.id,
          },
        };
      } else {
        permissions = Object.fromEntries(
          Object.entries(this.projects())
            .filter(([key, value]) => Array.isArray(value) && value.length > 0)
            .map(([key, value]) => [
              key,
              {
                customer: this.customer?.id ?? this.project().customer.id,
                project: value,
              },
            ])
        );

        if (Object.entries(permissions).length === 0) {
          permissions[permission] = {
            customer: this.customer?.id ?? this.project().customer.id,
            project:
              this.project() && prefix === 'project'
                ? [this.project().id]
                : null,
          };
        }
      }
    }

    return permissions;
  }

  public reset(): void {
    this.form.reset({
      create: [],
      edit: [],
      view: [],
      customer: this.customer?.id ?? this.project().customer.id,
    });

    this.showProjectError = false;
    this.showCreateProjectUser = false;
  }

  public handleUserCreated(data: any) {
    let permissions = this.preparePermissionsForSubmission();

    this.createUser.emit({ projectUser: data, formData: permissions });

    this.reset();
    this.createModal().close();
  }

  public triggerDelete(projectUser: ProjectUser) {
    this.deleteUser.emit(projectUser);
  }

  public changeCustomer(customer: Customer) {
    this.customer = customer;
    this.form.get('customer').patchValue(customer);
    this.selectedCustomer.emit(customer);
  }
}
