import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../environments/environment';
import * as Sentry from '@sentry/browser';

@Injectable()
export class ErrorService {
  /**
   * @param error
   */
  public logError(error) {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(error);
    }
  }

  /**
   * @param form
   * @param response
   * @param aliases Used to map errors to an other field
   */
  public parseErrorsToForm(form: AbstractControl, response: any, aliases = {}) {
    const violations = response.violations;

    if (violations != null && violations.length > 0) {
      for (const violation of violations) {
        let path = violation.propertyPath;
        const message = violation.message;

        if (aliases[path] != null) {
          path = aliases[path];
        }

        const control = this.findControl(form, path);

        if (control == null) {
          continue;
        }

        let existingMessages = [];

        if (control.errors != null && control.errors.server) {
          existingMessages = control.errors.server;
        }

        existingMessages.push(message);

        control.setErrors({
          server: existingMessages,
        });
      }
    } else {
      this.logError(response);
    }
  }

  /**
   * @param form
   * @param path
   * @returns {AbstractControl}
   */
  public findControl(form: AbstractControl, path: string): AbstractControl {
    const pathParts = path.split('.');
    let control: AbstractControl = form;

    for (const pathPart of pathParts) {
      control = control.get(pathPart);
    }

    return control;
  }

  /**
   * Marks all controls in a form group as touched, so errors will be shown upon submit
   * @link https://stackoverflow.com/questions/40529817/reactive-forms-mark-fields-as-touched
   * @param formGroup
   */
  public markFormGroupTouchedAndDirty(formGroup: FormGroup) {
    Object.keys(formGroup.controls).map((key) => {
      const control: any = formGroup.controls[key];

      control.markAsTouched();
      control.markAsDirty();

      if (control.controls && typeof control.controls === 'object') {
        for (const subKey in control.controls) {
          if (control.controls.hasOwnProperty(subKey)) {
            const element = control.controls[subKey];

            if (element instanceof FormControl) {
              element.markAsTouched();
              element.markAsDirty();
            } else {
              this.markFormGroupTouchedAndDirty(element);
            }
          }
        }
      } else if (control.controls) {
        control.controls.forEach((c) => this.markFormGroupTouchedAndDirty(c));
      }
    });
  }

  /**
   * Scrolls to an invalid form-group div (so which has the has-error class)
   * This function is only meant to be used on single-form pages, containing a lot of fields
   * @returns {Promise<boolean>}
   */
  public scrollToInvalidFormGroup(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // wait for bindings to update
        const errorGroups = document.querySelectorAll('.form-group.has-error');

        if (errorGroups.length === 0) {
          resolve(false);
        } else {
          errorGroups[0].scrollIntoView(true);

          resolve(true);
        }
      });
    });
  }
}
