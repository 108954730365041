import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'countdown',
  standalone: true,
})
export class CountdownPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(date: Date, ...args: any[]): any {
    let duration = moment.duration(moment(date).diff(moment()));
    let days = Math.floor(duration.asDays());
    let hours = duration.hours();
    let minutes = duration.minutes();
    let dateParts = [];

    if (days > 7) {
      if (hours > 12) days++;
      dateParts.push(`${days} ${this.translateService.instant('days')}`);
    } else if (days > 0) {
      dateParts.push(
        `${days} ${this.translateService.instant(days == 1 ? 'day' : 'days')}`
      );
      if (hours > 0)
        dateParts.push(
          `${hours} ${this.translateService.instant(
            hours == 1 ? 'hour' : 'hours'
          )}`
        );
    } else if (hours > 0) {
      dateParts.push(
        `${hours} ${this.translateService.instant(
          hours == 1 ? 'hour' : 'hours'
        )}`
      );
      if (minutes > 0)
        dateParts.push(
          `${minutes} ${this.translateService.instant(
            minutes == 1 ? 'minute' : 'minutes'
          )}`
        );
    } else {
      return this.translateService.instant('less-than-hour');
    }

    return dateParts.join(` ${this.translateService.instant('and')} `);
  }
}
