<div class="update">
  <div *appVersion="1" class="update__timeline">
    <div class="line"></div>
    <div class="date">
      {{ update.publicationDate | date : 'd MMM' }}
    </div>
  </div>

  <div
    *appVersion="1"
    class="update__box"
    [class.tileHasAttachments]="update.attachments.length > 0"
  >
    <div class="update__box__content">
      <div class="project-name" *ngIf="isCustomer">
        <a [routerLink]="'/projects/' + update.project?.slug">{{
          update.project?.name
        }}</a>
      </div>
      <div
        class="update__box__content__actions flex flex-row"
        *ngIf="!update.ratingMonitorPush"
        appAccess
        role="manager"
        [project]="update.project"
      >
        <a routerLink="/{{ prefix }}/updates/{{ update.id }}/edit" class="edit">
          <div [inlineSVG]="'/assets/img/icons/edit-small.svg'"></div>
        </a>
        <div
          [inlineSVG]="'/assets/img/icons/delete.svg'"
          (click)="
            confirmDelete.confirmDelete(update);
            $event.stopPropagation();
            (false)
          "
          class="delete"
        ></div>
      </div>

      <p class="update__box__content__date">
        {{ update.publicationDate | date : 'd MMMM y' }}
        <span
          *ngIf="update.status == 'CONCEPT'"
          class="update__box__content__concept"
          >{{ 'CONCEPT' | translate }}</span
        >
      </p>

      <span
        class="update__box__content__clock"
        [inlineSVG]="'/assets/img/icons/clock.svg'"
        *ngIf="isFuture(update)"
      ></span>

      <a
        *ngIf="update.status == 'CONCEPT' && getPreviewUrl()"
        [cbContent]="getPreviewUrl()"
        ngxClipboard
        (cbOnSuccess)="urlCopied()"
        class="update__box__content__preview"
      >
        {{ 'projects.detail.update.preview.copy' | translate }}
        <span
          [inlineSVG]="'/assets/img/icons/check.svg'"
          [hidden]="!copied"
        ></span>
      </a>

      <div class="update__box__content__text" [class.break]="hasBreak()">
        <div
          *ngIf="!update.ratingMonitorPush"
          [innerHTML]="
            update.content
              | words : (update.attachments.length === 0 ? 50 : 30) : '...'
          "
        ></div>
        <div *ngIf="update.ratingMonitorPush">
          <button class="btn btn-primary" disabled>
            {{ 'projects.detail.update.tile.rate' | translate }}
          </button>
        </div>
      </div>

      <div
        class="update__box__content__attachments"
        *ngIf="update.attachments.length > 0"
      >
        <ul>
          <ng-container
            *ngFor="
              let attachment of update.attachments.slice(
                0,
                moreAttachments ? update.attachments.length : 3
              )
            "
          >
            <li
              *ngIf="!isVideo(attachment)"
              [style.backgroundImage]="getAttachmentImage(attachment)"
            >
              <div
                *ngIf="attachment.is360"
                inlineSVG="/assets/img/icons/360.svg"
                [attr.aria-label]="
                  'projects.detail.update.tile.360' | translate
                "
              ></div>
            </li>
            <li *ngIf="isVideo(attachment)">
              <video preload="metadata" width="100%" height="100%">
                <source [src]="getAttachmentVideo(attachment)" />
              </video>
              <div
                class="video-icon"
                inlineSVG="/assets/img/icons/type-video.svg"
              ></div>
            </li>
          </ng-container>
        </ul>

        <div
          *ngIf="update.attachments.length >= 4 && !moreAttachments"
          class="update__box__content__attachments__more"
          (click)="viewMoreAttachments()"
          [style.backgroundImage]="getAttachmentImage(update.attachments[3])"
        >
          <span>+{{ update.attachments.length - 3 }}</span>
        </div>
      </div>

      <app-poll *ngIf="update.poll != null" [poll]="update.poll"></app-poll>
    </div>
  </div>

  <div
    *appVersion="2"
    class="update__box"
    [class.standalone]="standalone"
    [routerLink]="!standalone ? ['/' + prefix, 'updates', update.id] : ['edit']"
  >
    <div class="update__box__overlay">
      <div class="update__box__overlay__edit">
        <div inlineSVG="/assets/img/icons/edit.svg"></div>
        {{ 'projects.detail.update.edit.edit' | translate }}
      </div>
    </div>
    <div class="update__box__content">
      <div class="update__box__content__top">
        <p class="title">
          <span>
            {{ update.publicationDate | date : 'longDate' }}
            {{ update.publicationDate | date : 'shortTime' }}
          </span>
          <span>
            <ng-container *ngIf="update.project && isCustomer">
              {{ update.project.name }}
            </ng-container>
            <ng-container *ngIf="!update.project && isCustomer">
              {{ update.customer.name }}
            </ng-container>
          </span>
        </p>
        <p
          class="scheduled"
          *ngIf="isFuture(update) && update.status !== 'CONCEPT'"
        >
          <span [inlineSVG]="'/assets/v2/img/icons/clock.svg'"></span>
          <span
            >{{ 'projects.detail.update.list.scheduled' | translate }}
            {{ update.publicationDate | date : 'shortDate' }}
            {{ update.publicationDate | date : 'shortTime' }}</span
          >
        </p>
        <p class="concept" *ngIf="update.status == 'CONCEPT'">
          {{ 'CONCEPT' | translate }}
        </p>
      </div>

      <div class="update__box__content__text" [class.break]="hasBreak()">
        <div
          *ngIf="!update.ratingMonitorPush"
          [innerHTML]="
            update.content
              | words : (update.attachments.length === 0 ? 50 : 30) : '...'
          "
        ></div>
        <div *ngIf="update.ratingMonitorPush">
          <button class="btn btn-primary" disabled>
            {{ 'projects.detail.update.tile.rate' | translate }}
          </button>
        </div>
      </div>

      <div
        class="update__box__content__attachments"
        *ngIf="update.attachments.length > 0"
      >
        <ul>
          <ng-container *ngFor="let attachment of update.attachments">
            <li
              *ngIf="!isVideo(attachment)"
              [style.backgroundImage]="getAttachmentImage(attachment)"
            >
              <div
                *ngIf="attachment.is360"
                class="overlay"
                inlineSVG="/assets/img/icons/360.svg"
                [attr.aria-label]="
                  'projects.detail.update.tile.360' | translate
                "
              ></div>
              <div class="video-icon" *ngIf="isYoutubeVideo(attachment)">
                <img src="{{ '/assets/v2/img/icons/youtube.png' | fileUrl }}" />
              </div>
            </li>
            <li *ngIf="isVideo(attachment)">
              <video preload="metadata" width="100%" height="100%">
                <source [src]="getAttachmentVideo(attachment)" />
              </video>
              <div
                class="video-icon"
                inlineSVG="/assets/img/icons/type-video.svg"
              ></div>
            </li>
          </ng-container>
        </ul>
      </div>

      <app-poll *ngIf="update.poll != null" [poll]="update.poll"></app-poll>

      <div class="update__box__content__bottom" *ngIf="!standalone">
        <span>
          <span inlineSVG="/assets/v2/img/icons/eye.svg"></span>
          <span
            >{{ update.viewCount }}
            {{ 'projects.detail.update.list.views' | translate }}</span
          >
        </span>
      </div>
    </div>
  </div>
</div>
