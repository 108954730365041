import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { ProjectUserService } from '../services/project-user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private oauthService: OAuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (
            request.url.includes(ProjectUserService.base + '/check-token') ||
            request.url.includes('auth/token')
          ) {
            this.oauthService.logOut();
            this.router.navigate(['/login']);
            return throwError(() => error);
          }
          // Let the OAuthInterceptor handle the token refresh
          return throwError(() => error);
        }
        return throwError(() => error);
      })
    );
  }
}
