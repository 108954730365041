import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ErrorService } from '../../../../services/error.service';
import { ProjectDataBusService } from '../../../../services/project-data-bus.service';
import { ProjectService } from '../../../../services/project.service';
import { PhaseService } from '../../../../services/phase.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { Project } from '../../../../interfaces/project';
import { Phase } from '../../../../interfaces/phase';
import * as moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingDirective } from '../../../../directives/loading.directive';
import { DatePickerComponent } from '../../../../components/date-picker.component';
import { QuillComponent } from '../../../../components/quill.component';
import { FormGroupComponent } from '../../../../components/form-group.component';
import { LoaderComponent } from '../../../../components/loader.component';
import { NgIf } from '@angular/common';
import { MultiAttachmentControlComponent } from 'src/app/components/multi-attachment-control.component';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { VersionDirective } from 'src/app/directives/version.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { platform } from 'src/app/services/platform.service';
import { DatePickerV2Component } from 'src/app/components/date-picker-v2.component';

@Component({
  selector: 'app-default-projects-detail-phases-edit',
  templateUrl: 'edit.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    ReactiveFormsModule,
    FormChangeDetectorDirective,
    FormGroupComponent,
    QuillComponent,
    DatePickerComponent,
    DatePickerV2Component,
    MultiAttachmentControlComponent,
    RouterLink,
    LoadingDirective,
    TranslateModule,
    VersionDirective,
    InlineSVGModule,
  ],
})
export class DetailPhasesEditComponent {
  public form: FormGroup;
  public project: Project;
  public edit = false;
  public loading = false;
  public phase: Phase;
  public uploading = false;
  public dateRangeValid = true;
  private id: number;
  public language: string;

  constructor(
    private projectDataBusService: ProjectDataBusService,
    private projectService: ProjectService,
    private formBuilder: FormBuilder,
    private phaseService: PhaseService,
    private errorService: ErrorService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.createForm();
    this.projectDataBusService.projectObservable.subscribe((project) => {
      this.project = project;
      this.language = this.project?.language || platform.language;
    });

    this.activatedRoute.params.subscribe((params) => {
      if (params['id'] != null) {
        this.id = params['id'];
        this.edit = true;

        this.loadPhase(this.id);
      } else {
        this.id = null;
        this.edit = false;
      }
    });

    window.scroll(0, 0);
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      text: ['', Validators.required],
      name: [null, [Validators.required]],
      attachments: [[]],
      startDate: [moment().format(), Validators.required],
      startType: ['EXACT_DATE', Validators.required],
      endDate: [moment().format(), Validators.required],
      endType: ['EXACT_DATE', Validators.required],
    });

    this.form.get('startDate').valueChanges.subscribe((startDate) => {
      this.validateDatesForProject(startDate, this.form.get('endDate').value);
    });

    this.form.get('endDate').valueChanges.subscribe((endDate) => {
      this.validateDatesForProject(this.form.get('startDate').value, endDate);
    });
  }

  async save(): Promise<void> {
    if (this.uploading) {
      return;
    }

    this.errorService.markFormGroupTouchedAndDirty(this.form);

    this.form
      .get('startDate')
      .patchValue(
        moment(this.form.get('startDate').value)
          .set({ h: 11, m: 0, s: 0 })
          .utcOffset('+01:00', true)
          .format()
      );
    this.form
      .get('endDate')
      .patchValue(
        moment(this.form.get('endDate').value)
          .set({ h: 11, m: 0, s: 0 })
          .utcOffset('+01:00', true)
          .format()
      );

    this.validateDatesForProject(
      this.form.get('startDate'),
      this.form.get('endDate')
    );

    if (this.form.valid && this.dateRangeValid) {
      this.loading = true;

      try {
        const data = this.form.getRawValue();

        if (data.attachments) {
          Object.keys(data.attachments).forEach((key) => {
            if (
              !data.attachments[key].filePath &&
              !data.attachments[key].videoId
            ) {
              delete data.attachments[key];
            }
          });
        }

        if (this.edit) {
          data.id = this.id;

          await this.phaseService.update(data);
        } else {
          await this.phaseService.create(this.project, data);
        }

        this.router.navigate(['/projects', this.project.slug, 'phases']);
        this.projectService.updateLastEdited(this.project);
      } catch (error) {
        this.errorService.parseErrorsToForm(this.form, error.error);
      } finally {
        this.loading = false;
      }
    } else {
      await this.errorService.scrollToInvalidFormGroup();
    }
  }

  startUploading() {
    this.uploading = true;
  }

  stopUploading() {
    this.uploading = false;
  }

  async loadPhase(id: number): Promise<void> {
    try {
      this.phase = await this.phaseService.fetch(id);

      this.form.patchValue(this.phase);
    } catch (error) {
      this.router.navigate(['/404']);
    }
  }

  /**
   * Validate phase date ranges
   */
  public validateDatesForProject(startDate, endDate) {
    this.dateRangeValid = true;

    if (moment(endDate) < moment(startDate)) {
      const nextDay = moment(startDate).add(1, 'days').format();
      this.form.get('endDate').setValue(nextDay);

      this.dateRangeValid = false;
    }
  }
}
