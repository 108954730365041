<div class="bouwapp-header" [hidden]="mode !== 'configure'">
  <div class="bouwapp-header__text">
    <h1>
      {{ 'project.detail.satisfaction_monitor.configure.title' | translate }}
    </h1>
  </div>
</div>

<div class="bouwapp-header" [hidden]="mode !== 'results'">
  <div class="bouwapp-header__text">
    <h1>
      {{ 'project.detail.satisfaction_monitor.results.title' | translate }}
    </h1>
  </div>
  <div class="bouwapp-header__buttons">
    <button
      (click)="switchToConfigure()"
      class="secondary"
      appAccess
      role="manager"
      [project]="project"
    >
      {{ 'project.detail.satisfaction_monitor.results.configure' | translate }}
    </button>
    <button
      (click)="exportAllGraphs()"
      [hidden]="loadingResults"
      class="primary"
    >
      {{ 'project.detail.satisfaction_monitor.results.export_all' | translate }}
    </button>
  </div>
</div>

<section class="configure-satisfaction-monitor" [hidden]="mode !== 'configure'">
  <app-loader [hidden]="themes"></app-loader>
  <div *ngIf="themes">
    <div class="alert alert-info">
      {{ 'project.detail.satisfaction_monitor.configure.warning' | translate }}
    </div>

    <div class="alert alert-danger" [hidden]="!error">
      {{ 'project.detail.satisfaction_monitor.configure.error' | translate }}
    </div>

    <div class="themes">
      <h4>
        {{
          'project.detail.satisfaction_monitor.configure.themes.title'
            | translate
        }}
        <span class="count"
          >{{ project.ratingThemes.length }}/{{ MAX_THEMES }}</span
        >
      </h4>
      <p>
        {{
          'project.detail.satisfaction_monitor.configure.themes.introduction'
            | translate
        }}
      </p>
      <p
        class="survey-notice"
        [innerHTML]="
          'project.detail.satisfaction_monitor.configure.themes.survey_notice'
            | translate
        "
      ></p>

      <div>
        <div class="search">
          <input
            type="search"
            class="input-lg"
            [attr.placeholder]="
              'project.detail.satisfaction_monitor.configure.themes.search'
                | translate
            "
            [formControl]="searchControl"
            appAccess
            role="manager"
            [project]="project"
            property="readonly"
          />
        </div>
        <div class="items">
          <div
            class="item"
            *ngFor="let item of themes"
            [class.required]="item.required"
            [class.active]="item.active"
            (click)="toggleTheme(item)"
            appAccess
            role="manager"
            [project]="project"
            property="disabled"
          >
            <div class="text">
              <span class="title"
                >{{ item.title }}
                <span [hidden]="!item.required">{{
                  'project.detail.satisfaction_monitor.configure.themes.required'
                    | translate
                }}</span></span
              >
            </div>
          </div>
          <p [hidden]="themes.length > 0">
            {{
              'project.detail.satisfaction_monitor.configure.themes.no_results'
                | translate
            }}
          </p>
        </div>
      </div>

      <div
        class="justified-buttons"
        appAccess
        role="manager"
        [project]="project"
      >
        <button
          [appLoading]="loading"
          (click)="configure()"
          class="btn btn-lg"
          [hidden]="editing"
        >
          {{
            'project.detail.satisfaction_monitor.configure.themes.activate'
              | translate
          }}
        </button>
        <button
          [appLoading]="loading"
          (click)="configure()"
          class="btn btn-lg"
          [hidden]="!editing"
        >
          {{
            'project.detail.satisfaction_monitor.configure.themes.update'
              | translate
          }}
        </button>
      </div>
    </div>
  </div>
</section>

<section class="satisfaction-monitor" [hidden]="mode !== 'results'">
  <div>
    <div class="settings">
      <app-period [formControl]="periodControl"></app-period>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <app-data-filter
          *ngIf="demography"
          [demography]="demography"
          (filter)="handleFilter($event)"
        ></app-data-filter>
      </div>

      <div class="col-sm-9">
        <app-loader [hidden]="results"></app-loader>

        <div *ngIf="results">
          <app-chart
            *ngFor="let result of [results[0]]"
            [csvExport]="false"
            [title]="result.title"
            [stripZeroValues]="true"
            [tooltip]="determineChartTooltipTitle"
            [data]="result.data"
            [type]="'line'"
            [maximum]="5"
          ></app-chart>
          <app-chart
            [csvExport]="false"
            [title]="results[1].title"
            [data]="results[1].data"
            [type]="'satisfaction-distribution'"
          ></app-chart>
        </div>
      </div>
    </div>

    <br />
    <br />
    <hr />

    <div class="push-notification" appAccess role="manager" [project]="project">
      <h3>
        {{ 'project.detail.satisfaction_monitor.results.push' | translate }}
      </h3>
      <div class="row">
        <div class="col-sm-6">
          <p>
            {{
              'project.detail.satisfaction_monitor.results.instruction'
                | translate
            }}
            <a href="javascript:void(0)" (click)="toggleTargetPicker()">{{
              'project.detail.satisfaction_monitor.results.select_targets'
                | translate
            }}</a>
          </p>

          <div class="targets" [class.not-selected]="!showTargetPicker">
            <app-target-picker
              [project]="project"
              [multiple]="true"
              [label]="false"
              [formControl]="targetPicker"
            ></app-target-picker>
          </div>

          <div class="push">
            <button
              (click)="sendPushNotification()"
              class="btn btn-primary btn-lg"
              [attr.disabled]="!canPushRating() || sendingPush ? true : null"
            >
              {{
                showTargetPicker
                  ? ('project.detail.satisfaction_monitor.results.send_push'
                    | translate)
                  : ('project.detail.satisfaction_monitor.results.send_push_to_users'
                    | translate : { starredBy: project.starredBy })
              }}
            </button>
            <div class="disabled" [hidden]="canPushRating()">
              {{
                'project.detail.satisfaction_monitor.results.timeout'
                  | translate : { timeTillPush: getTimeTillRatingPush() }
              }}
            </div>
          </div>

          <div class="alert alert-danger" [hidden]="!sendingPushFailed">
            {{
              'project.detail.satisfaction_monitor.results.error' | translate
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
