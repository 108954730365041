<div *appVersion="1" class="breadcrumbs">
  <div class="container flex flex-row">
    <ul class="flex flex-row">
      <li *ngIf="current === 'news'">
        <a routerLink="/news">{{ 'breadcrumbs.blog.news' | translate }}</a>
      </li>
      <li *ngIf="current === 'knowledge'">
        <a routerLink="/knowledge">{{
          'breadcrumbs.blog.knowledge' | translate
        }}</a>
      </li>
      <li
        *ngIf="
          current === 'project-list' ||
          current === 'project-view' ||
          current === 'project-create'
        "
      >
        <a routerLink="/">{{ 'breadcrumbs.projects' | translate }}</a>
      </li>
      <li *ngIf="current === 'profile-company'">
        <a routerLink="/profile/company/{{ currentCustomerId }}">{{
          'breadcrumbs.company' | translate
        }}</a>
      </li>
      <li *ngIf="current === 'profile-admin'">
        <a routerLink="/profile/company/admin/{{ currentCustomerId }}">{{
          'breadcrumbs.user_admin' | translate
        }}</a>
      </li>
      <li *ngIf="current === 'profile-personal'">
        <a routerLink="/profile/personal">{{
          'breadcrumbs.profile' | translate
        }}</a>
      </li>
      <li *ngIf="current === 'profile-password'">
        <a routerLink="/profile/password">{{
          'breadcrumbs.password' | translate
        }}</a>
      </li>
      <li *ngIf="current === 'profile-export'">
        <a routerLink="/profile/export">{{
          'breadcrumbs.export' | translate
        }}</a>
      </li>
      <li *ngIf="current === 'tickets'">
        <a routerLink="/tickets">{{ 'breadcrumbs.tickets' | translate }}</a>
      </li>
      <li *ngIf="current === 'project-create'" class="secondary">
        {{ 'breadcrumbs.new_project' | translate }}
      </li>
      <li
        *ngIf="
          current === 'smart-reporting' || current === 'smart-reporting-detail'
        "
      >
        <a routerLink="/smart-reporting">{{
          'breadcrumbs.smart_reporting' | translate
        }}</a>
      </li>
      <li *ngIf="current === 'smart-reporting-detail'" class="secondary">
        {{ 'breadcrumbs.smart_reporting_detail' | translate }}
      </li>
      <li
        *ngIf="current === 'project-view' && projects"
        [ngClass]="{ projects: current === 'project-view' && projects }"
      >
        <select
          [attr.title]="'breadcrumbs.navigate' | translate"
          [formControl]="control"
        >
          <option *ngFor="let project of projects" [value]="project.slug">
            {{ project.name }}
          </option>
        </select>
      </li>
      <ng-container *ngIf="current === 'article-view' && article">
        <li>
          <a *ngIf="article.blogCategory.type === 'news'" routerLink="/news">{{
            'breadcrumbs.blog.news' | translate
          }}</a>
          <a
            *ngIf="article.blogCategory.type === 'knowledge'"
            routerLink="/knowledge"
            >{{ 'breadcrumbs.blog.knowledge' | translate }}</a
          >
        </li>
        <li>
          {{ article.title }}
        </li>
      </ng-container>
    </ul>

    <div
      class="last-edited"
      *ngIf="
        showLastEdited &&
        currentProject?.lastChangedBy != null &&
        currentProject?.updatedAt != null
      "
    >
      {{
        'breadcrumbs.edit'
          | translate
            : {
                user: currentProject.lastChangedBy.firstName,
                date: (currentProject.updatedAt | date : 'dd-MM-yyyy HH:mm')
              }
      }}
    </div>
  </div>
</div>
