<div class="app">
  <app-header></app-header>

  <div class="app__content">
    <div class="container full">
      <h2>{{ 'page-not-found.title' | translate }}</h2>
      <p>
        {{ 'page-not-found.text' | translate }}
      </p>
    </div>
  </div>

  <app-footer></app-footer>
</div>
