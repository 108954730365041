import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { UpdatePickerComponent } from 'src/app/components/update-helpers/update-picker.component';
import { Customer } from 'src/app/interfaces/customer';
import { Project } from 'src/app/interfaces/project';
import { Update } from 'src/app/interfaces/update';
import { AccessService } from 'src/app/services/access.service';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { UpdateService } from 'src/app/services/update.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { SecurityVoter } from 'src/app/security/security-voter';
import { UpdateStatus } from 'src/app/enums/update-status';
import { ActivatedRoute, Router } from '@angular/router';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { LoaderComponent } from 'src/app/components/loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ConfirmDeleteComponent } from 'src/app/components/confirm-delete.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { DateTypePipe } from 'src/app/pipes/date-type.pipe';
import { UpdatesTileComponent } from './tile.component';
import { AccessDirective } from 'src/app/directives/access.directive';
import { VersionDirective } from 'src/app/directives/version.directive';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Sort } from 'src/app/interfaces/sort';
import { ThemeService } from 'src/app/services/theme.service';
import { ProjectStatus } from 'src/app/enums/project-status';
import { FormGroupComponent } from 'src/app/components/form-group.component';

@Component({
  selector: 'app-default-updates-list',
  templateUrl: 'list.component.html',
  standalone: true,
  imports: [
    NgClass,
    VersionDirective,
    ReactiveFormsModule,
    LoaderComponent,
    NgIf,
    NgForOf,
    TranslateModule,
    InfiniteScrollDirective,
    UpdatePickerComponent,
    ConfirmDeleteComponent,
    AccessDirective,
    InlineSVGModule,
    DateTypePipe,
    UpdatesTileComponent,
    FormGroupComponent,
  ],
})
export class UpdatesListComponent implements OnInit {
  @ViewChild(UpdatePickerComponent) updatePicker: UpdatePickerComponent;

  public project?: Project;
  public projects: Project[];
  public customers: Customer[];
  public isCustomer: boolean = false;

  public updates: Update[];

  private perPage: number = 20;

  private page: number = 0;
  private ready = false;

  public totalItems: number;
  public version: number;

  private readonly defaultStatuses = [
    UpdateStatus.PUBLISHED,
    UpdateStatus.SCHEDULED,
    UpdateStatus.CONCEPT,
  ];

  private search = null;
  private statuses = null;
  private startDate = null;
  private endDate = null;
  public statusCount;
  public loading: boolean = false;

  public projectIds: number[] | null;
  public customerIds: number[] | null;

  public filterForm: FormGroup;

  constructor(
    private projectDataBusService: ProjectDataBusService,
    private updateService: UpdateService,
    private userDataService: UserDataService,
    private accessService: AccessService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private themeService: ThemeService
  ) {
    this.createFilterForm();
  }

  /**
   * Loads till the page is filled, so an user will be able to scroll down to see more
   * @returns {Promise<void>}
   */
  async loadUpdates(): Promise<void> {
    await this.loadMoreUpdates();
  }

  public getIsCustomer() {
    return this.isCustomer;
  }

  public newUpdate() {
    if (this.project) {
      this.router.navigate(['/projects', this.project.slug, 'updates', 'new']);
    }
    this.updatePicker.pick();
  }

  public async getStatusCount() {
    this.statusCount = await this.updateService.getStatusCount(
      this.project || null,
      this.search,
      this.startDate,
      this.endDate,
      this.getIsCustomer() ? this.customerIds : null,
      this.getIsCustomer() ? this.projectIds : null
    );
  }

  private createFilterForm() {
    const statuses = this.formBuilder.array(
      Object.values(UpdateStatus).map((status) => {
        if (this.defaultStatuses.includes(status)) {
          return this.formBuilder.control(true);
        }
        return this.formBuilder.control(false);
      })
    );

    this.filterForm = this.formBuilder.group({
      search: [''],
      statuses,
      startDate: [],
      endDate: [],
      customers: this.formBuilder.array([]),
      projects: this.formBuilder.array([]),
    });

    this.filterForm.valueChanges.subscribe(() => {
      this.updateFilter();
    });
  }

  updateFilter() {
    this.search = this.filterForm.get('search').value;
    this.startDate = this.filterForm.get('startDate').value || null;
    this.endDate = this.filterForm.get('endDate').value || null;
    this.statuses = this.filterForm
      .get('statuses')
      .value.map((item, index) => (!item ? null : this.allStatuses[index]))
      .filter((it) => !!it);
    this.customerIds = this.filterForm
      .get('customers')
      .value.map((item, index) => (!item ? null : this.customers[index].id))
      .filter((it) => !!it);
    this.projectIds = this.filterForm
      .get('projects')
      .value.map((item, index) => (!item ? null : this.projects[index].id))
      .filter((it) => !!it);

    if (!this.ready) {
      return;
    }

    this.reset();
    this.loadUpdates();
    this.getStatusCount();
  }

  get allStatuses(): UpdateStatus[] {
    return Object.values(UpdateStatus);
  }

  /**
   * Loads more updates
   * @returns {Promise<void>}
   */
  async loadMoreUpdates(): Promise<void> {
    this.loading = true;
    this.page += 1; // do before a request, so you won't get duplicates

    if (!this.updates || this.updates.length < this.totalItems) {
      let response: any;

      if (this.version == 1) {
        if (this.project) {
          response = await this.updateService.listByProject(
            this.project,
            this.page,
            this.perPage
          );
        } else {
          const projectUser = await this.userDataService.retrieveProjectUser();
          response = await this.updateService.listUpdates(
            projectUser,
            this.page,
            this.perPage
          );
        }
      } else {
        if (this.project) {
          response = await this.updateService.listByProjectV2(
            this.project,
            this.page,
            this.perPage,
            'desc',
            this.search,
            this.statuses,
            this.startDate,
            this.endDate,
            false
          );
        } else {
          const projectUser = await this.userDataService.retrieveProjectUser();
          response = await this.updateService.listUpdatesV2(
            projectUser,
            this.page,
            this.perPage,
            'desc',
            this.search,
            this.statuses,
            this.startDate,
            this.endDate,
            this.customerIds,
            this.projectIds,
            false
          );
        }
      }

      const result: Update[] = response['hydra:member'];

      if (result != null) {
        if (!this.updates) {
          this.updates = [];
          this.totalItems = response['hydra:totalItems'];
        }

        this.updates = this.updates.concat(result);
      }
    }

    this.loading = false;
  }

  async deleteConfirmed(update: Update): Promise<void> {
    await this.updateService.remove(update);

    const index: number = this.updates.indexOf(update);
    if (index !== -1) {
      this.updates.splice(index, 1);

      this.processUpdates(this.updates);
    }
  }

  /**
   * Checks whether updates should show a year
   * @returns {void}
   */
  private processUpdates(updates: Update[]): void {
    let lastYear: number;

    for (const update of updates) {
      const date = moment(update.publicationDate);
      const year = +date.format('Y');

      if (year !== lastYear) {
        update.year = year;
        lastYear = year;
      }
    }
  }

  public compareSort(sort1: Sort, sort2: Sort): boolean {
    return (
      sort1.field === sort2.field &&
      sort1.label === sort2.label &&
      sort1.direction === sort2.direction
    );
  }

  private reset() {
    this.page = 0;
    this.totalItems = undefined;
    this.updates = undefined;
  }

  async ngOnInit(): Promise<void> {
    this.version = await this.themeService.getVersion();
    this.route.parent.params.subscribe((params) => {
      if (!params['slug']) {
        this.isCustomer = true;
        this.project = null;
        this.getStatusCount();
      } else {
        this.projectDataBusService.projectObservable.subscribe((project) => {
          this.project = project;
          if (null === this.project) {
            this.isCustomer = true;
          }
          this.getStatusCount();
        });
      }
    });

    this.accessService.accessControlList.subscribe((list) => {
      if (list == null) {
        return;
      }

      this.customers = this.accessService.getCustomersFromACL(list);
      const formCustomers = this.filterForm.get('customers') as FormArray;
      formCustomers.controls = [];

      this.customers.forEach((customer) =>
        formCustomers.push(this.formBuilder.control(true))
      );

      this.projectDataBusService.shortListObservable.subscribe((shortList) => {
        if (shortList === null) {
          return;
        }
        this.projects = shortList.filter(
          (item) =>
            SecurityVoter.canEditProject(list, item) === true &&
            item.status !== ProjectStatus.ARCHIVED
        );

        const formProjects = this.filterForm.get('projects') as FormArray;
        formProjects.controls = [];

        this.projects.forEach((project) =>
          formProjects.push(this.formBuilder.control(false))
        );

        this.ready = true;

        this.projects.sort(function (projectA, projectB) {
          const projectNameA = projectA.name.toUpperCase();
          const projectNameB = projectB.name.toUpperCase();
          return projectNameA < projectNameB
            ? -1
            : projectNameA > projectNameB
            ? 1
            : 0;
        });
      });
    });

    this.loadUpdates();
  }
}
