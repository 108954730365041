import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { constants } from '../../constants';

@Injectable()
export class StaticFileInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isStaticFileRequest = !!request.url.match(
      /http(s)?:\/\/.*\.(svg|png|ico)$/i
    );

    if (!isStaticFileRequest) {
      return next.handle(request);
    }

    const file = new URL(request.url).pathname;

    if (!Object.hasOwn(constants.fileHashes, file)) {
      return next.handle(request);
    }

    const newRequest = request.clone({
      params: (request.params ? request.params : new HttpParams()).set(
        'h',
        constants.fileHashes[file]
      ),
    });
    return next.handle(newRequest);
  }
}
