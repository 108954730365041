<div class="multi-attachment__group">
  <div class="multi-attachment__list">
    <form [formGroup]="form">
      <div
        class="list"
        [dragula]="Sortable.ATTACHMENTS"
        [(dragulaModel)]="attachmentPreviews"
        (dragulaModelChange)="updateAttachmentsSortOrder($event)"
      >
        <ng-container *ngIf="attachments.controls">
          <div
            #file
            class="file"
            [attr.data-index]="i"
            [class.pdf]="isPdf(attachment)"
            *ngFor="let attachment of attachmentPreviews; let i = index"
          >
            <ng-container *ngIf="attachment.error">
              <div class="file__error" (click)="removeAttachment(i)">
                <span inlineSVG="/assets/img/icons/message-WARNING.svg"></span>
                <span>{{ attachment.error }}</span>
                <div class="file__overlay">
                  <div
                    class="file__remove"
                    inlineSVG="/assets/img/icons/trash.svg"
                  ></div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!attachment.error">
              <div
                (click)="openMediaModal(attachment, i)"
                class="file__image"
                [style.backgroundImage]="
                  !isVideo(attachment) ? getPreviewImage(attachment, true) : ''
                "
              >
                <video preload="metadata" *ngIf="isVideo(attachment)">
                  <source [src]="getPreviewVideo(attachment)" />
                </video>
                <div
                  class="file__overlay"
                  [class.uploading]="!attachment.isUploaded"
                >
                  <span
                    class="file__360"
                    inlineSVG="/assets/img/icons/360.svg"
                    *ngIf="attachments.controls[i]?.get('is360').value"
                  ></span>
                  <div class="file__spinner" [hidden]="attachment.isUploaded">
                    <i class="spinner"></i>
                  </div>
                  <span
                    class="file__video"
                    *ngIf="isYoutubeVideo(attachment) && attachment.isUploaded"
                  >
                    <img
                      src="{{ '/assets/v2/img/icons/youtube.png' | fileUrl }}"
                    />
                  </span>
                  <span
                    class="file__video"
                    inlineSVG="/assets/img/icons/type-video.svg"
                    *ngIf="isVideo(attachment) && attachment.isUploaded"
                  ></span>
                  <div
                    *ngIf="attachment.isUploaded"
                    class="file__edit"
                    inlineSVG="/assets/img/icons/edit.svg"
                  ></div>
                </div>
              </div>
              <div
                class="file__drag"
                inlineSVG="/assets/img/icons/handle.svg"
              ></div>
            </ng-container>
          </div>
        </ng-container>
        <div class="file disable-drag">
          <div class="file__image new">
            <app-input-file-v2
              class="input-file-holder"
              [type]="type"
              [uploadImages]="true"
              [uploadVideos]="withVideo"
              [uploadPdfs]="true"
              (uploadStart)="addAttachment($event)"
              (uploadFinished)="updateAttachment($event)"
              (uploadFailed)="uploadError($event)"
            >
            </app-input-file-v2>
          </div>
        </div>
      </div>
    </form>
    <button
      *ngIf="withVideo"
      type="button"
      class="button button--yt button--ghost"
      (click)="openYoutubeModal()"
    >
      <img src="{{ 'assets/v2/img/icons/youtube.png' | fileUrl }}" />
      <span>{{ 'multi_attachment_control.youtube.add' | translate }}</span>
    </button>
    <p class="alert alert-info" *ngIf="convertMessage">
      {{ 'multi_attachment_control.convert.warning' | translate }}
    </p>
  </div>
</div>

<app-modal
  class="media-modal"
  #mediaModal
  [title]="
    (isYoutube
      ? 'projects.detail.update.media.youtube'
      : 'projects.detail.update.media.edit'
    ) | translate
  "
>
  <p class="modal-description" *ngIf="withVideo">
    {{
      (isYoutube
        ? 'projects.detail.update.media.youtube.description'
        : 'projects.detail.update.media.description'
      ) | translate
    }}
  </p>
  <div class="multi-attachment" [formGroup]="form">
    <div
      class="multi-attachment__form"
      formArrayName="attachments"
      *ngIf="
        attachments.controls.length > 0 &&
        (withVideo || withDescription || with360)
      "
    >
      <div
        class="multi-attachment__control-holder"
        [formGroupName]="activeIndex"
        *ngIf="activeIndex >= 0"
      >
        <app-form-group
          [form]="attachments.controls[activeIndex]"
          errorPath="title"
          cssClass="multi-attachment__form-group__holder"
        >
          <label>{{ 'projects.detail.update.media.title' | translate }}</label>
          <textarea
            rows="4"
            [attr.placeholder]="
              'projects.detail.update.media.title.placeholder' | translate
            "
            class="input"
            formControlName="title"
          >
          </textarea>
        </app-form-group>
        <div
          class="multi-attachment__360-option"
          *ngIf="supports360() && !isYoutube"
        >
          <label>{{ 'projects.detail.update.media.360' | translate }}</label>
          <span>{{
            'projects.detail.update.media.360.description' | translate
          }}</span>
          <app-toggle formControlName="is360" [small]="true"></app-toggle>
        </div>
        <app-form-group
          *ngIf="isYoutube && !attachmentPreviews[activeIndex]?.isUploaded"
          [form]="videoForm"
          [formGroup]="videoForm"
          errorPath="youtubeUrl"
          cssClass="multi-attachment__youtube"
        >
          <label>{{
            'projects.detail.update.media.youtube.title' | translate
          }}</label>
          <input class="input input--m" formControlName="youtubeUrl" />
        </app-form-group>
      </div>
    </div>
    <div class="multi-attachment__media">
      <app-form-group [form]="form" errorPath="attachment">
        <label>
          {{ 'projects.detail.update.media.label' | translate }}
        </label>
        <div
          class="cover"
          [class.with-image]="activePreview"
          [style.backgroundImage]="
            activePreview ? getPreviewImage(activePreview) : null
          "
        >
          <video
            #videoPlayer
            [poster]="getVideoPoster(activePreview)"
            controls
            *ngIf="activePreview && isVideo(activePreview)"
          >
            <source [src]="getPreviewVideo(activePreview)" />
          </video>
        </div>
      </app-form-group>
    </div>
    <div class="multi-attachment__buttons">
      <div class="multi-attachment__buttons__left">
        <button
          type="button"
          *ngIf="attachmentPreviews[activeIndex]?.isUploaded"
          (click)="removeAttachment()"
          class="btn button--m button--outlined bg--red--hover"
        >
          {{ 'projects.detail.update.media.remove' | translate }}
        </button>
      </div>
      <div
        class="multi-attachment__buttons__right"
        *ngIf="withDescription && with360"
      >
        <button
          type="button"
          (click)="cancel()"
          class="btn button--m button--outlined bg--grey--hover"
        >
          {{ 'projects.detail.update.media.cancel' | translate }}
        </button>
        <button
          type="button"
          [disabled]="!canSave()"
          (click)="confirm()"
          class="btn button--m button--flat button--primary"
        >
          {{ 'projects.detail.update.media.save' | translate }}
        </button>
      </div>
    </div>
  </div>
</app-modal>
