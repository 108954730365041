import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Article } from 'src/app/interfaces/article';
import { NewsService } from 'src/app/services/news.service';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '../../../components/loader.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf, NgClass, NgStyle, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
    standalone: true,
    imports: [NgIf, InlineSVGModule, NgClass, NgStyle, NgFor, LoaderComponent, DatePipe, TranslateModule]
})
export class ArticleComponent implements OnInit {
  article: Article;
  relatedArticles: Article[];
  routerSub: Subscription;
  private slug: string;

  constructor(
    private newsService: NewsService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params['slug'] != null) {
        this.slug = params['slug'];
        this.loadArticle(this.slug);
        this.loadRelatedArticles(this.slug);
      }
    });
  }

  async loadArticle(slug: string): Promise<void> {
    try {
      this.article = await this.newsService.fetch(slug);
    } catch (error) {
      this.router.navigate(['/404']);
    }
  }

  getText() {
    return this.sanitizer.bypassSecurityTrustHtml(this.article.content);
  }

  goBack() {
    this.router.navigate([this.article.blogCategory.type]);
  }

  async toggleFavorite() {
    this.article.isFavorited = !this.article.isFavorited;
    const result = await this.newsService.toggleFavorite(this.article.slug);
    if (result.action == 'ADDED') this.article.isFavorited = true;
    else if (result.action == 'REMOVED') this.article.isFavorited = false;
    let savedState = this.newsService.getSavedState();
    if (savedState) {
      savedState.articles.forEach((article) => {
        if (article.slug == this.article.slug) {
          article.isFavorited = this.article.isFavorited;
        }
      });
    }
  }

  async loadRelatedArticles(slug: string): Promise<void> {
    const result = await this.newsService.listRelated(slug);
    this.relatedArticles = result;
  }

  openArticle(article: Article) {
    this.router.navigate(['/articles', article.slug]);
  }

  getImageByArticle(
    article: Article,
    backgroundStyle: boolean = false
  ): SafeStyle | string {
    if (article.image == null) {
      return '';
    } else {
      if (backgroundStyle)
        return this.sanitizer.bypassSecurityTrustStyle(
          'url(' + article.imageThumbnails?.medium + ')'
        );
      else
        return this.sanitizer.bypassSecurityTrustUrl(
          article.imageThumbnails?.medium
        );
    }
  }
}
