import { Component, input, OnInit, output, viewChild } from '@angular/core';
import { ModalComponent } from './modal.component';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { UserDataService } from '../services/user-data.service';
import { Customer } from '../interfaces/customer';
import { Subject } from 'rxjs';
import { ErrorService } from '../services/error.service';
import { Module } from '../enums/module';
import { ModuleService } from '../services/module.service';
import { AccessService } from '../services/access.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';
import { FormGroupComponent } from './form-group.component';
import { LocalStorageService } from 'ngx-webstorage';
import { VersionDirective } from '../directives/version.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { PermissionType } from '../enums/permission-type';

@Component({
  selector: 'app-customer-picker',
  templateUrl: './customer-picker.component.html',
  standalone: true,
  imports: [
    ModalComponent,
    ReactiveFormsModule,
    FormGroupComponent,
    NgFor,
    TranslateModule,
    VersionDirective,
    InlineSVGModule,
    NgIf,
  ],
})
export class CustomerPickerComponent implements OnInit {
  private readonly modal = viewChild<ModalComponent>(ModalComponent);
  public readonly withProjectPermissions = input<boolean>(false);
  public readonly withSwitcher = input<boolean>(true);
  public selectedCustomer = output<Customer>();

  private static CURRENT_CUSTOMER_KEY = 'current_customer';

  public customerList: Customer[] = [];
  public filteredList: Customer[] = [];
  public form: FormGroup;
  public currentCustomer: Customer;

  private customerSubject: Subject<Customer>;

  constructor(
    private fb: FormBuilder,
    private accessService: AccessService,
    private userDataService: UserDataService,
    private errorService: ErrorService,
    private moduleService: ModuleService,
    private localStorage: LocalStorageService
  ) {
    this.createForm();
  }

  async ngOnInit() {
    this.customerList = await this.userDataService.retrieveCustomers();
    const storedCustomer = this.localStorage.retrieve(
      CustomerPickerComponent.CURRENT_CUSTOMER_KEY
    );

    this.currentCustomer = storedCustomer ?? this.customerList[0];
    this.selectedCustomer.emit(this.currentCustomer);

    this.accessService.accessControlList.subscribe((projectUser) => {
      if (projectUser.mappings !== undefined && this.withProjectPermissions()) {
        projectUser.mappings.forEach((item) => {
          if (
            item.permissionType === PermissionType.CREATE ||
            item.permissionType === PermissionType.EDIT
          ) {
            if (!this.customerList.includes(item.project.customer)) {
              this.customerList.unshift(item.project.customer);
            }
          }
        });
      }
      this.customerList = this.customerList.filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      );
    });
  }

  public async pick(module?: Module): Promise<Customer> {
    let list = this.customerList;

    if (!!module) {
      list = await this.moduleService.getCustomersWithModule(module);
    }

    this.filteredList = list;

    if (this.filteredList.length === 1) {
      return this.filteredList[0];
    } else {
      this.modal().open();

      this.customerSubject = new Subject<Customer>();
      const customer: Customer = await this.customerSubject.toPromise();
      this.customerSubject = null;

      if (customer) {
        await this.localStorage.store(
          CustomerPickerComponent.CURRENT_CUSTOMER_KEY,
          customer
        );
      }

      this.modal().close();

      return customer;
    }
  }

  public async selectCompany() {
    this.errorService.markFormGroupTouchedAndDirty(this.form);

    if (this.form.valid) {
      let customer = this.customerList.find(
        (item) => item.id === +this.form.value.customer
      );
      if (customer !== null) {
        this.currentCustomer = customer;
        this.selectedCustomer.emit(customer);

        if (this.customerSubject !== null) {
          this.customerSubject.next(customer);
          this.customerSubject.complete();
        }
      }
    }
  }

  public modalClosed(): void {
    if (this.customerSubject !== null) {
      this.customerSubject.complete();
      this.customerSubject = null;
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      customer: ['', [Validators.required]],
    });
  }
}
