<div class="bouwapp-header">
  <div class="bouwapp-header__text">
    <h1 *appVersion="1">{{ 'projects.detail.general.title' | translate }}</h1>
    <ng-container *appVersion="2">
      <h1 *ngIf="create">{{ 'project.list.new_project' | translate }}</h1>
    </ng-container>
  </div>
</div>

<div class="nav-concept" *ngIf="isConcept && version === 1">
  <div class="nav-concept__left">
    <div class="header__logo">
      <a routerLink="/">
        <img [src]="logo" />
      </a>
    </div>
  </div>
  <div class="nav-concept__right">
    <button
      class="button button--m button--flat bg--orange margin"
      [appLoading]="loading"
      (click)="saveConcept(false, true)"
    >
      {{ 'projects.detail.concept.save' | translate }}
    </button>
  </div>
</div>
<section
  [hidden]="isSuccess && version === 1"
  class="general projects-detail"
  [class.is-concept]="isConcept && currentIndex < steps.length"
>
  <form
    #formRef="ngForm"
    ngForm
    appFormChangeDetector
    [formGroup]="generalForm"
    appAccess
    role="manager"
    [project]="project"
    property="readonly"
  >
    <app-success-message [hidden]="!generalFormSaved" [onScreen]="true">
      {{ 'projects.detail.general.success' | translate }}
    </app-success-message>

    <app-two-columns-layout
      [stickyContainerSelector]="'.general.projects-detail'"
    >
      <ng-container left>
        <!-- 01 General -->
        <div
          class="bouwapp-group"
          [hidden]="
            version === 1 &&
            isConcept &&
            ['info', 'summary'].indexOf(steps[currentIndex]) == -1
          "
        >
          <div class="bouwapp-group__header">
            <h2 *appVersion="1">
              {{ 'projects.detail.general.step.title' | translate }}
            </h2>
            <h2 *appVersion="2">
              {{ 'projects.detail.general.information' | translate }}
            </h2>
          </div>

          <app-detail-general-info
            (startUploading)="startUploading()"
            (stopUploading)="stopUploading($event)"
            [isConcept]="isConcept"
            [project]="project"
            [generalForm]="generalForm"
          ></app-detail-general-info>
        </div>

        <!-- 02 Planning -->
        <div
          class="bouwapp-group"
          id="planning"
          [hidden]="
            version === 1 &&
            isConcept &&
            ['planning', 'summary'].indexOf(steps[currentIndex]) == -1
          "
        >
          <div class="bouwapp-group__header">
            <h2 *appVersion="1">
              {{ 'projects.detail.general.planning.title' | translate }}
            </h2>
            <h2 *appVersion="2">
              {{ 'projects.detail.general.planning.header' | translate }}
            </h2>
            <p>{{ 'projects.detail.general.planning.subtitle' | translate }}</p>
          </div>

          <div class="bouwapp-box__self">
            <app-detail-general-planning
              [dateRangeValid]="dateRangeValid"
              [generalForm]="generalForm"
            ></app-detail-general-planning>
          </div>
        </div>

        <!-- 03 Location -->
        <div
          *ngIf="project"
          class="bouwapp-group"
          [hidden]="
            version === 1 &&
            isConcept &&
            ['location', 'summary'].indexOf(steps[currentIndex]) == -1
          "
        >
          <div class="bouwapp-group__header">
            <h2>{{ 'projects.detail.general.location.title' | translate }}</h2>
            <p>{{ 'projects.detail.general.location.subtitle' | translate }}</p>
          </div>
          <div class="bouwapp-box__self">
            <app-detail-general-location
              *ngIf="
                !(
                  isConcept &&
                  version === 1 &&
                  ['location', 'summary'].indexOf(steps[currentIndex]) == -1
                )
              "
              [project]="project"
              [generalForm]="generalForm"
            ></app-detail-general-location>
          </div>
        </div>

        <ng-container *ngIf="isConcept">
          <!-- 04 Contact -->
          <div
            class="bouwapp-group"
            *ngIf="
              version === 2 ||
              ['contact', 'summary'].indexOf(steps[currentIndex]) > -1
            "
          >
            <div class="bouwapp-group__header">
              <h2>{{ 'project.detail.contact.title' | translate }}</h2>
            </div>
            <div class="bouwapp-box__self">
              <app-detail-general-contact
                [language]="generalForm.get('language').value"
                [withSave]="false"
              ></app-detail-general-contact>
            </div>
          </div>

          <!-- 05 Targets -->
          <div
            class="bouwapp-group"
            *ngIf="
              version === 2 ||
              ['targets', 'summary'].indexOf(steps[currentIndex]) > -1
            "
          >
            <div class="bouwapp-group__header">
              <h2 *appVersion="1">
                {{ 'project.detail.targets.title' | translate }}
              </h2>
              <h2 *appVersion="2">
                {{ 'project.detail.targets.header' | translate }}
              </h2>
            </div>
            <div class="bouwapp-box__self">
              <app-detail-general-targets
                [step]="currentIndex"
              ></app-detail-general-targets>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isConcept">
          <!-- 04 Documents -->
          <div class="bouwapp-group">
            <div class="bouwapp-group__header">
              <h2>
                {{ 'projects.detail.general.documents.title' | translate }}
              </h2>
              <p>
                {{ 'projects.detail.general.documents.subtitle' | translate }}
              </p>
            </div>
            <div class="bouwapp-box__self">
              <app-default-projects-detail-documents></app-default-projects-detail-documents>
            </div>
          </div>

          <!-- 05 Projects -->
          <div class="bouwapp-group">
            <div class="bouwapp-group__header">
              <h2>
                {{ 'projects.detail.general.projects.title' | translate }}
              </h2>
              <p>
                {{ 'projects.detail.general.projects.subtitle' | translate }}
              </p>
            </div>

            <ng-container
              *ngIf="!project.enabledModules?.includes(Module.CHILD_PROJECTS)"
            >
              <div class="bouwapp-box__self">
                <p
                  [innerHTML]="
                    'projects.detail.general.projects.disabled' | translate
                  "
                ></p>
              </div>
            </ng-container>
            <ng-container
              *ngIf="project.enabledModules?.includes(Module.CHILD_PROJECTS)"
            >
              <div class="bouwapp-box__self" *ngIf="!project.parentProject">
                <app-default-projects-detail-projects></app-default-projects-detail-projects>
              </div>
              <div class="bouwapp-box__self" *ngIf="project.parentProject">
                <div class="project-child__empty">
                  <p
                    [innerHTML]="
                      'projects.detail.general.projects.is_child_project'
                        | translate : { name: project.parentProject.name }
                    "
                  ></p>
                  <div
                    appAccess
                    role="viewer"
                    [project]="project.parentProject"
                    class="button button--m button--outlined bg--grey--hover"
                    (click)="openProject(project.parentProject)"
                  >
                    {{ 'projects.detail.general.projects.go_to' | translate }}
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <!-- 06 WFS -->
          <div
            class="bouwapp-group"
            *ngIf="
              (!isConcept && project.enabledModules?.includes(Module.WFS)) ||
              (isConcept &&
                project.customer.enabledModules?.includes(Module.WFS))
            "
          >
            <div class="bouwapp-group__header">
              <h2>{{ 'projects.detail.general.wfs.title' | translate }}</h2>
              <p>{{ 'projects.detail.general.wfs.subtitle' | translate }}</p>
            </div>

            <app-detail-general-wfs
              [generalForm]="generalForm"
              [project]="project"
            ></app-detail-general-wfs>
          </div>
        </ng-container>

        <!-- Actionbar -->
        <div
          *appVersion="1"
          class="bouwapp-bottombar"
          appAccess
          role="manager"
          [project]="project"
        >
          <div class="bouwapp-bottombar__creation" *ngIf="isConcept">
            <p>
              <span>0{{ currentIndex + 1 }}</span>
              {{
                'projects.detail.concept.step.' + steps[currentIndex]
                  | translate
              }}
            </p>

            <div class="bouwapp-bottombar__creation__progress">
              <p>
                {{
                  'projects.detail.concept.step.process'
                    | translate
                      : {
                          currentStep: currentIndex + 1,
                          totalSteps: steps.length
                        }
                }}
                -
                <span
                  >{{
                    ((currentIndex + 1) / steps.length) * 100
                      | number : '1.0-0'
                  }}%
                  {{
                    'projects.detail.concept.step.completed' | translate
                  }}</span
                >
              </p>
              <progressbar
                [value]="((currentIndex + 1) / steps.length) * 100"
              ></progressbar>
            </div>

            <div class="bouwapp-bottombar__creation__button">
              <button
                class="button button--m button--flat bg--grey"
                [appLoading]="loading || uploading"
                color="dark"
                *ngIf="currentIndex + 1 < steps.length"
                (click)="saveConcept(true, false)"
              >
                {{ 'projects.detail.concept.step.next' | translate }}
              </button>

              <button
                class="button button-m button--ghost bg--grey--hover"
                [appLoading]="loading || uploading"
                *ngIf="currentIndex + 1 == steps.length"
                (click)="saveConcept(false, true)"
              >
                {{ 'projects.detail.concept.save' | translate }}
              </button>

              <button
                class="button button--m button--flat bg--orange"
                [appLoading]="loading || uploading"
                *ngIf="currentIndex + 1 == steps.length"
                (click)="save()"
              >
                {{ 'projects.detail.concept.save_unpublished' | translate }}
              </button>
            </div>
          </div>

          <button
            *ngIf="!isConcept"
            class="button button--m button--flat bg--orange"
            [appLoading]="loading || uploading"
            (click)="saveGeneral()"
          >
            {{ 'projects.detail.general.save' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-container right *appVersion="2">
        <div class="bouwapp-box">
          <div class="bouwapp-box__title">
            <h2>{{ 'projects.detail.concept.save_publish' | translate }}</h2>
          </div>
          <div class="bouwapp-box__self">
            <div class="two-columns__buttons">
              <div class="publication status">
                <span inlineSVG="/assets/img/icons/pin.svg"></span>
                {{ 'projects.detail.update.edit.status' | translate }}
                <span
                  [class.concept]="project?.status === 'CONCEPT' || !project"
                >
                  <strong>{{
                    generalForm.get('status').value | translate
                  }}</strong>
                </span>
              </div>
              <div class="publication notification">
                <span inlineSVG="/assets/v2/img/icons/globe.svg"></span>
                <span
                  >{{
                    'projects.detail.general.project.language.title' | translate
                  }}
                  <span>{{
                    'projects.detail.general.project.language.' +
                      getTranslationKey(generalForm.get('language').value)
                      | translate
                  }}</span>
                </span>
              </div>
            </div>
            <div class="bouwapp-box__footer">
              <button
                [appLoading]="loading"
                class="button button--m button--black-white"
                (click)="isConcept ? saveConcept(true, true) : save()"
                type="submit"
              >
                @if(isConcept){
                {{ 'projects.detail.update.edit.save_concept' | translate }}
                }@else{
                {{ 'projects.detail.concept.save_unpublished' | translate }}
                }
              </button>
              <button
                [appLoading]="loading"
                *ngIf="project?.status !== 'PUBLISHED'"
                (click)="publish()"
                class="button button--m button--flat bg--orange"
              >
                {{ 'projects.detail.concept.save_publish' | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </app-two-columns-layout>
  </form>
</section>

<!-- Success -->
<section *ngIf="isSuccess && version === 1" class="project-success">
  <div class="project-success__close" [routerLink]="['/']">
    <span [inlineSVG]="'/assets/img/icons/close--dark.svg'"></span>
  </div>
  <div class="project-success__inner">
    <img [src]="logo" width="212px" height="42" alt="Logo" />
    <h2>
      {{ 'projects.detail.concept.success.title' | translate }}
    </h2>
    <p
      [innerHTML]="
        'projects.detail.concept.success.description'
          | translate : { projectName: project.name }
      "
    ></p>
    <div class="flex buttons">
      <button
        class="button button--l button--flat bg--orange"
        [routerLink]="['/projects', project.slug, 'updates']"
      >
        {{ 'projects.detail.concept.success.link.updates' | translate }}
      </button>
      <button
        class="button button--l button--ghost bg--grey--hover"
        [routerLink]="['/']"
      >
        {{ 'projects.detail.concept.success.link.overview' | translate }}
      </button>
    </div>
  </div>
</section>

<app-modal
  [title]="'projects.detail.concept.preview.title' | translate"
  #previewModal
  class="project-previewModal"
>
  <p class="modal-text">
    {{ 'projects.detail.concept.preview.description' | translate }}
  </p>
  <input
    #codeToCopy
    type="text"
    class="input input--m"
    readonly
    [value]="previewUrl"
    [placeholder]="'projects.detail.concept.preview.save' | translate"
  />
  <div class="flex buttons">
    <button
      [disabled]="!project.id"
      (click)="openPreview()"
      class="button button--m button--flat bg--orange"
    >
      {{ 'projects.detail.concept.preview.title' | translate }}
    </button>
    <button
      [disabled]="!project.id"
      [ngxClipboard]="codeToCopy"
      (cbOnSuccess)="textCopied()"
      class="button button--m button--flat bg--grey"
      [ngClass]="{ isCopied: copied }"
    >
      <span
        [inlineSVG]="'/assets/img/icons/check.svg'"
        [hidden]="!copied"
      ></span>
      {{ 'projects.detail.concept.preview.copy' | translate }}
    </button>
  </div>
</app-modal>
