import { Component } from '@angular/core';
import { Project } from '../../../interfaces/project';
import { ProjectDataBusService } from '../../../services/project-data-bus.service';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import * as moment from 'moment';
import { SecurityVoter } from '../../../security/security-voter';
import { AccessControlList } from '../../../interfaces/access-control-list';
import { AccessService } from '../../../services/access.service';
import { ErrorService } from '../../../services/error.service';
import { ExportService } from '../../../services/export.service';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerComponent } from '../../../components/date-picker.component';
import { FormGroupComponent } from '../../../components/form-group.component';
import { LoaderComponent } from '../../../components/loader.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    ReactiveFormsModule,
    FormGroupComponent,
    NgFor,
    DatePickerComponent,
    TranslateModule,
  ],
})
export class ExportComponent {
  public showPeriod = false;
  public form: FormGroup;
  public projects: Project[];
  private allProjects: Project[];
  private acl: AccessControlList;

  constructor(
    private projectDataBusService: ProjectDataBusService,
    private errorService: ErrorService,
    private accessService: AccessService,
    private fb: FormBuilder,
    private exportService: ExportService
  ) {
    this.accessService.accessControlList.subscribe((acl) => {
      this.acl = acl;

      this.filterProjects();
    });

    this.projectDataBusService.shortListObservable.subscribe((projects) => {
      this.allProjects = projects;

      this.filterProjects();
    });

    this.createForm();
  }

  togglePeriod() {
    this.showPeriod = !this.showPeriod;
    if (!this.showPeriod) {
      this.form.patchValue({
        from: moment().subtract(1, 'month').format(),
        till: moment().format(),
      });
    }
  }

  async download() {
    this.errorService.markFormGroupTouchedAndDirty(this.form);

    if (!this.form.valid) {
      return;
    }

    await this.exportService.export({
      showPeriod: this.showPeriod,
      ...this.form.value,
    });
  }

  private createForm() {
    this.form = this.fb.group(
      {
        from: [moment().subtract(1, 'month').format(), Validators.required],
        till: [moment().format(), Validators.required],
        project: [null, Validators.required],
      },
      {
        validator: this.dateRangeValidator, // Apply the custom validator here
      }
    );
  }

  dateRangeValidator(control: FormGroup): ValidationErrors | null {
    const fromControll = control.get('from');
    const tillControll = control.get('till');

    fromControll.setErrors(null);
    tillControll.setErrors(null);

    if (
      fromControll.value &&
      tillControll.value &&
      fromControll.value > tillControll.value
    ) {
      const error = { invalidDateRange: true };
      fromControll.setErrors(error);
      tillControll.setErrors(error);
      return error;
    }

    return null;
  }

  private filterProjects() {
    if (!this.allProjects || !this.acl) {
      return;
    }

    this.projects = this.allProjects.filter(
      (project) =>
        SecurityVoter.isProjectAdminForProject(this.acl, project) ||
        SecurityVoter.isManagerForProject(this.acl, project)
    );
  }
}
