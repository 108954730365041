<div class="modal modal-open" [class.modal-open]="isOpen">
  <div
    class="modal-content"
    [class.modal-image]="image"
    [class.modal--no-scroll]="!scroll"
    #modalContainer
  >
    <div class="modal-header">
      <div *ngIf="svg" [inlineSVG]="svg" class="modal-icon"></div>
      <div>
        <h2 class="modal-title" [hidden]="image">{{ title }}</h2>
        <ng-content select="[slot=header]"></ng-content>
      </div>
      <div
        inlineSVG="/assets/v2/img/icons/close.svg"
        class="modal-close"
        (click)="close(true)"
      ></div>
    </div>
    <div class="modal-content-inner">
      <ng-content></ng-content>
    </div>
    <img
      class="modal-image-content"
      *ngIf="image && imageUrl != null"
      [src]="imageUrl"
    />
  </div>
</div>
