import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'timeAgo',
    standalone: true
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date, ...args: any[]): any {
    return moment(value).fromNow(false);
  }
}
