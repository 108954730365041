import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import { DatePipe, NgIf, NgFor, NgClass } from '@angular/common';
import { DatePickerItem } from '../interfaces/date-picker-item';
import { TranslateModule } from '@ngx-translate/core';
import { SwitchOptionComponent } from './switch-option.component';
import { SwitchComponent } from './switch.component';

@Component({
  selector: 'app-date-picker',
  templateUrl: 'date-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    NgFor,
    NgClass,
    SwitchComponent,
    SwitchOptionComponent,
    DatePipe,
    TranslateModule,
  ],
})
export class DatePickerComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  static COMPARISON_FORMAT = 'Y-MM-DD';

  currentDate: string;
  @ViewChild('timeElement') public timeElement: ElementRef<HTMLInputElement>;
  @Input() typeControl: FormControl;
  @Input() withTime: boolean;
  @Input() inline = false;
  noSelection = false;
  showPeriod: boolean;
  type: string;
  months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];
  actualDate: string | null = null;

  dateForm: FormGroup;

  years: number[];
  days: number[];
  timeCopyInterval: number;

  items: DatePickerItem[];

  /**
   * @param {FormBuilder} formBuilder
   * @param datePipe
   */
  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe) {
    this.createDateForm();
  }

  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  /**
   * @param value
   */
  writeValue(value: string): void {
    this.actualDate = value;

    this.updateDate(value);
  }

  /**
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /**
   * @returns {void}
   */
  ngOnInit(): void {
    if (this.typeControl) {
      this.typeControl.valueChanges.subscribe((value) => {
        this.handleTypeControlChange(value);
      });

      this.handleTypeControlChange(this.typeControl.value);
    }

    this.updateYears(moment());
  }

  handleTypeControlChange(value: string) {
    const mainTypeValue =
      this.typeControl.value === 'EXACT_DATE' || this.typeControl.value == null
        ? 'EXACT_DATE'
        : 'PERIOD';
    let typeValue;

    switch (this.typeControl.value) {
      case 'Q1':
      case 'Q2':
      case 'Q3':
      case 'Q4':
        typeValue = 'QUARTER';

        this.dateForm.get('quarter').patchValue(this.typeControl.value[1]);

        break;
      default:
        typeValue = this.typeControl.value;
    }

    this.dateForm.get('mainType').valueChanges.subscribe((value) => {
      if (value === 'PERIOD' && !this.actualDate) {
        this.updateDate(this.currentDate);
      }
    });

    this.dateForm.get('mainType').patchValue(mainTypeValue);
    this.dateForm.get('type').patchValue(typeValue);
  }

  ngOnDestroy(): void {
    clearInterval(this.timeCopyInterval);
  }

  /**
   * @param dateItem
   */
  select(dateItem: DatePickerItem): void {
    if (!dateItem.previous) {
      const date = dateItem.date;

      this.updateDate(date, true);
    }
  }

  /**
   * @param control
   * @returns {any}
   */
  validate(control: FormControl): any {
    return this.dateForm.valid && (!this.withTime || this.withTime != null)
      ? null
      : { invalidDate: true };
  }

  /**
   * @returns {void}
   */
  private createDateForm() {
    this.dateForm = this.formBuilder.group({
      day: ['', Validators.required],
      month: ['', Validators.required],
      year: ['', Validators.required],
      time: [''],
      mainType: ['EXACT_DATE'],
      type: ['MONTH'],
      quarter: [1],
    });
    const regex = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/;

    // fix for BOUW-594
    this.timeCopyInterval = setInterval(() => {
      if (this.timeElement) {
        const value = this.timeElement.nativeElement.value;

        if (
          value &&
          regex.test(value) &&
          value != this.dateForm.get('time').value
        ) {
          this.dateForm.get('time').patchValue(value);
        }
      }
    }, 1000) as any;

    this.dateForm.valueChanges.subscribe((value) => {
      let date = moment()
        .year(value.year)
        .month(value.month - 1);

      const time = value.time;
      if (time && /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(time)) {
        const timeParts = time.split(':');
        date = date.hours(timeParts[0]).minutes(timeParts[1]);
      } else if (this.withTime) {
        return;
      }

      const daysInMonth = date.daysInMonth();
      if (daysInMonth < value.day) {
        // check if a date is still valid (31st of December -> 31st of November is not valid)
        this.dateForm.get('day').patchValue(daysInMonth);
        return;
      } else {
        date = date.date(value.day);
      }

      if (value.type === 'QUARTER') {
        date.quarter(value.quarter);
      }

      this.updateDays(date);
      this.currentDate = date.format(DatePickerComponent.COMPARISON_FORMAT);

      const newDate = this.noSelection ? null : date.format();

      this.actualDate = newDate;

      this.propagateChange(newDate);
      this.propagateTouch(newDate);

      this.updateType(value);
    });
  }

  /**
   * @param {string} value
   * @param {boolean} dateOnly
   */
  private updateDate(value: string | moment.Moment, dateOnly: boolean = false) {
    if (value == null) {
      value = moment(); // data not yet filled in
      this.noSelection = true;
    } else {
      this.noSelection = false;
    }

    const date = moment(value);

    const day = +date.format('D');
    const month = +date.format('M');
    const year = +date.format('YYYY');

    const quarter = moment(date).quarter();

    const hours = date.format('HH');
    const minutes = date.format('mm');

    let time = null;
    if (dateOnly) {
      time = this.dateForm.get('time').value; // date was selected, so keep time
    } else {
      time = hours + ':' + minutes;
    }

    this.dateForm.patchValue({
      day: day,
      month: month,
      year: year,
      time: time,
      quarter: quarter,
    });
  }

  /**
   * @param date
   */
  private updateYears(date: moment.Moment) {
    const year = +date.format('Y');
    this.years = this.createRange(year - 40, year + 40);
  }

  /**
   * @param number
   * @return {string}
   */
  private static withZero(number: string) {
    return (parseInt(number) < 10 ? '0' : '') + number;
  }

  /**
   * @param date
   */
  private updateDays(date: moment.Moment) {
    const start = moment(date).startOf('month');
    const end = moment(date).endOf('month');

    this.days = this.createRange(+start.format('D'), +end.format('D'));

    const startOfWeek = moment(start).startOf('isoWeek');
    const endOfWeek = moment(end).endOf('isoWeek');

    const rangeMoment = extendMoment(moment);
    const month = date.format('M');

    this.items = [];

    Array.from(rangeMoment().range(startOfWeek, endOfWeek).by('day')).map(
      (item: moment.Moment) => {
        this.items.push({
          date: item.format(DatePickerComponent.COMPARISON_FORMAT),
          previous: item.format('M') !== month,
        });
      }
    );
  }

  /**
   * @param start
   * @param end
   */
  private createRange(start: number, end: number): number[] {
    return Array.apply(null, Array(end - start + 1)).map(function (discard, n) {
      return n + start;
    });
  }

  /**
   * @param value
   * @returns {void}
   */
  private updateType(value): void {
    if (!this.typeControl) {
      return;
    }

    this.showPeriod = value.mainType === 'PERIOD';

    // user switched from exact to period, so reset type to month
    if (value.type === 'EXACT_DATE' && this.showPeriod === true) {
      this.dateForm.get('type').patchValue('MONTH');
      return;
    }

    this.type = value.type;

    let emitType = 'EXACT_DATE';

    if (this.showPeriod) {
      if (value.type === 'QUARTER') {
        emitType = 'Q' + value.quarter;
      } else {
        emitType = value.type;
      }
    }

    if (emitType != null) {
      this.typeControl.patchValue(emitType, { emitEvent: false });
    }
  }
}
