<form [formGroup]="form" appFormChangeDetector>
    <p>
        {{
        'project.detail.settings.users.create.account.description'
        | translate
        }}
    </p>

    <app-form-group>
        <label>{{
            'project.detail.settings.users.create.email' | translate
            }}</label>
        <input type="email" class="input input--m" readonly [attr.value]="userEmail" />
    </app-form-group>

    <app-form-group [form]="form" errorPath="firstName">
        <label for="firstName">{{
            'project.detail.settings.users.create.account.first_name'
            | translate
            }}</label>
        <input type="text" class="input input--m" id="firstName" formControlName="firstName" />
    </app-form-group>

    <app-form-group [form]="form" errorPath="lastName">
        <label for="lastName">{{
            'project.detail.settings.users.create.account.last_name' | translate
            }}</label>
        <input type="text" class="input input--m" id="lastName" formControlName="lastName" />
    </app-form-group>

    <app-form-group [form]="form" errorPath="phoneNumber">
        <label for="phoneNumber">{{
                'project.detail.settings.users.create.account.phone_number'
                | translate
                }}</label>
        <input type="text" class="input input--m" id="phoneNumber" formControlName="phoneNumber" />
    </app-form-group>

    <br />

    <button type="button" class="btn btn-primary" (click)="createProjectUser()">
        {{ 'project.detail.settings.users.create.continue' | translate }}
    </button>
</form>
