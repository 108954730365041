<div class="bouwapp-header">
  <div class="bouwapp-header__text">
    <h1>{{ 'project.detail.statistics.title' | translate }}</h1>
  </div>
</div>

<section class="statistics">
  <div>
    <div class="settings">
      <app-period [formControl]="periodControl"></app-period>
    </div>

    <h3 class="pull-left">
      {{ 'project.detail.statistics.general' | translate }}
    </h3>

    <div class="settings pull-right">
      <app-target-picker
        [formControl]="targetControl"
        [project]="project"
      ></app-target-picker>
    </div>

    <br clear="both" />

    <app-loader [hidden]="!loadingResults"></app-loader>

    <div class="row" *ngIf="results" [hidden]="loadingResults">
      <div class="col-sm-6" *ngFor="let chart of results; let i = index">
        <app-chart
          [title]="chart.title"
          [type]="chart.type"
          [tooltip]="determineChartTooltipTitle"
          [full]="chart"
          [data]="chart.data"
          [stripZeroValues]="false"
          [yAxisLabel]="chart.yAxisLabel"
        ></app-chart>
      </div>

      <div class="col-xs-12" [hidden]="results.length > 0">
        {{ 'project.detail.statistics.empty' | translate }}
      </div>
    </div>

    <hr />

    <h3>{{ 'project.detail.statistics.conversations' | translate }}</h3>

    <div class="row">
      <div class="col-sm-3">
        <app-data-filter
          *ngIf="demography"
          [demography]="demography"
          (filter)="handleFilter($event)"
          [withConversationTimeType]="true"
        ></app-data-filter>
      </div>

      <div class="col-sm-9">
        <app-loader [hidden]="conversationResults"></app-loader>

        <div *ngIf="conversationResults">
          <app-chart
            *ngFor="let result of [conversationResults[0]]"
            [csvExport]="false"
            [title]="result.title"
            [stripZeroValues]="true"
            [tooltip]="determineChartTooltipTitle"
            [data]="result.data"
            [type]="'line'"
            [maximum]="5"
          ></app-chart>
          <app-chart
            [csvExport]="false"
            [title]="conversationResults[1].title"
            [data]="conversationResults[1].data"
            [type]="'satisfaction-distribution'"
          ></app-chart>
          <app-chart
            *ngFor="let result of [conversationResults[2]]"
            [csvExport]="false"
            [title]="result.title"
            [stripZeroValues]="true"
            [data]="result.data"
            [type]="result.type"
            [choices]="result.choices"
            (choicesChanged)="handleChoiceChange($event)"
          ></app-chart>
        </div>
      </div>
    </div>
  </div>
</section>
