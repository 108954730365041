import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Project } from '../interfaces/project';
import { Customer } from '../interfaces/customer';
import { AccessService } from '../services/access.service';
import { AccessControlList } from '../interfaces/access-control-list';
import { SecurityVoter } from '../security/security-voter';
import { ErrorService } from '../services/error.service';

@Directive({
  selector: `[appAccess]`,
  standalone: true,
})
export class AccessDirective implements OnInit {
  @Input() role: string;
  project: Project = null;
  @Input('project') set projectSetter(value: Project) {
    this.project = value;

    if (this.lastList) {
      this.checkAccess(this.lastList);
    }
  }
  @Input() customer: Customer = null;
  @Input() property = 'hidden';

  readonly roleHierarchy = {
    manager: ['projectAdmin', 'accountAdmin'],
    viewer: ['manager', 'projectAdmin', 'accountAdmin'],
    projectAdmin: ['accountAdmin'],
  };

  private lastList: AccessControlList;

  constructor(
    private accessService: AccessService,
    private elementRef: ElementRef,
    private errorService: ErrorService
  ) {}

  public ngOnInit(): void {
    this.accessService.accessControlList.subscribe((list) =>
      this.checkAccess(list)
    );
  }

  private async checkAccess(list: AccessControlList) {
    this.lastList = list;

    try {
      this.updateAttribute(await this.hasAccess(list));
    } catch (error) {
      this.errorService.logError(error);
      this.updateAttribute(false);
    }
  }

  private async hasAccess(list: AccessControlList): Promise<boolean> {
    let access = false;

    if (list == null) {
      return access;
    }

    // Start with the user's desired role
    let rolesToCheck = [this.role];

    // Add cascading roles to the list
    if (this.roleHierarchy[this.role]) {
      rolesToCheck = rolesToCheck.concat(this.roleHierarchy[this.role]);
    }
    for (const roleToCheck of rolesToCheck) {
      if (this.project !== null && !this.project.create) {
        if (roleToCheck === 'projectAdmin') {
          access = SecurityVoter.isProjectAdminForProject(list, this.project);
        } else if (roleToCheck === 'manager') {
          access = SecurityVoter.isManagerForProject(list, this.project);
        } else if (roleToCheck === 'viewer') {
          access = SecurityVoter.isViewerForProject(list, this.project);
        }

        if (
          SecurityVoter.isAccountAdminForCustomer(list, this.project.customer)
        ) {
          access = true;
          break;
        }
      } else if (this.project === null || this.project.create === true) {
        if (roleToCheck === 'manager') {
          access = SecurityVoter.isManager(list);
        }

        if (roleToCheck === 'projectAdmin') {
          access = SecurityVoter.hasCustomers(list);

          if (!access) {
            access = SecurityVoter.hasCreateRole(list);
          }
        }
      }

      if (!access) {
        if (this.customer !== null) {
          if (roleToCheck === 'accountAdmin') {
            access = SecurityVoter.isAccountAdminForCustomer(
              list,
              this.customer
            );
          }
        }
        if (this.customer === null) {
          if (roleToCheck === 'accountAdmin') {
            access = SecurityVoter.isAccountAdmin(list);
          }
        }
      }

      if (access) {
        break; // Exit the loop if access is granted
      }
    }
    return access;
  }

  private updateAttribute(hasAccess: boolean) {
    if (hasAccess) {
      this.elementRef.nativeElement.removeAttribute(this.property);
    } else {
      this.elementRef.nativeElement.setAttribute(this.property, '');
    }
  }
}
