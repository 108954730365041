import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-data-filter',
    templateUrl: './data-filter.component.html',
    standalone: true,
    imports: [ReactiveFormsModule, NgFor, NgIf, TranslateModule]
})
export class DataFilterComponent implements OnInit {
  public UNKNOWN = 'unknown';
  public static ALL = 'all';

  allChecked: boolean;

  @Input() public demography;
  @Input() public withConversationTimeType = false;
  @Output() public filter = new EventEmitter<any>();

  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.createForm();
  }

  public ngOnInit(): void {
    if (this.demography.targets) {
      this.demography.targets.forEach((item) => {
        (this.form.get('target') as FormArray).push(new FormControl(true));
      });
      this.checkAllToggle();
    }

    if (this.demography.types) {
      this.demography.types.forEach((item) => {
        (this.form.get('type') as FormArray).push(new FormControl(false));
      });
    }

    this.form.valueChanges.subscribe((value) => {
      value.target = value.target
        .map((val, key) => this.demography.targets[key].id)
        .filter((item, index) => value.target[index]);
      value.types = value.type
        .map((val, key) => this.demography.types[key].id)
        .filter((item, index) => value.type[index]);

      this.checkAllToggle();

      if (!this.withConversationTimeType) {
        delete value.timeType;
      }

      this.filter.emit(value);
    });
  }

  parseTitle(title: string): string {
    return title.split(':')[0];
  }

  private createForm() {
    this.form = this.fb.group({
      target: this.fb.array([]),
      type: this.fb.array([]),
      theme: [DataFilterComponent.ALL, Validators.required],
    });
  }

  toggleAll(event) {
    (<FormArray>this.form.get('target')).controls.forEach((control) => {
      control.patchValue(event.target.checked);
    });
  }

  checkAllToggle() {
    let allChecked = true;
    (<FormArray>this.form.get('target')).controls.forEach((control) => {
      if (!control.value) {
        allChecked = false;
      }
    });
    this.allChecked = allChecked;
  }
}
