import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-loader',
    templateUrl: 'loader.component.html',
    standalone: true,
    imports: [TranslateModule]
})
export class LoaderComponent {
  @Input() text = true;
  @Input() condensed = false;
}
