import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: `[appLoading]`,
    standalone: true
})
export class LoadingDirective {
  @Input() set appLoading(loading) {
    this.disabled = loading;

    if (this.withSpinner) {
      this.hasInlineSpinner = loading;
    }

    if (this.color == 'dark') {
      this.hasColorDark = true;
    }
  }

  @HostBinding('class.dark') public hasColorDark = false;
  @Input() color: string = 'light';

  /**
   * @type {boolean}
   */
  @Input() withSpinner = true;

  /**
   * @type {string}
   */
  @HostBinding('disabled') public disabled = false;

  /**
   * @type {string}
   */
  @HostBinding('class.has-inline-spinner') public hasInlineSpinner = false;
}
