import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export const EqualPasswordValidator = (): ValidatorFn => {
  return (control: FormGroup): { [key: string]: any } => {
    const first = control.get('first').value;
    const second = control.get('second').value;

    if ((first === second && first !== '') || first === '' || second === '') {
      return null;
    } else {
      return {
        equalPassword: false,
      };
    }
  };
};
