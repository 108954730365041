import { Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { ProjectDataBusService } from '../../../services/project-data-bus.service';
import { Project } from '../../../interfaces/project';
import { RatingTheme } from '../../../interfaces/rating-theme';
import { RatingThemeService } from '../../../services/rating-theme.service';
import { SatisfactionMonitorService } from '../../../services/satisfaction-monitor.service';
import * as moment from 'moment';
import { ChartComponent } from '../../../components/chart.component';
import { CsvService } from '../../../services/csv.service';
import { Subscription } from 'rxjs';
import { Target } from '../../../interfaces/target';
import { ProjectService } from '../../../services/project.service';
import { ErrorService } from '../../../services/error.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Module } from '../../../enums/module';
import { TargetPickerComponent } from '../../../components/target-picker.component';
import { DataFilterComponent } from '../../../components/data-filter.component';
import { PeriodComponent } from '../../../components/period.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { AccessDirective } from '../../../directives/access.directive';
import { NgIf, NgFor } from '@angular/common';
import { LoaderComponent } from '../../../components/loader.component';

@Component({
  selector: 'app-default-projects-detail-satisfaction-monitor',
  templateUrl: 'detail-satisfaction-monitor.component.html',
  standalone: true,
  imports: [
    LoaderComponent,
    NgIf,
    ReactiveFormsModule,
    AccessDirective,
    NgFor,
    LoadingDirective,
    PeriodComponent,
    DataFilterComponent,
    ChartComponent,
    TargetPickerComponent,
    TranslateModule,
  ],
})
export class DetailSatisfactionMonitorComponent implements OnDestroy {
  public MAX_THEMES = 7;
  public PUSH_INTERVAL_HOURS = 24;

  project: Project;

  public allThemes: RatingTheme[];

  public themes: RatingTheme[];

  public results;

  public search: string;

  public searchControl: FormControl;

  public periodControl: FormControl;

  public error = false;

  public loading = false;

  public loadingResults = false;

  public sendingPush = false;

  public sendingPushFailed = false;

  public editing = false;

  public showTargetPicker = false;
  public targetPicker: AbstractControl;

  public mode: 'configure' | 'results' = 'configure';

  public targetControl: FormControl;
  public targetSubscription: Subscription;
  public currentTarget: Target | null;

  public demography;
  public currentFilter;
  public Module = Module;
  public version: number;

  @ViewChildren(ChartComponent) private charts: QueryList<ChartComponent>;

  constructor(
    protected projectDataBusService: ProjectDataBusService,
    private projectService: ProjectService,
    protected formBuilder: FormBuilder,
    protected ratingThemeService: RatingThemeService,
    protected satisfactionMonitorService: SatisfactionMonitorService,
    protected csvService: CsvService,
    protected translateService: TranslateService,
    private errorService: ErrorService
  ) {
    this.projectDataBusService.projectObservable.subscribe((project) => {
      if (!project.ratingThemes) {
        project.ratingThemes = [];
      }

      this.project = project;
      this.mode = project.ratingThemes.length > 0 ? 'results' : 'configure';

      if (this.mode === 'results') {
        this.editing = true;
      }
    });

    this.createControls();
  }

  ngOnDestroy(): void {
    if (this.targetSubscription) {
      this.targetSubscription.unsubscribe();
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.mode === 'configure') {
      this.loadThemes();
    } else {
      this.loadDemography();
    }
  }

  /**
   * Create a CSV of all graphs currently visible
   */
  public exportAllGraphs() {
    if (!this.charts) {
      return;
    }

    let result = null;

    for (const chart of this.charts.toArray()) {
      const csv = chart.createCsv();

      if (result == null) {
        result = this.csvService.create(csv, chart.title);
      } else {
        result.merge(csv, chart.title);
      }
    }

    try {
      const responseData = this.results[0].data[0].data;
      if (result == null) {
        result = this.csvService.create(responseData);
      } else {
        result.merge(responseData);
      }
    } catch (e) {}

    if (result) {
      result.download('export.csv');
    }
  }

  /**
   * Configure the monitor
   * @returns {Promise<void>}
   */
  async configure() {
    try {
      this.error = false;
      this.loading = true;

      const chosen = this.allThemes.slice().filter((item) => item.active);

      await this.satisfactionMonitorService.configure(this.project, chosen);

      this.mode = 'results';
      this.loadDemography();
      this.loadResults();
      this.projectService.updateLastEdited(this.project);
    } catch (error) {
      this.errorService.logError(error);
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  /**
   * @param item
   */
  toggleTheme(item: RatingTheme) {
    if (
      (this.MAX_THEMES === this.project.ratingThemes.length && !item.active) ||
      item.required
    ) {
      return;
    }

    item.active = !item.active;

    if (item.active) {
      this.project.ratingThemes.push(item);
    } else {
      this.project.ratingThemes = this.project.ratingThemes.filter(
        (theme) => item.id !== theme.id
      );
    }
  }

  /**
   * Switch mode
   */
  switchToConfigure() {
    this.mode = 'configure';

    if (!this.allThemes) {
      this.loadThemes();
    }
  }

  /**
   * Filter current themes by search query
   * @return boolean
   */
  canPushRating() {
    if (this.project.ratingPushedAt == null) {
      return true;
    } else {
      return this.getTimeTillRatingPush() <= 0;
    }
  }

  /**
   * @returns {number}
   */
  getTimeTillRatingPush() {
    if (this.project.ratingPushedAt == null) {
      return 0;
    } else {
      const difference = moment().diff(this.project.ratingPushedAt, 'hours');

      return this.PUSH_INTERVAL_HOURS - difference;
    }
  }

  /**
   * Function to determine the label of a tooltip
   */
  determineChartTooltipTitle(data) {
    try {
      if (data.responseCount != null || data.responseCount === 0) {
        if (+data.responseCount === 1) {
          return this.translateService.instant(
            'project.detail.satisfaction_monitor.results.response',
            { responses: data.responseCount }
          );
        }

        return this.translateService.instant(
          'project.detail.satisfaction_monitor.results.responses',
          { responses: data.responseCount }
        );
      }

      return +data.y;
    } catch (error) {
      return <string>data.responseCount || '0';
    }
  }

  toggleTargetPicker(): void {
    this.showTargetPicker = !this.showTargetPicker;
  }

  async sendPushNotification() {
    try {
      this.sendingPush = true;
      this.sendingPushFailed = false;

      const targets = this.targetPicker.value;

      await this.satisfactionMonitorService.sendPushNotification(
        this.project,
        targets
      );

      this.project.ratingPushedAt = moment().format();
      this.showTargetPicker = false;
    } catch (error) {
      this.errorService.logError(error);
      this.sendingPushFailed = true;
    } finally {
      this.sendingPush = false;
    }
  }

  /**
   * Create the search control and listen for changes
   */
  private createControls() {
    this.searchControl = this.formBuilder.control(null);
    this.periodControl = this.formBuilder.control({
      type: 'week',
      start: moment().subtract(7, 'days').format(),
      end: moment().format(),
    });
    this.targetControl = this.formBuilder.control(null);
    this.targetPicker = this.formBuilder.control([]);

    this.searchControl.valueChanges.subscribe((value) => {
      this.search = value;

      this.filter();
    });

    this.periodControl.valueChanges.subscribe((value) => {
      if (this.periodControl.valid) {
        this.loadResults();
        this.loadDemography();
      }
    });

    this.targetSubscription = this.targetControl.valueChanges.subscribe(
      (value) => {
        this.currentTarget = value;
        this.loadResults();
      }
    );
  }

  /**
   * Load all themes
   * @returns {Promise<void>}
   */
  private async loadThemes() {
    this.allThemes = await this.ratingThemeService.list();
    this.filter();

    for (const theme of this.allThemes) {
      const item = this.project.ratingThemes.find(
        (chosen) => theme.id === chosen.id
      );

      if (theme.required) {
        theme.active = true;

        if (item == null) {
          this.project.ratingThemes.push(theme);
        }
      } else {
        if (item != null) {
          theme.active = true;
        }
      }
    }
  }

  public handleFilter(filter) {
    this.currentFilter = filter;
    this.loadResults();
  }

  private async loadDemography() {
    const period = this.periodControl.value;

    this.demography = await this.satisfactionMonitorService.demography(
      this.project,
      period
    );
  }

  /**
   * Load the results of the satisfaction monitor
   * @returns {Promise<void>}
   */
  private async loadResults() {
    const period = this.periodControl.value;

    this.loadingResults = true;

    this.results = await this.satisfactionMonitorService.results(
      this.project,
      period,
      this.currentFilter
    );

    this.loadingResults = false;
  }

  /**
   * Filter current themes by search query
   */
  private filter() {
    if (this.search == null) {
      this.themes = this.allThemes;
    } else {
      const search = this.search.toLowerCase();

      this.themes = this.allThemes
        .slice()
        .filter((item) => item.title.toLowerCase().indexOf(search) > -1);
    }
  }
}
