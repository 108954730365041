import { Injectable } from '@angular/core';
import { ProjectUser } from '../interfaces/project-user';
import { Project } from '../interfaces/project';
import { Update } from '../interfaces/update';
import { ProjectService } from './project.service';
import { UpdateAttachment } from '../interfaces/update-attachment';
import { CollectionHelper } from '../shared/collection-helper';
import { PollChoice } from '../interfaces/poll-choice';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CollectionResponse } from '../interfaces/collection-response';
import { environment } from '../../environments/environment';

@Injectable()
export class TwoFactorService {
  constructor(private http: HttpClient) { }

  public send(method: string): Promise<ProjectUser> {
    return this.http
      .post<ProjectUser>(environment.apiUrl + '/2fa/send', { method: method })
      .toPromise();
  }

  public resend(method: string): Promise<ProjectUser> {
    return this.http
      .post<ProjectUser>(environment.apiUrl + '/2fa/resend', { method: method })
      .toPromise();
  }

  public validate(code: number): Promise<number> {
    return this.http
      .post<number>(environment.apiUrl + '/2fa/validate', { code: code })
      .toPromise();
  }
}
