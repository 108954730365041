import { Injectable } from '@angular/core';
import { MapComponent } from 'ngx-mapbox-gl';
import * as mapboxgl from 'mapbox-gl';

@Injectable({ providedIn: 'root' })
export class MapboxService {

    constructor() { }

    isWebGLSupported(): boolean {
        const canvas = document.createElement('canvas');
        const gl =
            canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        return !!gl;
    }


    public addShapeFile(map: MapComponent, id: string, data: any) {
        if (!map.mapInstance) {
            return;
        }

        const primary = '#F5B049';

        if (!map.mapInstance.getSource(id)) {
            map.mapInstance.addSource(id, {
                type: 'geojson',
                data,
            });
        }

        if (!map.mapInstance.getLayer(id + '-' + 'fill')) {
            map.mapInstance.addLayer({
                id: id + '-' + 'fill',
                type: 'fill',
                source: id,
                layout: {},
                paint: {
                    'fill-color': primary,
                    'fill-outline-color': primary,
                    'fill-opacity': 0.5,
                },
            });
            map.mapInstance.addLayer({
                id: id + '-' + 'stroke',
                type: 'line',
                source: id,
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                paint: {
                    'line-color': primary,
                    'line-width': 2,
                },
            });
        }
    }

    public clearShapeFiles(map: MapComponent, ids: string[]) {
        if (!map.mapInstance) {
            return;
        }

        ids.forEach((id) => {
            const layerStroke = map.mapInstance.getLayer(id + '-stroke');
            const layerFill = map.mapInstance.getLayer(id + '-fill');
            const source = map.mapInstance.getSource(id);
            if (undefined !== layerStroke) {
                map.mapInstance.removeLayer(id + '-stroke');
            }

            if (undefined !== layerFill) {
                map.mapInstance.removeLayer(id + '-fill');
            }

            if (undefined !== source) {
                map.mapInstance.removeSource(id);
            }
        });
    }

    public fitMapToShapeFile(map: MapComponent, shapefile: any, deviation = 0) {
        const coordinates = shapefile.features.reduce((base, feature) => {
            return [
                ...base,
                ...(!Array.isArray(feature.geometry.coordinates[0])
                    ? [feature.geometry.coordinates]
                    : !Array.isArray(feature.geometry.coordinates[0][0])
                        ? feature.geometry.coordinates
                        : feature.geometry.coordinates.reduce((prev, coordinateSpace) => {
                            return [...prev, ...coordinateSpace];
                        }, [])),
            ];
        }, []);

        const validCoordinates = coordinates.filter(
            (coord) => !isNaN(coord[0]) && !isNaN(coord[1])
        );

        if (validCoordinates.length === 0) {
            return;
        }

        const bounds = new mapboxgl.LngLatBounds();
        validCoordinates.forEach((coord) => {
            bounds.extend(coord);
        });

        map.mapInstance.fitBounds(bounds, {
            animate: false,
            padding: deviation,
        });
    }

    public setLocation(map: MapComponent, location: number[]): void {
        if (!map.mapInstance) {
            return;
        }

        map.mapInstance.flyTo({
            animate: false,
            center: [location[1], location[0]],
        });
    }

    public drawLocationCircle(
        map: MapComponent,
        location: number[],
        accuracy?: number
    ) {
        if (!map.mapInstance) {
            return;
        }

        map.mapInstance.addSource('location', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [location[1], location[0]],
                        },
                    },
                ],
            },
        } as any);

        map.mapInstance.addLayer({
            id: 'outerCircle',
            type: 'circle',
            source: 'location',
            layout: {},
            paint: {
                'circle-radius': {
                    stops: [
                        [0, 0],
                        [
                            20,
                            this.metersToPixelsAtMaxZoom(
                                accuracy ? accuracy : 500,
                                location[0]
                            ),
                        ],
                    ],
                    base: 2,
                },
                'circle-color': '#4285F4',
                'circle-opacity': 0.3,
            },
        });
        map.mapInstance.addLayer({
            id: 'innerCircle',
            type: 'circle',
            source: 'location',
            layout: {},
            paint: {
                'circle-radius': 6,
                'circle-color': '#4285F4',
                'circle-opacity': 1,
                'circle-stroke-color': '#FFFFFF',
                'circle-stroke-width': 2,
            },
        });
    }

    public drawMarker(
        map: MapComponent,
        location: number[],
        icon: string = null,
        baseOptions = {},
        iconWidth = 36,
        iconHeight = 47,
        className = ''
    ) {
        const wrapper = document.createElement('div');
        const el = document.createElement('div');
        el.className = `marker ${className}`;
        el.style.backgroundImage =
            'url(' + (icon ? icon : 'assets/img/icons/marker_regular.svg') + ')';
        el.style.backgroundSize = '100%';
        el.style.width = iconWidth + 'px';
        el.style.height = iconHeight + 'px';
        el.style.transform = `translateZ(1px) translateY(-${iconHeight / 2
            }px) translate(var(--tX, 0), var(--tY, 0))`;

        wrapper.append(el);

        return new mapboxgl.Marker(wrapper)
            .setLngLat([location[1], location[0]])
            .addTo(map.mapInstance);
    }

    // from https://stackoverflow.com/a/18883819
    public calculateDistance(
        lat1: number,
        lon1: number,
        lat2: number,
        lon2: number
    ) {
        const R = 6371; // km
        const dLat = this.toRad(lat2 - lat1);
        const dLon = this.toRad(lon2 - lon1);
        lat1 = this.toRad(lat1);
        lat2 = this.toRad(lat2);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        return d;
    }

    // Converts numeric degrees to radians
    private toRad(Value) {
        return (Value * Math.PI) / 180;
    }

    private metersToPixelsAtMaxZoom = (meters, latitude) =>
        meters / 0.075 / Math.cos((latitude * Math.PI) / 180);
}
