import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-two-columns-layout',
  templateUrl: './two-columns-layout.component.html',
  standalone: true,
  imports: [],
})
export class TwoColumnsLayout implements OnInit, OnDestroy, AfterViewInit {
  @Input() stickyContainerSelector: string;

  public sticky: boolean = false;
  public stickyLeft: number = 0;
  private shouldCalculate: boolean = true;
  private observer: MutationObserver | null = null;

  resizeListener: EventListener;
  scrollListener: EventListener;
  unloadListener: EventListener;

  constructor(private el: ElementRef) {}

  async ngOnInit() {
    this.scrollListener = (event) => this.onScroll(event);
    this.resizeListener = (event) => this.calculateSticky();
    // this.unloadListener = (event) => this.detectChanges(event);
    document
      .querySelector('.app__content')
      .addEventListener('scroll', this.scrollListener);
    window.addEventListener('resize', this.resizeListener);
    window.addEventListener('beforeunload', this.unloadListener);
  }

  ngAfterViewInit() {
    const wrapper = this.el.nativeElement.querySelector(
      '.two-columns__wrapper'
    );
    if (wrapper) {
      const container = wrapper.querySelector('.right');

      if (container) {
        this.observer = new MutationObserver(() => {
          if (container.children.length === 0) {
            wrapper.classList.add('empty');
            this.shouldCalculate = false;
          } else {
            wrapper.classList.remove('empty');
            this.shouldCalculate = true;
          }
        });

        this.observer.observe(container, { childList: true, subtree: true });
        if (container.children.length === 0) {
          wrapper.classList.add('empty');
          this.shouldCalculate = false;
        } else {
          wrapper.classList.remove('empty');
          this.shouldCalculate = true;
        }
      }
    }
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.resizeListener);
    window.removeEventListener('beforeunload', this.unloadListener);
    document
      .querySelector('.app__content')
      .removeEventListener('scroll', this.scrollListener);

    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  onScroll(event) {
    if (event.target.scrollTop >= 140) this.sticky = true;
    else this.sticky = false;
    this.calculateSticky();
  }

  calculateSticky() {
    if (
      this.shouldCalculate &&
      document.querySelector('.two-columns__wrapper .left')
    ) {
      let leftWidth = document.querySelector(
        '.two-columns__wrapper .left'
      ).clientWidth;
      let menuWidth = (<any>document.querySelector('.app__menu')).offsetWidth;
      const rootFontSize = parseFloat(
        getComputedStyle(document.documentElement).fontSize
      );

      this.stickyLeft = menuWidth + leftWidth + 6.4 * rootFontSize;
    }
  }
}
