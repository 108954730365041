import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from '../../components/footer.component';
import { HeaderComponent } from '../../components/header.component';

@Component({
    selector: 'app-default-page-not-found',
    templateUrl: 'page-not-found.component.html',
    standalone: true,
    imports: [HeaderComponent, FooterComponent, TranslateModule]
})
export class PageNotFoundComponent {}
