import { Injectable, signal } from '@angular/core';
import { UserDataService } from './user-data.service';
import { ProductFruitUser } from '../interfaces/product-fruit';
import { productFruits } from 'product-fruits';
import { environment } from 'src/environments/environment';
import { LocaleService } from './locale.service';

declare global {
  interface Window {
    productFruits: any;
  }
}

@Injectable()
export class ProductFruitsService {
  constructor(
    private userDataService: UserDataService,
    private localeService: LocaleService
  ) {}

  isReady = signal(false);

  public init() {
    this.userDataService.retrieveUser().then((user) => {
      const fruitUser: ProductFruitUser = {
        username: user.id.toString(),
        firstname: user.projectUser.id.toString(),
      };
      if (user.activatedAt !== null) {
        fruitUser.signUpAt = user.activatedAt;
      }
      productFruits.init(
        environment.productFruits,
        this.localeService.currentLocale,
        fruitUser,
        { disableLocationChangeDetection: false, hideInAppCenterLauncher: true }
      );

      window.addEventListener('productfruits_ready', () => {
        this.isReady.set(true);
      });
    });
  }
}

type PfWindow = {
  productFruits: any;
};
