import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Platform } from '../interfaces/platform';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private static _platform: Platform;

  private static determinePlatform() {
    return environment.platforms.find(
      (item) =>
        item.host.toLowerCase() === window.location.hostname.toLowerCase()
    );
  }

  public static get platform(): Platform {
    if (!PlatformService._platform) {
      PlatformService._platform = this.determinePlatform();
    }

    return PlatformService._platform;
  }
}

// Export the platform constant
export const platform = PlatformService.platform;
