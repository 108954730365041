import { Injectable } from '@angular/core';
import { Project } from '../interfaces/project';
import { ProjectService } from './project.service';
import { Phase } from '../interfaces/phase';
import { Update } from '../interfaces/update';
import { Attachment } from '../interfaces/attachment';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Event } from '../interfaces/event';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class PhaseService {
  static base = '/project-phases';
  static attachmentBase = '/project-phase-attachments';

  constructor(private http: HttpClient) {}

  public listByProject(project: Project): Promise<Phase[]> {
    return this.http
      .get(
        environment.apiUrl +
          ProjectService.base +
          '/' +
          project.slug +
          PhaseService.base +
          '?order[startDate]'
      )
      .toPromise()
      .then((result) => result['hydra:member']);
  }

  public listWithEvents(project: Project): Promise<(Phase | Event)[]> {
    return firstValueFrom(
      this.http.get<(Phase | Event)[]>(
        environment.apiUrlV3 +
          ProjectService.base +
          '/' +
          project.slug +
          '/planning'
      )
    );
  }

  public async create(project: Project, data: any): Promise<Update> {
    data.project = '/api' + ProjectService.base + '/' + project.slug;

    return this.http
      .post<Update>(environment.apiUrl + PhaseService.base, data)
      .toPromise();
  }

  public async update(data: Phase): Promise<Phase> {
    return await this.http
      .put<Phase>(environment.apiUrl + PhaseService.base + '/' + data.id, data)
      .toPromise();
  }

  public async remove(phase: Phase): Promise<void> {
    await this.http
      .delete(environment.apiUrl + PhaseService.base + '/' + phase.id)
      .toPromise();

    return;
  }

  public fetch(id: number): Promise<Phase> {
    return this.http
      .get<Phase>(environment.apiUrl + PhaseService.base + '/' + id)
      .toPromise();
  }

  public updateAttachment(attachment: Attachment): Promise<Attachment> {
    return this.http
      .put<Attachment>(
        environment.apiUrl + PhaseService.attachmentBase + '/' + attachment.id,
        attachment
      )
      .toPromise();
  }

  public updateAttachmentSortOrder(id, data): Promise<Attachment[]> {
    return this.http
      .post(
        environment.apiUrl + PhaseService.attachmentBase + '/' + id + '/sort',
        data
      )
      .toPromise()
      .then((response) => {
        return response as Promise<Attachment[]>;
      });
  }
}
