<form [formGroup]="form" appFormChangeDetector (ngSubmit)="save()">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1 *ngIf="edit">{{ 'project.detail.events.edit' | translate }}</h1>
      <h1 *ngIf="!edit">{{ 'project.detail.events.new' | translate }}</h1>
    </div>
    <div class="bouwapp-header__buttons">
      <button
        type="submit"
        class="secondary"
        [appLoading]="loading"
        [disabled]="loading"
        appAccess
        role="manager"
      >
        <span
          *ngIf="!edit"
          class="icon"
          [inlineSVG]="'/assets/icons/add--white.svg'"
        ></span>
        <span>{{
          (edit ? 'project.detail.events.save' : 'project.detail.events.create')
            | translate
        }}</span>
      </button>
    </div>
  </div>

  <!-- New/Edit Event -->
  <section class="new-event projects-detail" [hidden]="edit && !event">
    <div class="bouwapp-box full">
      <div class="bouwapp-box__title">
        <h2>{{ 'project.detail.events.info' | translate }}</h2>
      </div>

      <div class="bouwapp-box__self">
        <app-form-group [form]="form" errorPath="title">
          <label for="name"
            >{{ 'project.detail.events.name' | translate
            }}<span class="required">*</span></label
          >
          <input
            type="text"
            class="input input--m"
            formControlName="title"
            id="name"
            maxlength="60"
          />
        </app-form-group>

        <div class="form-split">
          <div>
            <div class="form-date">
              <p>{{ 'project.detail.events.start' | translate }}</p>
              <app-form-group [form]="form" errorPath="startDate">
                <input
                  formControlName="startDate"
                  [value]="
                    form.get('startDate').value | date : 'yyyy-MM-ddTHH:mm'
                  "
                  class="input input--m"
                  type="datetime-local"
                />
              </app-form-group>
            </div>
          </div>
          <div>
            <div class="form-date">
              <p>{{ 'project.detail.phases.edit.end' | translate }}</p>
              <app-form-group [form]="form" errorPath="endDate">
                <input
                  formControlName="endDate"
                  [value]="
                    form.get('endDate').value | date : 'yyyy-MM-ddTHH:mm'
                  "
                  class="input input--m"
                  type="datetime-local"
                />
              </app-form-group>
            </div>
          </div>
        </div>

        <div *ngIf="!dateRangeValid" class="form-date__notice combi">
          {{ 'project.detail.phases.edit.date.invalid' | translate }}
        </div>
      </div>
    </div>
  </section>
</form>

<app-loader *ngIf="edit && !event"></app-loader>
